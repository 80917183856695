

export default function isMobile(userAgent) {
   /* eslint-disable no-param-reassign */
   userAgent =
      userAgent || (typeof navigator !== 'undefined' ? navigator.userAgent : '');
   
   // Facebook mobile app's integrated browser adds a bunch of strings that
   // match everything. Strip it out if it exists.
   let tmp = userAgent.split('[FBAN');
   if (typeof tmp[1] !== 'undefined') {
      userAgent = tmp[0];
   }
   
   // Twitter mobile app's integrated browser on iPad adds a "Twitter for
   // iPhone" string. Same probably happens on other tablet platforms.
   // This will confuse detection so strip it out if it exists.
   tmp = userAgent.split('Twitter');
   if (typeof tmp[1] !== 'undefined') {
      userAgent = tmp[0];
   }
   
   return (new RegExp(/(iPhone|iPod|iPad|\bAndroid(?:.+)Mobile\b|Android|(?:SD4930UR|\bSilk(?:.+)Mobile\b)|Silk|Windows Phone|\bWindows(?:.+)ARM\b|BlackBerry|BB10|Opera Mini|\b(CriOS|Chrome)(?:.+)Mobile|Mobile(?:.+)Firefox\b)/i)).test(userAgent);
}