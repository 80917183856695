import React from 'react';
import {
   BrowserRouter as Router,
   Switch,
   Route,
   Link,
   
   // useParams
} from "react-router-dom";

import Cookies from 'js-cookie'
import ReactGA from 'react-ga'

import RedditAPI from './reddit';
// import fetch from 'node-fetch';
// import ArtJson from './art'

import isMobile from "./isMobile";

import {
   // Menu,
   Dropdown,
   Button,
   Icon,
   Checkbox,
} from 'semantic-ui-react'

// import logoPNG from './images/logo.png';
import downloadSVG from './images/download.svg';
import redditSVG from './images/reddit.svg';
import gfycatSVG from './images/gfycat.svg';
import imgurSVG from './images/imgur.svg';
import giphySVG from './images/giphy.svg';
import redgifsSVG from './images/redgifs.svg';
import outSVG from './images/out.svg';

import 'semantic-ui-css/semantic.min.css'
import './App.css';

function noop() {}

if (process.env.NODE_ENV !== 'development') {
   console.log = noop;
   console.warn = noop;
   console.error = noop;
}

/*function getJSON(url, res, err) {
   return fetch(url)
      .then(response => response.json())
      .then(res)
      .catch(err);
}*/

/*function decodeHTMLEntities(html) {
   var txt = document.createElement('textarea');
   txt.innerHTML = html;
   return txt.value;
}*/

/*function HTMLEntities(str) {
   return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}*/

function uppercaseFirstLetter(str) {
   return str.charAt(0).toUpperCase() + str.slice(1);
}

function decodeHTMLEntities(str) {
   return String(str).replace(/&amp;/gi, '&').replace(/&lt;/gi, '<').replace(/&gt;/gi, '>').replace(/&quot;/gi, '"');
}

function triggerMouseEvent (node, eventType) {
   var clickEvent = document.createEvent ('MouseEvents');
   clickEvent.initEvent (eventType, true, true);
   node.dispatchEvent (clickEvent);
}

/*function between(x, min, max) {
   return x >= min && x <= max;
}*/

const REGEX = {
   imgur: /(i\.)?imgur\.com\/(\w+\/)?(\w*)(\.\w+)?(\?\w+)?/i,
   gfycat: /https?:\/\/(\w+\.)?gfycat\.com\/(\w+\/)?(\w*)(-.+)?(\.\w+)?(\?\w+)?/i,
   giphy: /https?:\/\/(\w+\.)?giphy\.com\/(\w+\/)?([a-z0-9-]+-)?(\w*)(\/[^/]+)?/i,
   redgifs: /https?:\/\/(\w+\.)?redgifs\.com\/(\w+\/)?(\w*)(-.+)?(\.\w+)?(\?\w+)?/i,
};

const SOURCE_TYPES = {
   reddit: 0,
   gfycat: 1,
   imgur: 2,
   giphy: 3,
   redgifs: 4,
   0: 'Reddit',
   1: 'Gfycat',
   2: 'Imgur',
   3: 'Giphy',
   4: 'RedGifs',
};


const RGPD_CONSENTS = {
   content:1,
   stats:2,
};

/*const RGPD_CONSENTS = {
   ids_access:1,
   ads_selection:2,
   ads_profile:3,
   ads_personalization:4,
   content_profile:5,
   content_personalization:6,
   ads_stats:7,
   content_stats:8,
   global_stats:9,
   improve_stats:9,
   debug_stats:6,
   device_stats:6,
};*/

const USER_PARAMS = {
   PREFIX:'r',
   MEDIA_TYPE:{alias:'mt', cookie_delay:7, consent:RGPD_CONSENTS.content},
   ALLOW_NSFW:{alias:'an', cookie_delay:7, consent:RGPD_CONSENTS.content},
   ALLOW_AUTO_PLAY:{alias:'ap', cookie_delay:7, consent:RGPD_CONSENTS.content},
   DISPLAY_TITLE:{alias:'dt', cookie_delay:7, consent:RGPD_CONSENTS.content},
   IS_DISCLAIMER_HIDDEN:{alias:'dok', cookie_delay:365, consent:RGPD_CONSENTS.content},
}

const OLD_COOKIES = ['mt','ac','ap','dt','dok'];

const SOURCE_IMGS = {
   [SOURCE_TYPES.reddit]:redditSVG,
   [SOURCE_TYPES.gfycat]:gfycatSVG,
   [SOURCE_TYPES.imgur]:imgurSVG,
   [SOURCE_TYPES.giphy]:giphySVG,
   [SOURCE_TYPES.redgifs]:redgifsSVG,
};

const SOURCE_URLS = {
   [SOURCE_TYPES.reddit] : 'https://reddit.com',
   [SOURCE_TYPES.gfycat] : 'https://gfycat.com/',
   [SOURCE_TYPES.imgur] : 'https://imgur.com/',
   [SOURCE_TYPES.giphy] : 'https://giphy.com/gifs/',
   [SOURCE_TYPES.redgifs] : 'https://redgifs.com/',
};

const REMOVED_PREVIEW_URLS = [
   'https://external-preview.redd.it/vcg6KjHZnlO3KHwsfnSCn85Z1LXaD267jm1XT2aTETA.gif?format=png8&amp;s=8a486a3f574da077925d78bcda55dc44e0e928b9', // gfycat copyright
   'https://external-preview.redd.it/ESPM-tTuK3werLgfWG_wBsw_trUSDNU4bI5s2jVRAgQ.png?auto=webp&amp;s=5516eaced3d9aa38c5fb842ca40ea36a3c164eed', // imgur
];


export default function AppRouter() {
   return (
      <Router>
         <Switch>
            <Route path="/" exact component={App} />
            
            {/*<Route path="/:mediaType(gifs|images)/:type(r|c|u|user)/:pages/(comments|duplicates)/:item/" component={App} />*/}
            <Route path="/:type(r|c|u|user)/:pages([-+_a-zA-Z0-9]{2,})/(comments|duplicates)/:item/" component={App} />
            
            {/*<Route path="/:mediaType(gifs|images)/:type(r|c|u|user)/:pages/:sort/:time" component={App} />*/}
            <Route path="/:type(r|c|u|user)/:pages([-+_a-zA-Z0-9]{2,})/:sort(hot|new|top)/:time(hour|day|week|month|year|all)" component={App} />
            
            {/*<Route path="/:mediaType(gifs|images)/:type(r|c|u|user)/:pages/:sort" component={App} />*/}
            <Route path="/:type(r|c|u|user)/:pages([-+_a-zA-Z0-9]{2,})/:sort(hot|new|top)" component={App} />
            
            {/*<Route path="/:mediaType(gifs|images)/:type(r|c|u|user)/:pages" component={App} />*/}
            <Route path="/:type(r|c|u|user)/:pages([-+_a-zA-Z0-9]{2,})" component={App} />
            
            {/*<Route path="/:mediaType(gifs|images)/:type(r|c|u|user)" component={App} />*/}
            <Route path="/:type(r|c|u|user)" component={App} />
         </Switch>
      </Router>
   );
}



export class Disclaimer extends React.Component {
   constructor(props) {
      super(props);
      
      this.vars = props.vars;
      
      this.state = {
         hide: this.vars.isDisclaimerHidden,
      }
   }
   
   componentDidMount() {
      try {
         this.vars.galleryTopOffset += this.vars.disclaimerContainerRef.current.offsetHeight;
      } catch(e) {}
   }
   
   render() {
      
      if (this.state.hide)
         return null;
      
      {/*<a className="name" target="_blank" href="https://api.imgur.com/">Imgur API</a>,&nbsp;
      <a className="name" target="_blank" href="https://developers.gfycat.com/">Gfycat API</a>,&nbsp;
      <a className="name" target="_blank" href="https://developers.giphy.com/">Giphy API</a> and&nbsp;
      <a className="name" target="_blank" href="https://developers.google.com/youtube/">Youtube API</a>&nbsp;*/}
      
      return (
         <div className="disclaimer"
              ref={this.vars.disclaimerContainerRef}
         >
            <div className="disclaimer-inner">
               <span className="bt close" onClick={this.vars.hideDisclaimer}>
                  <span className="icon">
                     <span className="bar" />
                     <span className="bar" />
                  </span>
               </span>
               <span className="title">A simple tool made by a Reddit fan <span className="right">for watching all Reddit images, gifs and videos at the same time on big screen</span></span>
               Please note that this is a prototype in BETA version, but we improve it every day !
               This tool uses the <a className="name" target="_blank" href="https://github.com/reddit-archive/reddit/wiki/API">Reddit API</a>, <a className="name" target="_blank" href="https://reddit.com">Reddit CDN</a>, <a className="name" target="_blank" href="https://gfycat.com">Gfycat CDN</a>, <a className="name" target="_blank" href="https://redgifs.com">RedGIFS CDN</a>, <a className="name" target="_blank" href="https://imgur.com">Imgur CDN</a>, <a className="name" target="_blank" href="https://giphy.com" >Giphy CDN</a> and <a className="name" target="_blank" href="http://www.youtube.com">Youtube API</a> but is not endorsed or certified by <a className="name" target="_blank" href="https://reddit.com">Reddit inc</a>, <a className="name" target="_blank" href="https://imgur.com">Imgur inc</a>, <a className="name" target="_blank" href="https://gfycat.com">Gfycat inc</a>, <a className="name" target="_blank" href="https://redgifs.com">RedGIFS inc</a>, <a className="name" target="_blank" href="https://giphy.com">Giphy inc</a> and <a className="name" target="_blank" href="http://www.youtube.com">Youtube, owned by Google inc</a>. We don't own any of the medias displayed.<br />All <a className="name" target="_blank" href="https://www.redditinc.com/policies/">Reddit&trade;</a>, <a className="name" target="_blank" href="https://help.imgur.com/hc/en-us/articles/202062878-Trademark-Use-Policy">Imgur&trade;</a>, <a className="name" target="_blank" href="https://gfycat.com/fr/terms">Gfycat&trade;</a>, <a className="name" target="_blank" href="https://giphy.com/terms">Giphy&trade;</a>, <a className="name"
                  target="_blank" href="http://www.google.com/permissions/trademark/our-trademarks.html">Youtube&trade;</a> logos, trademarks, images and videos displayed on this site are property of their respective owners.
               <br /><a className="email" href="mailto:contact@reddit.pics"><Icon className="envelope" />contact@reddit.pics</a>
            </div>
         </div>
      );
   }
}


export class TopBar extends React.Component {
   constructor(props) {
      super(props);
      
      this.addSubredditHandler = this.addSubredditHandler.bind(this);
      this.selectSubredditsHandler = this.selectSubredditsHandler.bind(this);
      this.selectSortHandler = this.selectSortHandler.bind(this);
      this.onSortOpen = this.onSortOpen.bind(this);
      this.selectSortTimeHandler = this.selectSortTimeHandler.bind(this);
      this.onSortClose = this.onSortClose.bind(this);
      this.closeSearch = this.closeSearch.bind(this);
      this.onOptionHeaderClick = this.onOptionHeaderClick.bind(this);
      this.onLinkClick = this.onLinkClick.bind(this);
      this.onLabelClick = this.onLabelClick.bind(this);
      this.onSearchChange = this.onSearchChange.bind(this);
      this.filterBySearchQuery = this.filterBySearchQuery.bind(this);
      this.renderSubredditLabel = this.renderSubredditLabel.bind(this);
      this.submit = this.submit.bind(this);
      this.onSubredditsMouseEnter = this.onSubredditsMouseEnter.bind(this);
      this.onSubredditsMouseLeave = this.onSubredditsMouseLeave.bind(this);
      this.onSubredditsSearchOpen = this.onSubredditsSearchOpen.bind(this);
      this.onSubredditsSearchClose = this.onSubredditsSearchClose.bind(this);
      this.onAutoPlayToggle = this.onAutoPlayToggle.bind(this);
      this.onOnlyGifsToggle = this.onOnlyGifsToggle.bind(this);
      this.onNSFWToggle = this.onNSFWToggle.bind(this);
      
      this.vars = this.props.vars;
   
      this.subredditsSearchResults = [];
      this.searchQuery = this.displayedSearchQuery = '';
      
      this.sortOptions = [];
      this.sortTimesOptions = [];
      
      this.updateParams(this.vars);
      
      for (let i = 0; i < this.vars.sortValues.length; i++) {
         this.sortOptions.push(
            {key: this.vars.sortValues[i], text:this.vars.sortValues[i], value: this.vars.sortValues[i]},
         );
      }
      
      for (let i = 0; i < this.vars.sortTimeValues.length; i++) {
         this.sortTimesOptions.push(
            {key: this.vars.sortTimeValues[i], text:this.vars.sortTimeTexts[ this.vars.sortTimeValues[i] ], value: this.vars.sortTimeValues[i]},
         );
      }
   
      this.subredditsSearchRef = React.createRef();
      this.sortRef = React.createRef();
      this.sortTimeRef = React.createRef();
      this.otherMenuRef = React.createRef();
   
      this.state = {
         // onlyGifs: this.vars.selectedMediaType === 2,
         allowAutoPlay: this.vars.allowAutoPlay,
         allowNSFW: !!this.vars.allowNSFW,
         // subredditsOptions: this.subredditsOptions,
         // selectedSubreddits: this.props.selectedSubreddits,
         // selectedSubredditsText: this.props.selectedSubredditsText,
         // selectedSort: this.props.selectedSort,
         // selectedSortTime: this.props.selectedSortTime,
      };
      
      console.log('TopBar state : ',this.state)
   }
   
   componentDidMount() {
      this.vars.setTopBarFixed();
   
      try {
         this.vars.galleryTopOffset += this.vars.topBarContainerRef.current.offsetHeight;
      } catch(e) {}
   }
   
   /*componentWillReceiveProps(nextProps, nextContext) {
      console.log('TopBar componentWillReceiveProps')
      // debugger;
      this.updateParams(nextProps);
      
   }*/
   
   updateParams(props, updateState = false) {
      console.log('TopBar updateParams');
      // debugger;
      this.displayedSearchQuery = '';
      this.selectedSubreddits = [...props.selectedSubreddits];
      this.selectedSubredditsText = props.selectedSubredditsText;
      this.selectedSort = props.selectedSort;
      this.selectedSortTime = props.selectedSortTime;
      
      this.onlyGifs = props.selectedMediaType === 2;
      
      this.initSubredditsOptions(props.selectedSubreddits, false, true);
      
      if (updateState)
         this.forceUpdate();
   }
   
   initSubredditsOptions(subreddits, removeSearchResults = false, removeDefaults = false) {
      console.log('initSubredditsOptions');
      
      if (!this.searchResultsAdded || removeSearchResults) {
         this.subredditsOptionsKeys = ['option-header'];
         this.subredditsOptions = [{key:'option-header', className:"option-header", text:'', value:'', content:'Add multiple subreddits :', onClick:this.onOptionHeaderClick}];
         
         if (!this.searchResultsAdded || !removeDefaults) {
            for (let i = 0; i < this.vars.defaultSubredditsOpions.length; i++) {
               this.subredditsOptionsKeys.push(this.vars.defaultSubredditsOpions[i]);
               this.subredditsOptions.push(
                  {
                     key: this.vars.defaultSubredditsOpions[i],
                     text: this.vars.defaultSubredditsOpions[i],
                     value: this.vars.defaultSubredditsOpions[i],
                     content: this.renderSubredditOptionContent(this.vars.defaultSubredditsOpions[i])
                  },
               );
            }
         }
      }
      else if (removeDefaults)
         this.removeDefaultOptions();
      
      this.addSubredditsOptions(subreddits);
   }
   
   removeDefaultOptions(/*subreddits*/) {
      console.log('removeDefaultOptions');
      
      for (let i = 0, index; i < this.vars.defaultSubredditsOpions.length; i++) {
         
         // if (subreddits.indexOf(this.vars.defaultSubredditsOpions[i]) !== -1)
         //    continue;
         
         
         if (this.selectedSubreddits.indexOf(this.vars.defaultSubredditsOpions[i]) === -1) {
            
            index = this.subredditsOptionsKeys.indexOf(this.vars.defaultSubredditsOpions[i]);
            
            this.subredditsOptionsKeys.splice(index, 1);
            this.subredditsOptions.splice(index, 1);
         }
      }
   }
   
   addSubredditsOptions(subreddits, searchQuery) {
      console.log('addSubredditsOptions');
      // debugger;
      if (subreddits && subreddits.length) {
         subreddits = [...subreddits];
         let index;
         
         for (let i=0; i < subreddits.length; i++) {
            index = this.subredditsOptionsKeys.indexOf(subreddits[i]);
            if (index === -1) {
               console.log('push : ',subreddits[i])
               this.subredditsOptionsKeys.push(subreddits[i]);
               
               this.subredditsOptions.push({
                  key: subreddits[i],
                  text: subreddits[i],
                  value: subreddits[i],
                  searchQuery,
                  content: this.renderSubredditOptionContent(subreddits[i])
               });
            }
         }
         
         // for (let i=0; i < subreddits.length; i++) {
         //    this.subredditsOptions.push({
         //       key: subreddits[i], text: subreddits[i], value: subreddits[i],
         //    });
         // }
      }
      return this.subredditsOptions;
   }
   
   onSearchChange(e, { searchQuery }) {
      console.log('onSearchChange : ',searchQuery);
   
      clearTimeout(this.redditSearchTimeout);
      
      // if (!searchQuery || !this.searchQuery) {
      //    this.initSubredditsOptions(this.selectedSubreddits, !this.searchQuery);
         
         if (!searchQuery) {
            this.initSubredditsOptions(this.selectedSubreddits, !!this.searchQuery);
            this.searchQuery = this.displayedSearchQuery = searchQuery;
            this.stopLoading();
            return;
         }
      // }
      
      this.searchQuery = this.displayedSearchQuery = searchQuery;
      
      if (this.subredditsSearchResults[searchQuery]) {
         return this.addSearchResults(searchQuery);
      }
      
      this.searchSubredditApi(searchQuery, this.vars.allowNSFW)
   }
   
   searchSubredditApi(searchQuery, allowNSFW = false, removeDefaults = false) {
      
      this.isLoading = true;
      this.forceUpdate();
      
      // debugger;
   
      this.redditSearchTimeout = setTimeout(() => {
   
         this.vars.gaAddEvent('TopBar', 'searchSubreddit', this.searchQuery);
         
         RedditAPI
            .searchSubreddits(searchQuery, allowNSFW)
            .fetch(
               (res)=>{
                  this.isLoading = false;
                  // this.lastRedditJson = res;
                  if (res.error) {
                     console.log('QUERY FETCH ERROR');
                     console.log(res.error);
                     this.stopLoading();
                  }
                  else {
                     console.log('REDDIT SEARCH RESULT : ',res);
                     
                     this.parseSubredditSearchJson(searchQuery, res, removeDefaults);
                  }
               },
               (err) => {
                  console.error(err);
                  this.stopLoading();
            });
         
      }, 1000);
      
   }
   
   stopLoading() {
      this.isLoading = false;
      this.forceUpdate();
   }
   
   filterBySearchQuery(options, query) {
      console.log('filterBySearchQuery query : '+query+' option : ',options);
      
      query = query.replace(/[-_/\\,.;~^"':?!&+*#{}()[\]|@=]+/g, ' ').trim().replace(/  +/g, '|');
      
      let regex = new RegExp('('+ query + ')', 'i');
      
      // return options.filter(option => option.isSearchResult || regex.test(option.value))
      let filteredOptions = options.filter((option, index) => {
         console.log('option '+index+' : ',option)
         console.log('regex.test('+option.value+') : ',regex.test(option.value));
         return option.searchQuery === query || regex.test(option.value);
      });
      
      if (filteredOptions.length)
         filteredOptions.unshift(options[0]);
      
      return filteredOptions;
   }
   
   parseSubredditSearchJson(searchQuery, searchJson, removeDefaults = false) {
      
      console.log('parseSubredditSearchJson : ',searchJson);
      // let item.json, item.isVideoForSure, isHD, isMultiSize, item.isGfycatFilesOk /*, minImageResolution*/;
      // debugger
   
      if (!searchJson.data || !searchJson.data.dist) {
         this.searchResultsAdded = false;
         this.stopLoading();
         return;
      }
      
      this.subredditsSearchResults[searchQuery] = [];
      
      for(let i = 0; i < searchJson.data.children.length; i++) {
         this.subredditsSearchResults[searchQuery].push( searchJson.data.children[i].data.display_name );
      }
      
      if (this.searchQuery === searchQuery)
         this.addSearchResults(searchQuery, removeDefaults);
   }
   
   addSearchResults(searchQuery, removeDefaults = false) {
      console.log('addSearchResults '+searchQuery+' : ',this.subredditsSearchResults[searchQuery]);
      this.searchResultsAdded = true;
      // this.removeDefaultOptions();
      this.initSubredditsOptions(this.selectedSubreddits, true, removeDefaults);
      this.addSubredditsOptions( this.subredditsSearchResults[searchQuery], searchQuery);
      this.stopLoading();
   }
   
   renderSubredditLabel(label) {
      return {content : (
         <Link className="sublink"
               to={'/r/'+label.value}
         >{label.text}</Link>
      )};
   }
   
   renderSubredditOptionContent(value) {
      return (
         <span>
            <span className="icon plus">+</span>
            <Link className="sublink"
                  to={'/r/'+value}
                  onClick={this.onLinkClick}
            >{value}</Link>
         </span>
      );
   }
   
   onLabelClick(e, { value }) {
      console.log('onLabelClick');
      this.selectOneSubreddit(value);
   }
   
   onLinkClick(e) {
      console.log('onLinkClick subreddit e.target : ',e.target.text);
      // this.isLinkClicked = true;
      e.preventDefault();
      e.stopPropagation();
      return this.selectOneSubreddit(e.target.text);
   }
   
   addSubredditHandler(e, { value }) {
      console.log('addSubredditHandler');
      this.canAutoCloseSearch = false;
      /*this.setState((prevState) => ({
         subredditsOptions: [{ text: value, value }, ...prevState.subredditsOptions],
      }))*/
      this.subredditsOptionsKeys.push(value);
      this.subredditsOptions.push({ key: value, text: value, value });
      this.forceUpdate(); /* A TRAITER */
   }
   
   selectSubredditsHandler(e, { value }) {
      console.log('selectSubredditsHandler');
      // debugger;
      /*if (this.isLinkClicked) {
         e.preventDefault();
         e.stopPropagation();
         return this.selectOneSubreddit(value[value.length-1]);
      }*/
      
      if (value[value.length-1] === '') { // press enter key
         return this.submit();
      }
      
      this.setSelectedSubreddits(value);
      this.canAutoCloseSearch = false;
      this.forceUpdate();
   }
   
   setSelectedSubreddits(value) {
      this.selectedSubreddits = value;
      this.selectedSubredditsText = value.join('+');
   }
   
   selectOneSubreddit(value) {
      console.log('selectOneSubreddit ');
      this.displayedSearchQuery = '';
      this.isAutoSearchOpen = true;
      this.canAutoCloseSearch = true;
      this.selectedSubreddits = [value];
      this.selectedSubredditsText = value;
      this.closeSearch();
      // this.forceUpdate();
      this.vars.navigateToUrl(this.vars.getPageUrl('/r/'+value) );
   }
   
   closeAllMenus() {
      this.closeSearch();
      this.restoreSortValues();
      try {
         this.sortRef.current.close();
         this.otherMenuRef.current.close();
         
         if (this.sortTimeRef.current)
            this.sortTimeRef.current.close();
      }
      catch(e) { console.error(e) }
   }
   
   closeSearch() {
      console.log('closeSearch subreddit this.isSearchOpen : ',this.isSearchOpen);
      if (this.isSearchOpen) {
         this._closeSearch();
      }
   }
   
   _closeSearch() {
      console.log('_closeSearch subreddit');
      // debugger;
      console.log('this.subredditsSearchRef.current : ',this.subredditsSearchRef.current);
      console.log('this.subredditsSearchRef.current.ref.current : ',this.subredditsSearchRef.current.ref.current);
      // this.subredditsSearchRef.current.sizerRef.current.blur();
      // this.subredditsSearchRef.current.ref.current.blur();
      // triggerMouseEvent(this.subredditsSearchRef.current.ref.current, 'mouseup');
      this.subredditsSearchRef.current.searchRef.current.blur();
      this.subredditsSearchRef.current.close();
      this.isAutoSearchOpen = false;
      // this.vars.topBarContainerRef.current.click();
      // document.body.click();
      // document.body.focus();
      this.subredditsSearchRef.current.ref.current.scrollTo(0,0);
   }
   
   submit() {
      console.log('submit subreddit');
      this.canAutoCloseSearch = true;
      
      if (this.displayedSearchQuery) {
         this.displayedSearchQuery = '';
         this.forceUpdate();
      }
      this.closeSearch();
      // debugger;
      // this.vars.setRedditParams({subreddits:this.state.selectedSubreddits});
      if (  this.selectedSubredditsText !== ''
         && (
            this.selectedSubredditsText !== this.vars.selectedSubredditsText
            || this.selectedSort !== this.vars.selectedSort
            || this.selectedSortTime !== this.vars.selectedSortTime
         )
      )
         this.vars.navigateToSubreddit(this.selectedSubredditsText, this.selectedSort, this.selectedSortTime, this.vars.selectedMediaType, this.vars.displayTitles);
   }
   
   onSubredditsSearchOpen(e1, e2) {
      console.log('onSubredditsSearchOpen e1 : ',e1);
      console.log('e2 : ',e2);
      this.isSearchOpen = true;
   }
   
   onSubredditsSearchClose() {
      console.log('onSubredditsSearchClose');
      this.isSearchOpen = false;
      // this.isAutoSearchOpen = false;
      // this.isLinkClicked = false;
      // this.canAutoCloseSearch = false;
      
      if (!this.selectedSubreddits.length) {
         this.setSelectedSubreddits(this.vars.selectedSubreddits);
         this.forceUpdate();
      }
      
   }
   
   restoreSortValues() {
      console.log('restoreSortValues');
      
      this.selectedSort = this.vars.selectedSort;
      this.selectedSortTime = this.vars.selectedSortTime;
   }
   
   onSortOpen() {
      console.log('onSortOpen');
      clearTimeout(this.submitSortTimeout);
   }
   
   onSortClose() {
      console.log('onSortClose');
      
      this.submitSortTimeout = setTimeout(()=>{
         if (this.selectedSort !== this.vars.selectedSort)
            this.submit();
      }, 100);
   }
   
   onOptionHeaderClick(e) {
      console.log('onOptionHeaderClick');
   
      e.preventDefault();
   }
   
   onSubredditsMouseEnter() {
      // return;
      // if (!this.isSearchOpen && this.subredditsSearchRef.current) {
         console.log('onSubredditsMouseEnter ');
         console.log('this.subredditsSearchRef.current : ',this.subredditsSearchRef.current)
         // console.log('this.subredditsSearchRef.current.refs : ',this.subredditsSearchRef.current.refs)
      clearTimeout(this.closeSearchTimeout);
      // debugger;
      if (this.subredditsSearchRef.current && !this.isSearchOpen && !this.isAutoSearchOpen) {
         this.isAutoSearchOpen = true;
         this.canAutoCloseSearch = true;
         // this.subredditsSearchRef.current.ref.current.mouseDown();
         // triggerMouseEvent(this.subredditsSearchRef.current.ref.current, 'mousedown');
         this.subredditsSearchRef.current.open();
         // this.subredditsSearchRef.current.ref.current.click();
         // this.subredditsSearchRef.current.searchRef.current.click();
         // this.subredditsSearchRef.current.searchRef.current.focus();
      }
   }
   
   onSubredditsMouseLeave() {
      console.log('onSubredditsMouseLeave');
      // return;
      // debugger;
      
      if (this.isAutoSearchOpen && this.canAutoCloseSearch && this.subredditsSearchRef.current && !this.displayedSearchQuery) {
         this.closeSearchTimeout = setTimeout(() => {
            this._closeSearch();
         }, 400);
      }
   }
   
   selectSortHandler(e, { value }) {
      console.log('selectSortHandler : ',this.selectedSort);
      this.selectedSort = value;
      if (value === 'top') {
         // this.selectedSortTime = 'day';
         
         this.forceUpdate(() => {
            try {
               // debugger;
               this.sortTimeRef.current.open();
            } catch (e) {}
         });
      }
      else
         this.forceUpdate(() => {
            this.submit();
         });
   }
   
   onAutoPlayToggle(e) {
      this.setState({
         allowAutoPlay: !this.vars.allowAutoPlay,
      });
      this.vars.toggleAutoPlay();
   }
   
   onOnlyGifsToggle(e) {
      // debugger;
      this.onlyGifs = !this.onlyGifs;
      this.vars.navigateToParams('/' + this.vars.selectedType + '/' +this.vars.selectedSubredditsText, this.vars.selectedSort, this.vars.selectedSortTime, this.onlyGifs ? 2 : 1);
      this.forceUpdate();
      // this.setState({
      //    onlyGifs: !this.state.onlyGifs,
      // });
      // this.vars.setAllowTypes(this.state.onlyGifs ? 1 : 2);
   }
   
   onNSFWToggle() {
      console.log('onNSFWToggle')
      // debugger;
      
      if (!this.vars.allowNSFW)
         this.vars.displayWarningNSFW();
      else {
         this.vars.setAllowNSFW(false);
         this.setAllowNSFW(false);
      }
   }
   
   setAllowNSFW(allowNSFW) {
      console.log('TopBar setAllowNSFW : ',allowNSFW);
      this.reloadSearch(false, allowNSFW);
      
      this.setState({allowNSFW});
   }
   
   reloadSearch(forceUpdate = true, allowNSFW = false) {
      this.searchResultsAdded = false;
      this.subredditsSearchResults = [];
      this.initSubredditsOptions(this.selectedSubreddits, true);
      
      if (this.searchQuery)
         this.searchSubredditApi(this.searchQuery, allowNSFW, true);
      
      else if (forceUpdate)
         this.forceUpdate();
   }
   
   selectSortTimeHandler(e, { value }) {
      this.selectedSortTime = value;
      this.forceUpdate(() => {
         this.submit();
      });
   }
   
   
   /*
   state = {
      isFetching: false,
      search: true,
      searchQuery: null,
      value: [],
      options: getOptions(),
   }
   
   handleSearchChange = (e, { searchQuery }) => this.setState({ searchQuery })
   
   fetchOptions = () => {
      this.setState({ isFetching: true })
      
      setTimeout(() => {
         this.setState({ isFetching: false, options: getOptions() })
         this.selectRandom()
      }, 500)
   }
   
   render() {
      const { multiple, options, isFetching, search, value } = this.state

<Button onClick={this.fetchOptions}>Fetch</Button>
<Dropdown
   search={search}
   options={options}
   value={value}
   onChange={this.handleChange}
   onSearchChange={this.handleSearchChange}
   loading={isFetching}
/>
   */
   
   
   
   
   
   render() {
      console.log('TopBar Render');
      // console.log('this.subredditsOptions : ',this.subredditsOptions)
      console.log('this.state.allowNSFW : ',this.state.allowNSFW);
      const displaySortTime = this.selectedSort === 'top';
      
      return (
         <div className={'topbar '/*+(this.isTouchScreen ? 'touch ' : 'no-touch ')+*/}
              ref={this.vars.topBarContainerRef}
              onMouseMove={this.vars.onMouseMoveTopBar}
              onMouseLeave={this.vars.onMouseLeaveTopBar}
         >
            <div className={'topbar-inner '/*+(this.isTouchScreen ? 'touch ' : 'no-touch ')+*/}
               ref={this.vars.topBarInnerRef}
            >
               {/*<div className="left"*/}
               {/*   // ref={this.topBarRef}*/}
               {/*>*/}
                  <a className="logo"
                     href="https://reddit.pics">
                     {/*<img src={logoPNG} alt="Reddit.pics logo" />*/}
                     reddit.pics <span className="app-version">0.4</span>
                  </a>
                  <div
                     className="subreddits-container"
                     onMouseEnter={this.onSubredditsMouseEnter}
                     onMouseLeave={this.onSubredditsMouseLeave}
                  >
                     <Dropdown
                        item
                        className="topbar-item subreddits-search"
                        ref={this.subredditsSearchRef}
                        options={this.subredditsOptions}
                        placeholder="Search a subreddit"
                        // additionLabel="Add subreddit : "
                        search={this.filterBySearchQuery}
                        selection
                        openOnFocus
                        closeOnBlur
                        closeOnEscape
                        // fluid
                        compact
                        // scrolling
                        clearable
                        multiple
                        // allowAdditions
                        // noResultsMessage={null}
                        searchQuery={this.displayedSearchQuery}
                        value={this.selectedSubreddits}
                        loading={this.isLoading}
                        onSearchChange={this.onSearchChange}
                        renderLabel={this.renderSubredditLabel}
                        onLabelClick={this.onLabelClick}
                        onAddItem={this.addSubredditHandler}
                        onChange={this.selectSubredditsHandler}
                        onOpen={this.onSubredditsSearchOpen}
                        onClose={this.onSubredditsSearchClose}
                     />
                     <Button
                        className="topbar-item subreddits-submit"
                        // icon
                        // labelPosition='right'
                        color="blue"
                        onClick={this.submit}
                     >
                        Go
                        {/*<Icon name='right arrow' />*/}
                     </Button>
                  </div>
                  <div className={'sort-container '+(displaySortTime ? 'top':'')}>
                     <Dropdown
                        className="topbar-item sort"
                        ref={this.sortRef}
                        options={this.sortOptions}
                        placeholder='sort'
                        selection
                        compact
                        openOnFocus
                        closeOnBlur
                        closeOnEscape
                        // fluid
                        // scrolling
                        value={this.selectedSort}
                        onChange={this.selectSortHandler}
                        onOpen={this.onSortOpen}
                        onClose={this.onSortClose}
                     />
                     {displaySortTime ?
                        <Dropdown
                           className="topbar-item time"
                           ref={this.sortTimeRef}
                           options={this.sortTimesOptions}
                           // placeholder='by time'
                           selection
                           compact
                           openOnFocus
                           closeOnBlur
                           closeOnEscape
                           // fluid
                           // scrolling
                           value={this.selectedSortTime}
                           onChange={this.selectSortTimeHandler}
                           onOpen={this.onSortOpen}
                           onClose={this.onSortClose}
                        />
                        : null
                     }
               </div>
               {/*</div>*/}
               {/*<div className="right">*/}
                  <div className="params-container"
                  >
                     <Checkbox
                        className="topbar-item topbar-checkbox white"
                        toggle
                        label="AutoPlay"
                        checked={this.state.allowAutoPlay}
                        onChange={this.onAutoPlayToggle}
                     />
                     <Checkbox
                        className="topbar-item topbar-checkbox white"
                        toggle
                        label="Only Gifs"
                        checked={this.onlyGifs}
                        onChange={this.onOnlyGifsToggle}
                     />
                     <Dropdown
                        className="topbar-item other-menu"
                        ref={this.otherMenuRef}
                        direction="left"
                        icon={(<span className="other-menu-icon"></span>)}
                        multiple
                     >
                        <Dropdown.Menu>
                           <Dropdown.Item>
                              <Checkbox
                                 className="topbar-checkbox"
                                 toggle
                                 label="Allow NSFW"
                                 checked={this.state.allowNSFW}
                                 onChange={this.onNSFWToggle}
                              />
                           </Dropdown.Item>
                           <Dropdown.Item>
                              <a className="change-consent" title="Privacy Settings" onClick={this.vars.displayConsentUi}><Icon name="setting" />Privacy</a>
                           </Dropdown.Item>
                           <Dropdown.Item>
                              <a className="change-consent" title="About this app" onClick={this.vars.displayDisclaimer}><Icon name="info circle" />About</a>
                           </Dropdown.Item>
                        </Dropdown.Menu>
                     </Dropdown>

                  </div>
               </div>
            </div>
         // </div>
      );
   }
}


export function LoadingFooter(props) {
   console.log('LoadingFooter Render');
   const text = '.....';
   
   props = props.vars;
   
   const emojisElement = window.Modernizr.emoji ?
      (
         <span className="emoji">
            <span className={'container e'+props.loadingFooterEmojiTrack}
                  ref={props.loadingFooterEmojisContainerRef}>
               {props.loadingFooterEmojis.map((row, index) =>
                  <span className="track" key={index}>
                     {row.map((emoji, index) => (<span className="inner" key={index}>{emoji}</span>))}
                  </span>
               )}
            </span>
         </span>
      )
      : null;
   
   return (
      <div className={"loading-footer "+(props.isFirstLoad || props.isLoadingPage ? 'on':'')}
           ref={props.loadingFooterRef}
      >
         <a className="bt-load"
            ref={props.loadingFooterButtonRef}
            onClick={props.loadNextPage}
         >Load more</a>
         <div className="animation">
            {/*Loading<span className="anim"><span className="inner">.......</span></span>*/}
            {emojisElement} Loading<span className="anim"><span className="inner">{text}</span><span className="invisible">{text}</span></span>
         </div>
      </div>
   );
}



export class WarningNSFW extends React.PureComponent {
   constructor(props) {
      super(props);
      
      this.vars = props.vars;
      
      this.state = {
         display: props.display,
      }
   }
   
   render() {
      console.log('WarningNSFW Render');
      
      if (!this.state.display)
         return null;
      
      return (
         <div className="nsfw-warning">
            <div className="msg">
               <h2>This page may contain some adult content</h2>
               <p className="legal">You must be at least 18 years old to view this content</p>
               <p>Are you over eighteen and willing to see adult content ?</p>
               <Button.Group>
                  <Button size="huge" negative onClick={() => this.vars.setAllowNSFW(false, true)}>NO</Button>
                  <Button size="huge" basic color="red" onClick={() => this.vars.setAllowNSFW(true, true)}>YES</Button>
               </Button.Group>
            </div>
         </div>
      );
   }
}

export class Gallery extends React.Component {
   
   constructor(props) {
      super(props);
   
      /*this.state = {
         rows: this.props.rows,
         // rowsRefs: this.props.rowsRefs,
         // visibleRows: this.props.visibleRows,
         allowAutoPlay: this.props.allowAutoPlay,
      };*/
      this.isFirstRender = true;
      this.vars = this.props.vars;
   }
   
   componentDidMount() {
      this.isFirstRender = false;
   }
   
   
   render() {
      console.log('Gallery Render');
      
      if (!this.vars.groups || !this.vars.groups.length) {
         if (this.isFirstRender)
            return (
               <div className="no-results">Loading...</div>
            );
         
         const defaultMediaTypeUrl = this.vars.getDefaultMediaTypeUrl();
         return (
            <div className="no-results">
               <span className="emoji">:(</span> No {this.vars.selectedMediaType === 2 ? 'gifs' : 'results'} found...
               {
                  this.vars.selectedMediaType !== 1 ?
                     <Link to={defaultMediaTypeUrl}>Display all posts without filters</Link>
                  :
                     <Link to="/">Go to homepage</Link>
                     
               }
            </div>
         );
      }
      
      
      const pages = [];
      // debugger;
      for (let group = 0; group < this.vars.groups.length; group++) {
         
         pages.push(
            <GalleryGroup
               key={this.vars.selectedSlug+'group'+group}
               ref={this.vars.groups[group].ref}
               groupIndex={group}
               vars={this.vars}
               visible={this.vars.groups[group].visible}
            />
         );
      }
   
      return (
         <div
            className={this.vars.nbMediasPerRow > 1 ? 'flex-container' : ''}
         >
            {pages}
            <span className="copyright">Reddit.pics &copy; 2020. All rights reserved</span>
         </div>
      );
   }
   
   /*render() {
      console.log('Gallery Render');
   
      if (!this.vars.rows || !this.vars.rows.length)
         return null;
      
   
      const rows = [];
      // console.log('this.lastItemId : ',this.lastItemId)
      // console.log('this.vars.visibleRows : ',this.vars.visibleRows);
      // console.log('this.vars.rowsRefs : ',this.vars.rowsRefs);
      console.log('this.vars.rows : ',this.vars.rows);
      console.log('this.vars.selectedSlug: ',this.vars.selectedSlug);
      // debugger;
      for (let row = 0; row < this.vars.rows.length; row++) {
         
         rows.push(
            <React.Fragment key={this.vars.selectedSlug+'row'+row}>
               <GalleryRow
                  key={this.vars.selectedSlug+'galleryrow'+row}
                  // ref={this.vars.rows[row].ref}
                  ref={this.vars.rows[row].ref}
                  rowIndex={row}
                  vars={this.vars}
                  isLastRow={row === this.vars.rows.length - 1}
                  // slug={this.vars.selectedSlug}
                  // allowAutoPlay={this.vars.allowAutoPlay}
                  // onlyGifs={this.vars.onlyGifs}
                  // updated={this.vars.rows[row].updated}
                  visible={this.vars.rows[row].visible}
                  play={this.vars.rows[row].play}
                  // items={this.vars.rows[row]}
                  // removedMedias={this.vars.removedMedias}
                  // visible={row >= this.vars.visibleRows.first - this.vars.nbRowPreloading && row <= this.vars.visibleRows.last + this.vars.nbRowPreloading}
                  // lastItemId={this.props.lastItemId}
                  // // letBrowserAutoPlay={this.props.letBrowserAutoPlay}
                  // // canVideoPlay={this.props.canVideosPlay || (this.props.isFirstLoad && !rowIndex)}
                  // onMouseLeaveMedia={this.props.onMouseLeaveMedia}
                  // onMouseMoveMedia={this.props.onMouseMoveMedia}
                  // onTouchStartMedia={this.props.onTouchStartMedia}
                  // onTouchMoveMedia={this.props.onTouchMoveMedia}
                  // onTouchEndMedia={this.props.onTouchEndMedia}
                  // onClickMedia={this.props.onClickMedia}
                  // onClickTitle={this.props.onClickTitle}
                  // onTouchEndTitle={this.props.onTouchEndTitle}
                  // onTouchEndButton={this.props.onTouchEndButton}
                  // onClickButton={this.props.onClickButton}
                  // // onClickSource={this.props.onClickSource}
                  // // onClickDownload={this.props.onClickDownload}
                  // // onClickShare={this.props.onClickShare}
                  // onNavNextToMedia={this.props.onNavNextToMedia}
                  // onNavPrevToMedia={this.props.onNavPrevToMedia}
                  // toggleVideoSize={this.props.toggleVideoSize}
                  // toggleImgSize={this.props.toggleImgSize}
                  // onPlay={this.props.onPlay}
                  // // onTimeUpdate={this.props.onTimeUpdate}
                  // onPause={this.props.onPause}
                  // onVolumeChangeMedia={this.props.onVolumeChangeMedia}
                  // onVideoLoadingProgress={this.props.onVideoLoadingProgress}
                  // // onImgLoadingProgress={this.props.onImgLoadingProgress}
                  // onImgLoaded={this.props.onImgLoaded}
                  // onImgError={this.props.onImgError}
                  // // onVideoDownloadWait={this.props.onVideoDownloadWait}
                  // onVideoError={this.props.onVideoError}
                  // goFullscreen={this.props.goFullscreen}
                  // exitFullscreen={this.props.exitFullscreen}
               />
               <div key={'break' + row} className="break"/>
            </React.Fragment>
         );
      }
   
      return (
         <div
            className="flex-container">
            {rows}
         </div>
      );
   }*/
}

export class GalleryGroup extends React.Component {
   constructor(props) {
      super(props);
      
      this.state = {
         // items: this.props.items,
         visible: this.props.visible,
      };
      
      this.vars = this.props.vars;
   }
   
   shouldComponentUpdate(nextProps, nextState, nextContext) {
      console.log('Should GalleryGroup Render '+this.props.groupIndex+' : ',this.props.visible || nextProps.visible)
      // console.log('GalleryGroup Render this.props.visible : ',this.props.visible)
      // console.log('GalleryGroup Render nextProps.visible : ',nextProps.visible)
      return this.props.visible || nextProps.visible;
   }
   
   renderVisibleRows() {
   
      const rows = [];
      
      for (let i = 0, row = this.group.rows[i]; i < this.group.rows.length; i++, row = this.group.rows[i]) {
         
         rows.push(
            <React.Fragment key={this.vars.selectedSlug+'row'+row}>
               <GalleryRow
                  key={this.vars.selectedSlug+'galleryrow'+row}
                  // ref={this.vars.rows[row].ref}
                  ref={this.vars.rows[row].ref}
                  rowIndex={row}
                  vars={this.vars}
                  isLastRow={row === this.vars.rows.length - 1}
                  // slug={this.vars.selectedSlug}
                  // allowAutoPlay={this.vars.allowAutoPlay}
                  // onlyGifs={this.vars.onlyGifs}
                  // updated={this.vars.rows[row].updated}
                  visible={this.vars.rows[row].visible}
                  play={this.vars.rows[row].play}
                  // items={this.vars.rows[row]}
                  // removedMedias={this.vars.removedMedias}
                  // visible={row >= this.vars.visibleRows.first - this.vars.nbRowPreloading && row <= this.vars.visibleRows.last + this.vars.nbRowPreloading}
                  // lastItemId={this.props.lastItemId}
                  // // letBrowserAutoPlay={this.props.letBrowserAutoPlay}
                  // // canVideoPlay={this.props.canVideosPlay || (this.props.isFirstLoad && !rowIndex)}
                  // onMouseLeaveMedia={this.props.onMouseLeaveMedia}
                  // onMouseMoveMedia={this.props.onMouseMoveMedia}
                  // onTouchStartMedia={this.props.onTouchStartMedia}
                  // onTouchMoveMedia={this.props.onTouchMoveMedia}
                  // onTouchEndMedia={this.props.onTouchEndMedia}
                  // onClickMedia={this.props.onClickMedia}
                  // onClickTitle={this.props.onClickTitle}
                  // onTouchEndTitle={this.props.onTouchEndTitle}
                  // onTouchEndButton={this.props.onTouchEndButton}
                  // onClickButton={this.props.onClickButton}
                  // // onClickSource={this.props.onClickSource}
                  // // onClickDownload={this.props.onClickDownload}
                  // // onClickShare={this.props.onClickShare}
                  // onNavNextToMedia={this.props.onNavNextToMedia}
                  // onNavPrevToMedia={this.props.onNavPrevToMedia}
                  // toggleVideoSize={this.props.toggleVideoSize}
                  // toggleImgSize={this.props.toggleImgSize}
                  // onPlay={this.props.onPlay}
                  // // onTimeUpdate={this.props.onTimeUpdate}
                  // onPause={this.props.onPause}
                  // onVolumeChangeMedia={this.props.onVolumeChangeMedia}
                  // onVideoLoadingProgress={this.props.onVideoLoadingProgress}
                  // // onImgLoadingProgress={this.props.onImgLoadingProgress}
                  // onImgLoaded={this.props.onImgLoaded}
                  // onImgError={this.props.onImgError}
                  // // onVideoDownloadWait={this.props.onVideoDownloadWait}
                  // onVideoError={this.props.onVideoError}
                  // goFullscreen={this.props.goFullscreen}
                  // exitFullscreen={this.props.exitFullscreen}
               />
               {this.vars.renderRowBreak(row)}
            </React.Fragment>
         );
      }
   
      return rows;
   }
   
   
   renderInvisibleRows() {
   
      const rows = [];
   
      for (let i = 0, row = this.group.rows[i]; i < this.group.rows.length; i++, row = this.group.rows[i]) {
         rows.push(
            <React.Fragment key={this.vars.selectedSlug+'row'+row}>
               <React.Fragment key={this.vars.selectedSlug+'galleryrow'+row}>
                  {this.vars.renderInvisibleItems(this.vars.rows[row], i === this.group.rows.length-1)}
               </React.Fragment>
               {this.vars.renderRowBreak(row)}
            </React.Fragment>
         );
      }
      
      return rows;
   }
   
   
   render() {
      console.log('GalleryGroup Render ',this.props.groupIndex);
      
      this.group = this.vars.groups[ this.props.groupIndex ];
      
      if (this.group.visible)
         return this.renderVisibleRows();
      else
         return this.renderInvisibleRows();
   }
}

export class GalleryRow extends React.Component {
   constructor(props) {
      super(props);
      
      this.state = {
         // items: this.props.items,
         visible: this.props.visible,
         play: this.props.play,
      };
      
      this.vars = this.props.vars;
   }
   
   shouldComponentUpdate(nextProps, nextState, nextContext) {
      // console.log('Should GalleryRow Render '+this.props.rowIndex+' : ',this.props.visible || nextProps.visible)
      // console.log('GalleryRow Render this.props.visible : ',this.props.visible)
      // console.log('GalleryRow Render nextProps.visible : ',nextProps.visible)
      return this.props.visible || nextProps.visible;
      // slug={this.vars.selectedSlug}
      // allowAutoPlay={this.vars.allowAutoPlay}
      // onlyGifs={this.vars.onlyGifs}
      // updated={this.vars.rows[row].updated}
      // play={this.vars.rows[row].play}
   }
   
   renderVisibleItems() {
      
      const rows = [];
      
      for (let mediaIndex = 0; mediaIndex < this.row.items.length; mediaIndex++) {
         const media = this.row.items[mediaIndex];
         // if (this.vars.removedMedias[media.id])
         //    return;
   
   
         // title: decodeHTMLEntities(vars.json.title),
         // redditUrl: decodeHTMLEntities(vars.json.permalink),
         // redditId: vars.json.id,
         // subreddit: vars.json.subreddit,
         // waiting: false,
         // // empty: false,
         // visible: false,
         // clicked: false,
         // play: false,
         // playing: false,
         // mustDisplayHD: false,
         // playStarted: false
         
         rows.push(
            this.vars.renderItemCoontainer(this.row, this.props.isLastRow, media, mediaIndex, (
               <Item
                  key={media.id}
                  // ref={media.refs.component}
                  media={media}
                  visible={media.visible}
                  play={media.play}
                  waiting={media.waiting}
                  clicked={media.clicked}
                  playing={media.playing}
                  mustDisplayHD={media.mustDisplayHD}
                  playStarted={media.playStarted}
                  
                  vars={this.vars}
                  updated={media.updated}
                  rowVisible={this.state.visible}
                  // // visible={this.props.visible}
                  // // isOneVideoRow={this.state.items.length === 1}
                  // // allowMinSize={media.redditId !== this.props.lastItemId && mediaIndex === this.state.items.length - 1 && this.props.isLastRow}
                  // allowAutoPlay={this.props.allowAutoPlay}
                  // // letBrowserAutoPlay={this.props.letBrowserAutoPlay}
                  // // canVideoPlay={this.props.canVideosPlay || (this.props.isFirstLoad && !rowIndex)}
                  renderRatioImg={this.renderRatioImg}
                  // onMouseLeaveMedia={this.props.onMouseLeaveMedia}
                  // onMouseMoveMedia={this.props.onMouseMoveMedia}
                  // onTouchStartMedia={this.props.onTouchStartMedia}
                  // onTouchMoveMedia={this.props.onTouchMoveMedia}
                  // onTouchEndMedia={this.props.onTouchEndMedia}
                  // onClickMedia={this.props.onClickMedia}
                  // onClickTitle={this.props.onClickTitle}
                  // onTouchEndTitle={this.props.onTouchEndTitle}
                  // onTouchEndButton={this.props.onTouchEndButton}
                  // onClickButton={this.props.onClickButton}
                  // // onClickSource={this.props.onClickSource}
                  // // onClickDownload={this.props.onClickDownload}
                  // // onClickShare={this.props.onClickShare}
                  // onNavNextToMedia={this.props.onNavNextToMedia}
                  // onNavPrevToMedia={this.props.onNavPrevToMedia}
                  // toggleVideoSize={this.props.toggleVideoSize}
                  // toggleImgSize={this.props.toggleImgSize}
                  // onPlay={this.props.onPlay}
                  // // onTimeUpdate={this.props.onTimeUpdate}
                  // onPause={this.props.onPause}
                  // onVolumeChangeMedia={this.props.onVolumeChangeMedia}
                  // onVideoLoadingProgress={this.props.onVideoLoadingProgress}
                  // // onImgLoadingProgress={this.props.onImgLoadingProgress}
                  // onImgLoaded={this.props.onImgLoaded}
                  // onImgError={this.props.onImgError}
                  // // onVideoDownloadWait={this.props.onVideoDownloadWait}
                  // onVideoError={this.props.onVideoError}
                  // // goFullscreen={this.props.goFullscreen}
                  // // exitFullscreen={this.props.exitFullscreen}
               />
            )
         ));
      }
      
      return rows;
   }
   
   
   render() {
      console.log('GalleryRow Render ',this.props.rowIndex);
      
      this.row = this.vars.rows[ this.props.rowIndex ];
   
      this.row.height = this.vars.getRowHeight(this.row);
      
      if (this.row.visible)
         return this.renderVisibleItems();
      else
         return this.vars.renderInvisibleItems(this.row, this.props.isLastRow);
   }
}

export class App extends React.Component {
   
   constructor(props) {
      
      super(props);
      this.addFullscreenEvents = this.addFullscreenEvents.bind(this);
      this.addEventHandlers = this.addEventHandlers.bind(this);
      
      this.toggleAutoPlay = this.toggleAutoPlay.bind(this);
      this.onClickDocument = this.onClickDocument.bind(this);
      this.onMouseDownCaptureDocument = this.onMouseDownCaptureDocument.bind(this);
      this.onMouseUpCaptureDocument = this.onMouseUpCaptureDocument.bind(this);
      this._onMouseMove = this._onMouseMove.bind(this);
      this.onTouchStart = this.onTouchStart.bind(this);
      this.onTouchMove = this.onTouchMove.bind(this);
      this.onMouseMoveMedia = this.onMouseMoveMedia.bind(this);
      this.onMouseLeaveMedia = this.onMouseLeaveMedia.bind(this);
      this.onClickMedia = this.onClickMedia.bind(this);
      this.onTouchStartMedia = this.onTouchStartMedia.bind(this);
      this.onTouchMoveMedia = this.onTouchMoveMedia.bind(this);
      this.onTouchEndMedia = this.onTouchEndMedia.bind(this);
      this.onTouchEndButton = this.onTouchEndButton.bind(this);
      this.onTouchEndTitle = this.onTouchEndTitle.bind(this);
      this.onClickTitle = this.onClickTitle.bind(this);
      this.onClickButton = this.onClickButton.bind(this);
      // this.onClickSource = this.onClickSource.bind(this);
      // this.onClickDownload = this.onClickDownload.bind(this);
      // this.onClickShare = this.onClickShare.bind(this);
      this.onVolumeChangeMedia = this.onVolumeChangeMedia.bind(this);
      this.onNavNextToMedia = this.onNavNextToMedia.bind(this);
      this.onNavPrevToMedia = this.onNavPrevToMedia.bind(this);
      this.toggleVideoSize = this.toggleVideoSize.bind(this);
      this.onPlay = this.onPlay.bind(this);
      // this.onTimeUpdate = this.onTimeUpdate.bind(this);
      this.onPause = this.onPause.bind(this);
      this.onVideoLoadingProgress = this.onVideoLoadingProgress.bind(this);
      // this.onImgLoadingProgress = this.onImgLoadingProgress.bind(this);
      this.onImgLoaded = this.onImgLoaded.bind(this);
      this.onImgError = this.onImgError.bind(this);
      this.onVideoError = this.onVideoError.bind(this);
      // this.onVideoDownloadWait = this.onVideoDownloadWait.bind(this);
      this.fullscreenHandler = this.fullscreenHandler.bind(this);
      this.toggleFullscreen = this.toggleFullscreen.bind(this);
      this.goFullscreen = this.goFullscreen.bind(this);
      this.exitFullscreen = this.exitFullscreen.bind(this);
      this.loadNextPage = this.loadNextPage.bind(this);
   
   
      this.renderRatioImg = this.renderRatioImg.bind(this);
      this._getMediaStyle = this._getMediaStyle.bind(this);
      this.renderInvisibleItems = this.renderInvisibleItems.bind(this);
      this.renderItemCoontainer = this.renderItemCoontainer.bind(this);
      
      this.getParamCookie = this.getParamCookie.bind(this);
      this.setParamCookie = this.setParamCookie.bind(this);
      this.removeParamCookie = this.removeParamCookie.bind(this);
      this.displayConsentUi = this.displayConsentUi.bind(this);
      this.getPublisherConsents = this.getPublisherConsents.bind(this);
   
      this.onMouseMoveTopBar = this.onMouseMoveTopBar.bind(this);
      this.onMouseLeaveTopBar = this.onMouseLeaveTopBar.bind(this);
      this.displayTopBar = this.displayTopBar.bind(this);
      this._displayTopBar = this._displayTopBar.bind(this);
      this.hideTopBar = this.hideTopBar.bind(this);
      
      this.addRGPDHandler = this.addRGPDHandler.bind(this);
      this.displayDisclaimer = this.displayDisclaimer.bind(this);
      this._hideDisclaimer = this._hideDisclaimer.bind(this);
      this.hideDisclaimer = this._hideDisclaimer;
      
      this.removeOldCookies();
      this.addRGPDHandler();
      
      this.isMobile = isMobile();
      
      // document.body.classList.add('hover', 'titles', 'title');
      
      console.log('PROPS : ',this.props);
      
      /*this.state = {
         // allowAutoPlay: true,
         // displayTitlesOnEvent: true,
         // onlyHD: false,
         // allowRemoveErrorItems: true,
         
         // selectedSubreddits: [],
         // selectedSubredditsText: '',
         // selectedSort: 'hot', // top, hot, new
         // selectedFilter: 'all', // all, year, month, today
      };*/
   
      this.galleryTopOffset = 0;
      
      this.nbRowsPerGroup = 5;
      this.maxVisibleRows = 3;
      this.nbRowPreloadingNext = 2;
      this.nbRowPreloadingBack = 1;
      this.nbMediasPerRow = this.getNbMediasPerRow();
   
      this.toggleDesktopFunctions(this.nbMediasPerRow);
      
      this.nbRowsPerGroup = this.nbRowPreloadingBack + this.maxVisibleRows + this.nbRowPreloadingNext;
      
      // this.removedMedias = {};
      this.initRows();
      // debugger;
      // this.letBrowserAutoPlay = false;
      
      this.allowNSFW = this.getParamCookie(USER_PARAMS.ALLOW_NSFW) === "true";
      // this.allowNSFW = false;
      
      this.allowAutoPlay = this.getParamCookie(USER_PARAMS.ALLOW_AUTO_PLAY);
      this.allowAutoPlay = typeof this.allowAutoPlay === 'undefined' ? true : this.allowAutoPlay === "true";
      // this.allowAutoPlay = true;
      
      this.isDisclaimerHidden = !!this.getParamCookie(USER_PARAMS.IS_DISCLAIMER_HIDDEN);
      
      this.allowFullScreen = true;
      this.allowFullscreenVolume = false;
   
      this.documentWidth = document.body.clientWidth;
      
      this.minRedditPosterSurface = 272640;
      this.minRedditPosterResolution = 3;
      this.minRedditImgResolution = 4;
      this.maxRedditImgResolution = 5;
      this.minRedditVideoResolution = 4;
      
      // this.typeReg = /(r|u|c)/;
      this.typeValues = ['r','u','c'];
      this.defaultType = 'r';
      
      this.sortReg = /sort=(hot|new|top)/;
      this.sortValues = ['hot','new','top'/*,'rising'*/];
      this.defaultSort = 'hot';
      
      this.sortTimeReg = /t=(hour|day|week|month|year|all)/;
      this.sortTimeValues = ['hour','day','week','month','year','all'];
      this.sortTimeTexts = {
         hour:'now',
         day:'today',
         week:'this week',
         month:'this month',
         year:'this year',
         all:'all time',
      };
      this.defaultSortTime = 'day';
      
      this.itemReg = /^[-_a-z0-9]+$/i;
      this.pagesReg = /^[-_a-z0-9]+$/i;
      
      this.defaultSubreddits = ['EarthPorn'];
      this.defaultSubredditsOpions = ['EarthPorn','Art','gifs','steampunks','interestingasfuck','oddlysatisfying'];
      
      this.mediaTypeReg = /mt=([0-2])/;
      this.defaultMediaType = 1;
      
      this.selectedMediaType = this.getParamCookie(USER_PARAMS.MEDIA_TYPE);
      this.selectedMediaType = typeof this.selectedMediaType === 'undefined' ? this.defaultMediaType : this.selectedMediaType * 1;
      
      this.selectedMediaType = this.defaultMediaType;
   
      this.displayTitlesReg = /dt=([0-2])/;
      this.displayMobileTitlesLong = false;
      this.defaultDisplayTitles = 1;
      this.setDisplayTitles(this.defaultDisplayTitles);
      
      
      this.nextPageAfter = null;
      // this.lastPageIsItem = false; /* PAS BESOIN DANS LE CONTRUCTOR */
      // this.lastRedditJson = null;
      // this.canLoadNextPage = false; /* PAS BESOIN DANS LE CONTRUCTOR */
      // this.isLoadingPage = false; /* PAS BESOIN DANS LE CONTRUCTOR */
      // this.isLoadingNewPage = false; /* PAS BESOIN DANS LE CONTRUCTOR */
      this.loadErrors = 0;
      
      this.selectedSlug = '';
      // this.selectedType = 'r';
      // this.selectedSubreddits = this.selectedSubredditsText = this.selectedSort = this.selectedSortTime = this.selectedItem = null; /* PAS BESOIN DANS LE CONTRUCTOR */
   
      this.documentHidden = document.hidden;
      // this.canVideosPlay = true;
      this.canPlayWebm = !!window.Modernizr.video.webm;
      this.canDisplayHoverAfterClick = true;
      // this.touchStarted = false; /* PAS BESOIN DANS LE CONTRUCTOR */
      // this.isTouchShort = false; /* PAS BESOIN DANS LE CONTRUCTOR */
      // this.isTouchClick = true;
      // this.isTouchEndOnTitle = false; /* PAS BESOIN DANS LE CONTRUCTOR */
      // this.isTouchEndOnButton = false; /* PAS BESOIN DANS LE CONTRUCTOR */
      // this.isMouseDown = false; /* PAS BESOIN DANS LE CONTRUCTOR */
      // this.isHover = false; /* PAS BESOIN DANS LE CONTRUCTOR */
      // this.isHoverTitles = false; /* PAS BESOIN DANS LE CONTRUCTOR */
      // this.isHoverTitle = false; /* PAS BESOIN DANS LE CONTRUCTOR */
      this.canHideTitles = true;
      
      // this.isFullscreen = false; /* PAS BESOIN DANS LE CONTRUCTOR */
      // this.isTouchScreen = false; /* PAS BESOIN DANS LE CONTRUCTOR */
      this.isFirstLoad = true;
      // this.fullscreenElement = null; /* PAS BESOIN DANS LE CONTRUCTOR */
      // this.selectedMedia = null; /* PAS BESOIN DANS LE CONTRUCTOR */
   
      this.pagesBySlug = {};
      this.medias = {};
      this.mediasByRedditId = {};
      this.prevOpenedMedias = null;
   
      this.initTopBar();
      
      this.touchStartPosX = 0;
      // this.updateScrollTimeout = null; /* PAS BESOIN DANS LE CONTRUCTOR */
      // this.bodyMouseMoveTimeout = null; /* PAS BESOIN DANS LE CONTRUCTOR */
      // this.titlesMouseMoveTimeout = null; /* PAS BESOIN DANS LE CONTRUCTOR */
   
      // this.appRef = React.createRef();
      this.topBarRef = React.createRef();
      this.topBarContainerRef = React.createRef();
      this.topBarInnerRef = React.createRef();
      this.disclaimerRef = React.createRef();
      this.disclaimerContainerRef = React.createRef();
      this.galleryRef = React.createRef();
      this.loadingFooterRef = React.createRef();
      this.loadingFooterButtonRef = React.createRef();
      this.loadingFooterEmojisContainerRef = React.createRef();
      this.warningNSFWRef = React.createRef();
   
      this.forceStateTimeouts = {};
      
      this.updateTimeoutDelays();
      this.initFooterEmojis();
   
      this.addFullscreenEvents();
      this.addEventHandlers();
      
      this.setRedditParamsByRouterProps(this.props);
   }
   
   /*addRGPDHandler() {
      console.log('addRGPDHandler');
      this.RGPDConsents = {};
      
      // if (!window.__cmp) {
      if (!window.__tcfapi) {
         try {
            // document.getElementById('cmp-script').addEventListener('load', this.addRGPDHandler);
            // document.getElementById('tcf-script').addEventListener('load', this.addRGPDHandler);
         } catch (e) {}
      }
      else {
         this.getPublisherConsents();
         // window.__cmp('setConsentUiCallback', this.getPublisherConsents);
      }
   }*/
   
   /*getPublisherConsents() {
      window.__cmp('getPublisherConsents', [1,2,3,4,5], ({standardPurposeConsents}) => {
         console.log('getPublisherConsents : ',standardPurposeConsents);
         if (!standardPurposeConsents)
            return;
         
         this.RGPDConsents = standardPurposeConsents;

         if (this.RGPDConsents[RGPD_PURPOSES.personalisation]) {
            Cookies.set('mt', this.selectedMediaType, { expires: 7 });
            Cookies.set('ac', this.allowNSFW, { expires: 7 });
            Cookies.set('ap', this.allowAutoPlay, { expires: 7 });
            Cookies.set('dok', this.isDisclaimerHidden, { expires: 365 });
            // Cookies.set('dt', this.displayTitles, { expires: 7 });
         }
         else {
            Cookies.remove('mt');
            Cookies.remove('ac');
            Cookies.remove('ap');
            Cookies.remove('dok');
            // Cookies.remove('dt');
         }
         
         // if (this.RGPDConsents[RGPD_PURPOSES.ad_selection]) {
            
            window.__cmp('getGooglePersonalization', ({googlePersonalizationData}) => {
               this.RGPDConsents[RGPD_PURPOSES.google] = googlePersonalizationData.consentValue;
               
               // if (!this.isGAInitialised && this.RGPDConsents[RGPD_PURPOSES.google]) {
               if (!this.isGAInitialised) {
                  this.isGAInitialised = true;
                  
                  if (this.RGPDConsents[RGPD_PURPOSES.google])
                     ReactGA.initialize('UA-163481328-1');
                  else {
                     // debugger;
                     ReactGA.initialize('UA-163481328-1', {
                        gaOptions: {
                           storage: 'none',
                           storeGac: false,
                           // anonymizeIp: true,
                        },
                     });
                  }
                  this.gaAddCurrentPageView();
               }
            });
         // }
      })
   }*/
   
   removeOldCookies() {
      for (let cookie of OLD_COOKIES)
         Cookies.remove(cookie);
   }
   
   getParamCookie(userParam) {
      return Cookies.get(USER_PARAMS.PREFIX + userParam.alias);
   }
   
   setParamCookie(userParam, value) {
      if (typeof value === 'undefined')
         return;
      
      console.log('setParamCookie '+userParam.alias+' : ',value);
      console.log('userParam : ',userParam);
      console.log('this.RGPDConsents : ',this.RGPDConsents);
      if (this.RGPDConsents[userParam.consent]) {
         console.log('ok ');
         Cookies.set(
            USER_PARAMS.PREFIX + userParam.alias,
            value,
            {expires: userParam.cookie_delay}
         );
      }
   }
   
   removeParamCookie(userParam) {
      Cookies.remove(USER_PARAMS.PREFIX + userParam.alias);
   }
   
   addRGPDHandler() {
      console.log('addRGPDHandler');
      this.RGPDConsents = {};
      
      window.__tcfapi('addEventListener', 2, (tcData, success) => {
         console.log('addRGPDHandler event, tcData : ',tcData);
         // if (tcData.listenerId === 0)
            this.getPublisherConsents(tcData, success);
      });
      
   }
   
   displayConsentUi() {
      console.log('test open tcf ui');
      window.__tcfapi('displayConsentUi',2, function() {});
   }
   
   getPublisherConsents(tcData, success) {
      
      console.log('getPublisherConsents, tcData : ',tcData);

      // window.__tcfapi('removeEventListener', 2, function() {}, tcData.listenerId);
      if (success && tcData.gdprApplies) {
         
         if (tcData.eventStatus === 'tcloaded' || tcData.eventStatus === 'useractioncomplete') { // ui just closed or choices made before
            
            let gaOptions = {
               storage: 'none',
               storeGac: false,
               // anonymizeIp: true,
            };
            
            this.RGPDConsents[RGPD_CONSENTS.content] = this.RGPDConsents[RGPD_CONSENTS.stats] = false;
            
            if (tcData.publisher.consents[1]) { // consent : store id
   
               if (tcData.publisher.legitimateInterests[5] && tcData.publisher.legitimateInterests[6]) { // consent : personalised content
      
                  this.RGPDConsents[RGPD_CONSENTS.content] = true;
                  
                  this.setParamCookie(USER_PARAMS.MEDIA_TYPE, this.selectedMediaType);
                  this.setParamCookie(USER_PARAMS.ALLOW_NSFW, this.allowNSFW);
                  this.setParamCookie(USER_PARAMS.ALLOW_AUTO_PLAY, this.allowAutoPlay);
                  this.setParamCookie(USER_PARAMS.IS_DISCLAIMER_HIDDEN, this.isDisclaimerHidden);
                  // this.setCookie(USER_PARAMS.DISPLAY_TITLE, this.selectedMediaType);
               }
               
               if (  tcData.publisher.legitimateInterests[8]  // consent : content performance
                  // && tcData.specialFeatureOptins[1] // consent : geolocation - probleme : n'est pas dans tcData.publisher - contradictoire
               ) {
                  this.RGPDConsents[RGPD_CONSENTS.stats] = true;
                  gaOptions = {};
               }
            }
   
            /*if (tcData.purpose.consents[1] || tcData.publisher.consents[1]) { // consent : store id (autre vendo que le publisher)
            }*/
            
            if (!this.RGPDConsents[RGPD_CONSENTS.content]) {
               this.removeParamCookie(USER_PARAMS.MEDIA_TYPE);
               this.removeParamCookie(USER_PARAMS.ALLOW_NSFW);
               this.removeParamCookie(USER_PARAMS.ALLOW_AUTO_PLAY);
               this.removeParamCookie(USER_PARAMS.IS_DISCLAIMER_HIDDEN);
               // this.removeCookie(USER_PARAMS.DISPLAY_TITLE);
            }
            
            if (!this.RGPDConsents[RGPD_CONSENTS.stats]) {
               // Remove the default tracker.
               // window['ga-disable-UA-163481328-1'] = true
               // if (window.ga) window.ga('remove');
               // Remove the default cookies
               // _ga is used to distinguish users.
               Cookies.remove('_ga', { path: '/', domain: document.domain });
               // _gid is used to distinguish users.
               Cookies.remove('_gid', { path: '/', domain: document.domain });
               // _gat is used to throttle request rate.
               Cookies.remove('_gat', { path: '/', domain: document.domain });
            }
            
            if (!this.isGAInitialised) {
               this.isGAInitialised = true;
               ReactGA.initialize('UA-163481328-1', {
                  gaOptions,
               });
               this.gaAddCurrentPageView();
            }
            
            console.log('this.RGPDConsents : ',this.RGPDConsents)
            
            /*
            // Example
            // we have consent, get the tcData string and do the ad request
            if (tcData.vendor.consents[755] && tcData.purpose.consents[1]) {
               console.log("Vendor ID 755 has consent for purpose ID 1");
            }
            if (!tcData.vendor.consents[755] || !tcData.purpose.consents[2]) {
               console.log("Vendor ID 755 has no consent for purpose ID 2");
            }
            if (tcData.vendor.legitimateInterests[755] && tcData.purpose.legitimateInterests[9]) {
               console.log("User has been informed of vendor ID 755's legitimate interest for purpose ID 9 and hasn't objected to it");
            }
            if (!tcData.vendor.legitimateInterests[755] || !tcData.purpose.legitimateInterests[10]) {
               console.log("User has objected to vendor ID 755's legitimate interest for purpose ID 10");
            }*/
         }
      }
   }
   
   gaAddCurrentPageView(uri) {
      if (!uri)
         uri = window.location.pathname + window.location.search;
      
      console.log('gaAddCurrentPageView : ',uri);
      // if (this.RGPDConsents[RGPD_PURPOSES.google]) {
         ReactGA.pageview(uri);
      // }
   }
   
   gaAddEvent(category, action, label, value = null) {
      console.log('gaAddEvent : ');
      // if (this.RGPDConsents[RGPD_PURPOSES.google]) {
         ReactGA.event({
            category,
            action,
            label,
            value: value !== null ? value * 1 : undefined,
         });
      // }
   }
   
   /*addDebugClass(component, classes) {
   
   }
   
   removeDebugClass(component, classes) {
   
   }*/
   
   
   /*forceStateRow(row, cb) {
      // console.log('forceStateRow ', row);
      if (this.rows[row]) {
         this.rows[row].updated++;
         this.forceState('row'+row, this.rows[row].ref, cb);
      }
   }*/
   
   forceStateMedia(media, cb) {
      // console.log('forceStateMedia '+media.id+' : ', media);
      media.updated++;
      if (this.rows[media.pos.y])
         this.forceState(media.id, this.rows[media.pos.y].ref, cb);
   }
   
   forceState(id, component, cb = null, tryLeft = 3) {
      // console.log('forceState '+id+' : ', component);
      // debugger;
      clearTimeout(this.forceStateTimeouts[id]);
      tryLeft--;
      
      try {
         component.current.forceUpdate(cb);
      }
      catch(e){
         if (tryLeft)
            this.forceStateTimeouts[id] = setTimeout(()=>{
               this.forceState(id, component, cb, tryLeft);
            }, 800);
      }
   }
   
   updateState(id, component, state, cb = null, tryLeft = 3) {
      console.log('updateState '+id+' : ', component);
      // debugger;
      clearTimeout(this.forceStateTimeouts[id]);
      tryLeft--;
      
      try {
         component.current.setState(state, cb);
      }
      catch(e){
         if (tryLeft)
            this.forceStateTimeouts[id] = setTimeout(()=>{
               this.updateState(id, component, state, cb, tryLeft);
            }, 800);
      }
   }
   
   isVideoInserted(media) {
      this.onVideoLoadingProgress(null, media, media[media.selectedSize].isHD, media.selectedSize, false, media.play && media.waiting);
   }
   
   /*setAllowTypes(selectedMediaType) {
      if (this.selectedMediaType !== selectedMediaType) {
         this.selectedMediaType = selectedMediaType;
   
         this.navigateToParams(this.selectedSubredditsText, this.selectedSort, this.selectedSortTime, selectedMediaType);
         // this.gaAddEvent('orderMedias', 'setAllowTypes', null, selectedMediaType);
   
         if (this.RGPDConsents[RGPD_PURPOSES.personalisation])
            Cookies.set('types', selectedMediaType, { expires: 7 });
         
         if (!this.allowAutoPlay)
            this.stopAllVideos();
         
         this.scrollToTop();
         this.orderMedias(false);
         // this.forceState('gallery', this.galleryRef);
      }
   }*/
   
   scrollToTop() {
      if (window.scrollY) {
         this.scrollTopBeforeNewSlug = true;
         window.scrollTo(0, 0);
      }
   }
   
   toggleAutoPlay() {
      console.log('toggleAutoPlay')
      console.log('this.RGPDConsents : ', this.RGPDConsents)
      
      this.allowAutoPlay = !this.allowAutoPlay;
   
      this.gaAddEvent('orderMedias', 'toggleAutoPlay', null, this.allowAutoPlay);
   
      this.setParamCookie(USER_PARAMS.ALLOW_AUTO_PLAY, this.allowAutoPlay);
      
      if (this.allowAutoPlay) {
         this.initVisibleRows();
         this.playVisibleVideos();
      }
      else
         this.stopAllVideos();
   }
   
   /*toggleNSFW(setTopBar = false) {
      this.setAllowNSFW(!this.allowNSFW, setTopBar);
   }*/
   
   setAllowNSFW(allowNSFW, setTopBar = false) {
      // if (this.allowNSFW !== allowNSFW) {
         this.allowNSFW = allowNSFW;
         this.allowNSFWChoosen = true;
         this.hideWarningNSFW();
         
         this.gaAddEvent('orderMedias', 'setAllowNSFW', null, allowNSFW);
         
         this.setParamCookie(USER_PARAMS.ALLOW_NSFW, allowNSFW);
         
         if (setTopBar) {
            try {
               this.topBarRef.current.setAllowNSFW(allowNSFW);
            } catch (e) {}
         }
         
         if (allowNSFW || !this.isNSFWSubreddit) {
            this.scrollToTop();
            this.orderMedias(false);
         }
         else
            this.navigateToParams();
      // }
   }
   
   displayWarningNSFW() {
      this.updateState('warningnsfw', this.warningNSFWRef, {display:true});
   }
   
   hideWarningNSFW() {
      this.updateState('warningnsfw', this.warningNSFWRef, {display:false});
   }
   
   addEventHandlers() {
      
      document.addEventListener('keydown', this.keyDownHandler.bind(this));
      // document.addEventListener('keypress', this.keyPressHandler.bind(this));
      document.addEventListener('visibilitychange', this.visibilityHandler.bind(this), {passive: true});
      
      window.addEventListener('resize', this.resizeHandler.bind(this));
      window.addEventListener('scroll', this.scrollHandler.bind(this), {passive: true});
   }
   
   addFullscreenEvents() {
      // console.log('addFullscreenEvents')
      // console.log('SET WINDOW RESIZE OK')
      document.addEventListener('webkitfullscreenchange', this.fullscreenHandler, {passive: true});
      document.addEventListener('mozfullscreenchange', this.fullscreenHandler, {passive: true});
      document.addEventListener('fullscreenchange', this.fullscreenHandler, {passive: true});
      document.addEventListener('MSFullscreenChange', this.fullscreenHandler, {passive: true});
   }
   
   visibilityHandler() {
      console.log('visibilityHandler this.isFullscreen : ',this.isFullscreen)
      if (document.hidden) {
         this.documentHidden = true;
         this.keepFullscreenOpen = false;
         
         if (this.isFullscreen)
            this.pauseVideo(this.selectedMedia);
         else
            this.pauseAllVideos();
      }
      else if (this.documentHidden) {
         this.documentHidden = false;
         
         if (this.isFullscreen) {
            this.keepFullscreenOpen = true;
            this.playVideo(this.selectedMedia);
         }
         else
            this.playVisibleVideos(true);
      }
   }
   
   resizeHandler() {
      clearTimeout(this.windowResizeTimeout);
      console.log('RESIZE HANDLER');
      
      if (this.isFullscreen)
         return;
      
      if (!this.isResizing) {
         this.isResizing = true;
      }
      else {
         console.log('RESIZE ADD BODY CLASS');
         document.body.classList.add('resize');
      }
      
      this.windowResizeTimeout = setTimeout(()=> {
         this.isResizing = false;
         if (!this.isFullscreen) {
            console.log('RESIZE TIMEOUT !');
            document.body.classList.remove('resize');
            this.updateNbMediasPerRow();
            this.updateTopBarMouseHoverHeight();
         }
      }, 200);
   }
   
   
   fullscreenHandler() {
      console.log('this.isTheGoodFullscreenChangeEvent : ', this.isTheGoodFullscreenChangeEvent);
      
      if (!this.allowFullScreen
         || !this.isTheGoodFullscreenChangeEvent) // POUR CONTOURNER LE FAIT QUE LORS DU PREMIER FullscreenChange EVENT (OUI, POURQUOI 2 EVTS ?) LE THIS EST UNE ANCIENNE COPIE DATANT DU ADD EVENT LISTENER
         return;
      
      // TODO : ADD ESCAPE KEY !
      console.log('FULLSCREEN HANDLER');
      console.log('this.isFullscreen : ', this.isFullscreen);
      console.log('keepFullscreenOpen : ', this.keepFullscreenOpen);
      console.log('this.selectedMedia : ', this.selectedMedia);
      // debugger;
      if (this.keepFullscreenOpen) {
         this.keepFullscreenOpen = false;
         return;
      }
      
      this.isFullscreen = (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement) != null;
      // console.log('this.isFullscreen apres : ', this.isFullscreen);
      
      if (this.isFullscreen) {
         this.openMedia(this.selectedMedia, true);
      }
      else if (!this.keepFullscreenOpen) // TODO : condition utile ?
         this.closeFullscreenMedia();
   }
   
   resetFullscreen(media) {
      this.selectedMedia = this.fullscreenElement = null;
      this.prevOpenedMedias = null;
      
      if (media)
         media.isFullscreen = false;
   }
   
   
   // FOR ARROWS ONLY
   keyDownHandler(e) {
      console.log('keyDownHandler : e.keyCode : ',e.keyCode);
      // debugger;
      /*if (!this.isFullscreen) {
         if (e.altKey) {
            switch (e.keyCode) {
               case 37 : // prev history
                  e.preventDefault();
                  break;
               case 39 : // next history
                  e.preventDefault();
                  break;
               default:
                  break;
            }
         }
      }
      else {*/
      if (this.isFullscreen) {
         switch (e.keyCode) {
            case 27 : //escape
               this.exitFullscreen();
               break;
            case 37 : // left
               this.navNextToMedia(true, -1);
               break;
            case 38 : // up
               // this.navNextToMedia(false, -1); /* NAVIGATION VERTICALE ANNULEE */
               /* A TRAITER */
               /* REGLER LE VOLUME */
               break;
            case 39 : // right
               this.navNextToMedia();
               break;
            case 40 : // bottom
               // this.navNextToMedia(false); /* NAVIGATION VERTICALE ANNULEE */
               /* A TRAITER */
               /* REGLER LE VOLUME */
               break;
            case 32 : // space
               if (!this.selectedMedia.isVideo)
                  this.toggleBodyTitles();
               else {
                  let mustPlay = false;
                  try {
                     mustPlay = this.selectedMedia.refs[this.selectedMedia.selectedSize].paused;
                  }
                  catch (e) {}
                  this.canHideTitles = mustPlay;
                  
                  if (mustPlay) {
                     this.hideBodyTitles();
                     this.hideVideoControls(this.selectedMedia);
                     this.playVideo(this.selectedMedia);
                  }
                  else {
                     this.displayBodyTitles();
                     this.displayVideoControls(this.selectedMedia);
                     this.pauseVideo(this.selectedMedia);
                  }
               }
               break;
            default:
               break;
         }
      }
   }
   
   updateTopMenuPos(direction) {
      console.log('new top bar pos DIRECTION : ',direction);
      // console.log('new top bar pos lastScrollDirection : ',this.lastScrollDirection);
      if (this.lastScrollDirection !== direction) {
         // console.log('new top bar pos CHANGEMENT DIRECTION');
         this.lastFixedScrollY = this.prevScrollTop;
         this.lastScrollDirection = direction;
         this.topBarFixedDirection = 0;
         // debugger;
      }
      
      /*else*/ if (this.topBarFixedDirection !== direction) {
   
         try {
            // this.setTopBarFixed();
   
            if (!this.topBarRef.current || (direction === 1 && !!this.topBarRef.current.displayedSearchQuery))
               return;
            
            this.topBarPos = Math.min(0, Math.max(-this.topBarInnerRef.current.clientHeight, this.lastFixedTopPos + (this.lastFixedScrollY - window.scrollY)));
            // console.log('new top bar pos this.lastFixedScrollY : ',this.lastFixedScrollY);
            // console.log('new top bar pos this.lastFixedTopPos : ',this.lastFixedTopPos);
            // console.log('new top bar pos window.scrollY : ',window.scrollY);
            // console.log('new top bar pos DELTA: ',this.lastFixedScrollY - window.scrollY);
            // console.log('new top bar pos : ',this.topBarPos);
            // console.log('-----');
      
            if (!this.topBarPos) {
               this.displayTopBar();
            }
            else if (this.topBarPos === -this.topBarInnerRef.current.clientHeight) {
               this.hideTopBar();
            }
            else {
               this.topBarInnerRef.current.style.marginTop = Math.round(this.topBarPos) + 'px';
            }
      
         } catch (e) {
            console.error(e)
         }
      }
   }
   
   initTopBar() {
      console.log('initTopBar');
   
      this.isTopBarHover = true;
      this.updateTopBarMouseHoverHeight();
      
      this.topBarFixedDirection = this.lastFixedTopPos = this.lastFixedScrollY = this.prevScrollTop = 0;
      this.prevMouseY = 0;
      this.lastScrollDirection = 1;
   }
   
   onMouseMoveTopBar() {
      this.isTopBarHover = true;
      
      this._displayTopBar();
   }
   
   onMouseLeaveTopBar() {
      this.isTopBarHover = false;
      
      this._displayTopBar();
   }
   
   displayTopBar() {
      console.log('displayTopBar');
      this.lastScrollDirection = this.topBarFixedDirection = -1;
      this.lastFixedTopPos = this.topBarPos = 0;
      try {
         this.topBarInnerRef.current.style.marginTop = 0;
      } catch (e) {}
      
      this._displayTopBar();
   }
   
   _displayTopBar() {
      clearTimeout(this.topBarTimeout);
      
      this.topBarTimeout = setTimeout(() => {
         if (!this.isTopBarHover && window.scrollY)
            this.hideTopBar();
      }, 3000);
   }
   
   updateTopBarMouseHoverHeight() {
      this.topBarMouseHoverHeight = window.innerHeight / 3;
   }
   
   hideTopBar() {
      console.log('hideTopBar');
      try {
         // debugger;
         if (this.topBarRef.current.displayedSearchQuery)
            return;
   
         this.lastScrollDirection = this.topBarFixedDirection = 1;
         this.lastFixedTopPos = this.topBarPos = -this.topBarInnerRef.current.clientHeight;
         this.topBarInnerRef.current.style.marginTop = this.topBarPos + 'px';
      
         this.topBarRef.current.closeAllMenus();
         
      } catch (e) {console.error(e)}
   }
   
   setTopBarFixed() {
      console.log('setTopBarFixed');
      try {
         // if (typeof this.topBarPos === 'undefined') {
            this.topBarContainerRef.current.style.height = this.topBarContainerRef.current.clientHeight + 'px';
            this.topBarInnerRef.current.style.position = 'fixed';
         // }
         
         // this.setTopBarFixed = () => {};
      } catch (e) {
         console.error(e)
      }
   }
   
   scrollHandler() {
      // console.log('scrollHandler this.isLoadingNewPage : ',this.isLoadingNewPage);
      if (this.isFullscreen)
         return;
      
      if (!this.touchStarted) {
         // if (this.allowAutoPlay)
         //    this.displayBodyTitles();
         // else
            this.displayBodyHover();
      }
      
      clearTimeout(this.updateScrollTimeout);
      
      // this.scrollTop = document.body.scrollTop;
      
      // this.hideDisclaimer();
      
      // console.log('---------------');
      // console.log('window.scrollY  : ',window.scrollY);
      // console.log('document.body.clientHeight  : ',document.body.clientHeight);
      // console.log('window.innerHeight  : ',window.innerHeight);
      // console.log('---------------');
      // this.loadNextPageIfBottomScreen();
      
      // console.log('SCROLL TOP : ',window.scrollY);
      
      // if (!this.letBrowserAutoPlay) {
         
         let deltaY = window.scrollY - this.prevScrollTop;
         
         if (deltaY > 0)
            this.updateTopMenuPos(1);
         // this.scrollDirection = 1;
         else if (deltaY < 0)
            this.updateTopMenuPos(-1);
            // this.scrollDirection = -1;
         
         this.prevScrollTop = window.scrollY;
         
         deltaY = Math.abs(deltaY);
         // console.log('deltaY',deltaY);
         if (this.scrollTopBeforeNewSlug) {
            // this.playVisibleVideos(true); // scroll to top, juste apres setState avec les nouvelles videos deja visible ou hidden, THEORIQUEMENT INUTILE
            this.scrollTopBeforeNewSlug = false;
         }
         else {
            this.loadNextPageIfBottomScreen();
            
            if (deltaY < 500)
               this.playVisibleVideos(false, true);
   
            else {
               this.pauseVisibleVideos();
               this.timeoutUpdateScroll();
            }
         }
      // }
      // else
      //    this.timeoutUpdateScroll();
      
   }
   
   timeoutUpdateScroll() {
      this.updateScrollTimeout = setTimeout(()=>{
         this.scrollHandler();
      }, 300)
   }
   
   initRows() {
      this.initVisibleRows();
      this.rows = [];
      this.groups = [];
      // this.rowsByTopPos = {};
      this.rowsByTopPos = [];
   }
   
   initVisibleRows() {
      this.visibleRows = {first:0, last:0, length:0};
   }
   
   updateNbMediasPerRow() {
      const nbMediasPerRow = this.getNbMediasPerRow();
      console.log('updateNbMediasPerRow : ',nbMediasPerRow);
      if (this.nbMediasPerRow !== nbMediasPerRow) {
         this.toggleDesktopFunctions(nbMediasPerRow);
         
         this.documentWidthChanged = true;
         this.orderMedias(false, nbMediasPerRow);
         // this.setState({nbMediasPerRow});
      }
      else if (this.documentWidth !== document.body.clientWidth) {
         
         this.documentWidth = document.body.clientWidth;
         this.documentWidthChanged = true;
         
         this.forceState('gallery', this.galleryRef, ()=>{
            this.documentWidthChanged = false;
         });
      }
   }
   
   toggleDesktopFunctions(nbMediasPerRow) {
      if (/*!this.isMobile &&*/ nbMediasPerRow > 1) {
         if (!this.isMobile) {
            this.renderBackground = this._renderBackground;
            this.renderNavButtons = this._renderNavButtons;
            this.onMouseMove = this._onMouseMove;
         }
         else
            this.onMouseMove = this.renderBackground = this.renderNavButtons = () => null;
         
         this.renderRowBreak = this._renderRowBreak;
         this.getMediaStyle = this._getMediaStyle;
      }
      else {
         this.onMouseMove = this.renderRowBreak = this.renderBackground = this.renderNavButtons = () => null;
         this.getMediaStyle = this.getMediaMobileStyle;
      }
   }
   
   getNbMediasPerRow() {
      // console.log('Math.round(document.body.clientWidth / 700)  : ',Math.round(document.body.clientWidth / 700));
      return Math.max(1, Math.min(3, Math.ceil(document.body.clientWidth / 640)));
   }
   
   loadNextPage() {
      // return;
      // debugger;
      if (this.canLoadNextPage && !this.isLoadingPage && (this.nextPageAfter || this.lastPageIsItem)) {
         console.log('loadNextPage');
         console.log('this.nextPageAfter : ',this.nextPageAfter)
         if (!this.lastPageIsItem)
            this.gaAddEvent('orderMedias', 'loadNextPage', null, this.currentPageIndex+1);
         
         this.loadRedditJson(this.selectedSlug, this.selectedType, this.selectedSubredditsText, this.selectedSort, this.selectedSortTime, this.nextPageAfter);
      }
   }
   
   loadNextPageIfBottomScreen() {
      if (this.rows.length && window.scrollY > document.body.clientHeight - (window.innerHeight * 2) ) {
         console.log('LOAD NEXT PAGE IF BOTTOM SCREEN');
         console.log('window.scrollY :  ',window.scrollY)
         console.log('document.body.clientHeight:  ', document.body.clientHeight)
         console.log('window.innerHeight :  ',window.innerHeight)
         this.loadNextPage();
      }
   }
   
   startLoadingNextPageTimer() {
      console.log('startLoadingNextPageTimer')
      
      clearTimeout(this.loadNextPageTimeout);
      this.loadNextPageTimeout = setTimeout(() => {
         this.canLoadNextPage = true;
         this.loadNextPageIfBottomScreen();
      }, 1000);
   }
   
   componentWillReceiveProps(nextProps, nextContext) {
      console.log('componentWillReceiveProps')
      this.isLoadingPage = true;
      this.isLoadingNewPage = true;
      this.loadErrors = 0;
      
      if (this.isFullscreen || this.selectedMedia)
         this.exitFullscreen();
      
      this.gaAddCurrentPageView();
      this.setRedditParamsByRouterProps(nextProps);
   }
   
   shouldComponentUpdate(nextProps, nextState, nextContext) {
      return this.isFirstLoad || !this.isLoadingPage;
   }
   
   setRedditParamsByRouterProps(props = {}) {
      console.log('setRedditParamsByRouterProps');
      console.log('URL PARAMS : ', props);
      let {type, pages, item, sort} = props.match.params; // props.match && props.match.params ? props.match.params : {};
      let sortTime, mediaType, displayTitles;
      // debugger;
   
      if (props.match.url === '/')
         this.isHomePage = true;
      
      else {
   
         if (type === 'user')
            type = 'u';

         else if (this.typeValues.indexOf(type) === -1)
            type = this.defaultType;
         
         if (pages) {
            pages = pages.split('+');
            pages = pages.filter(page => page.match(this.pagesReg) !== null);
         }
   
         if (item && item.match(this.itemReg) === null)
            item = null;
      }
      
      // const urlParams = (props.location && props.location.search !== '') ? props.location.search : null;
      if (props.location.search) {
         let results;
         // debugger;
         if ((results = props.location.search.match(this.sortReg)) !== null)
            sort = results[1];
   
         if ((results = props.location.search.match(this.sortTimeReg)) !== null)
            sortTime = results[1];
   
         if ((results = props.location.search.match(this.mediaTypeReg)) !== null)
            mediaType = results[1] * 1;
   
         if ((results = props.location.search.match(this.displayTitlesReg)) !== null)
            displayTitles = results[1] * 1;
      }
      // debugger;
      this.setRedditParams(type, pages, item, sort, sortTime, mediaType, displayTitles);
   }
   
   navigateToSubreddit(subredditsText, sort, time, mediaType, displayTitles) {
      console.log('navigateToSubreddit');
      this.navigateToUrl( this.getSubredditUrl(subredditsText, sort, time, mediaType, displayTitles) );
   }
   
   navigateToParams(page, sort, time, mediaType, displayTitles) {
      console.log('navigateToParams');
      this.navigateToUrl( this.getUrl(page, sort, time, mediaType, displayTitles) );
   }
   
   navigateToUrl(url) {
      console.log('navigateToUrl : ',url);
      
      this.props.history.push(url);
   }
   
   getDefaultMediaTypeUrl() {
      console.log('getDefaultMediaTypeUrl : ');
      return this.getUrl('/' + this.selectedType + '/' +this.selectedSubredditsText, this.selectedSort, this.selectedSortTime, this.defaultMediaType, this.displayTitles);
   }
   
   getPageUrl(page) {
      console.log('getPageUrl : ',page);
      // debugger;
      return this.getUrl(page, this.selectedSort, this.selectedSortTime, this.selectedMediaType, this.displayTitles);
   }
   
   getSubredditUrl(subredditsText, sort, time, mediaType, displayTitles) {
      return this.getUrl(subredditsText ? '/r/'+subredditsText : '', sort, time, mediaType, displayTitles);
   }
   
   getUrl(page = '', sort, time, mediaType, displayTitles) {
      
      if (typeof mediaType === 'undefined')
         mediaType = this.selectedMediaType;
      
      if (typeof displayTitles === 'undefined')
         displayTitles = this.displayTitles;
      
      let url = page + '/', search = [];
      
      if (sort && sort !== 'hot') {
         url += sort + '/';
   
         if (time && time !== 'day')
            search.push('t=' + time);
      }
      
      if (mediaType !== 1)
         search.push('mt='+mediaType);
      
      if (displayTitles !== 1)
         search.push('dt='+displayTitles);
      
      if (search.length)
         url += '?' + search.join('&');
      
      return url;
   }
   
   setRedditParams(type, pages, item, sort, sortTime, mediaType, displayTitles) {
      console.log('setRedditParams');
      
      if (!type)
         type = this.defaultType;
      
      // debugger;
      /* URL PARAMS */
      if (!pages || !pages.length)
         pages = this.defaultSubreddits;
      
      const subredditsText = pages.join('+');
      
      if (!sort)
         sort = this.defaultSort;
      
      if (!sortTime)
         sortTime = this.defaultSortTime;
      
      
      /* URL & COOKIE PARAMS */
      if (this.isFirstLoad) {
         if (typeof mediaType === 'undefined')
            mediaType = this.selectedMediaType;
   
         if (typeof displayTitles === 'undefined')
            displayTitles = this.displayTitles;
      }
      else {
         if (typeof mediaType === 'undefined')
            mediaType = this.defaultMediaType;
   
         if (typeof displayTitles === 'undefined')
            displayTitles = this.defaultDisplayTitles;
      }
      
      // debugger;
      
      if (  this.selectedType !== type
         || this.selectedSubredditsText !== subredditsText
         || this.selectedSort !== sort
         || this.selectedSortTime !== sortTime
         || this.selectedItem !== item
         || this.selectedMediaType !== mediaType
         || this.displayTitles !== displayTitles
      ) {
         this.lastPageIsItem = !!item;
         
         if (this.lastPageIsItem) {
            this.lastItemId = item;
            this.nextPageAfter = 'item';
         }
         else {
            this.nextPageAfter = this.lastItemId = null;
         }
         
         if (this.selectedMediaType !== mediaType)
            this.setParamCookie(USER_PARAMS.MEDIA_TYPE, this.selectedMediaType);
         
         if (this.displayTitles !== displayTitles/* && this.RGPDConsents[RGPD_PURPOSES.personalisation]*/) {
            // this.setCookie(USER_PARAMS.MEDIA_TYPE, this.selectedMediaType);
            this.displayTitles = displayTitles;
            this.setDisplayTitles(displayTitles);
            this.updateTimeoutDelays();
         }
         
         // if (this.displayTitles !== displayTitles && this.RGPDConsents[RGPD_PURPOSES.personalisation])
         //    Cookies.set('dt', displayTitles, { expires: 7 });
         
         this.selectedType = type;
         this.selectedSubreddits = pages;
         this.selectedSubredditsText = subredditsText;
         this.selectedSort = sort;
         this.selectedSortTime = sortTime;
         this.selectedItem = item;
         this.selectedMediaType = mediaType;
         
         this.selectedSlug = type+'_'+subredditsText+'_'+sort;
         if (sort === 'top')
            this.selectedSlug += '_'+sortTime;
         
         this.updateTitle();
         
         /*if (type === 'r')
            this.updateState('topbar', this.topBarRef, {selectedReddits:pages});
         else
            this.updateState('topbar', this.topBarRef, {selectedReddits:null});*/
         
         if (this.topBarRef.current /*&& !this.isFirstLoad*/)
            this.topBarRef.current.updateParams(this, true);
      }
      
      this.loadRedditJson(this.selectedSlug, this.selectedType, this.selectedSubredditsText, this.selectedSort, this.selectedSortTime, this.nextPageAfter, this.selectedItem);
   }
   
   loadRedditJson(slug, type, subredditsText, sort, sortTime, after = null, itemId = null) {
      // RedditAPI.hot('boobbounce').limit(30).fetch(
      // RedditAPI.top('boobbounce').t('year').limit(30).fetch(
      // RedditAPI.top('boobbounce+NSFW_GIFS').t('year').limit(30).fetch(
      // RedditAPI.top('boobbounce+NSFW_GIFS').t('year').fetch(
      // RedditAPI.top('titsagainstglass').t('year').limit(30).fetch(
      // RedditAPI.hot('titsagainstglass').limit(30).fetch(
      // RedditAPI.hot('BustyPetite').limit(30).fetch(
      // RedditAPI.top('BustyPetite').t('year').limit(30).fetch(
      // RedditAPI.top('gifs').t('all').limit(1).fetch(
   
      this.isLoadingPage = true;
      this.displayLoadingFooterAnim();
      // debugger;
      console.log('loadNextPage slug : ',slug);
      console.log('this.pagesBySlug : ',this.pagesBySlug);
      
      if (this.pagesBySlug[slug]) {
         
         const IsItem = !!itemId;
         
         if (this.pagesBySlug[slug].pagesByAfter[after +''])
            return this.loadPageBySlug(slug, after, IsItem);
      }
      else {
         this.pagesBySlug[slug] = {
            pages: [],
            pagesByAfter: {},
         };
      }
      
      let redditQuery;
      
      if (itemId) {
         redditQuery = RedditAPI.comments(itemId, subredditsText);
      }
      else {
         // debugger;
         if (type === 'u')
            redditQuery = RedditAPI.user(subredditsText).sort(sort);
         else
            redditQuery = RedditAPI[sort](subredditsText);
         
         if (sort === 'top')
            redditQuery = redditQuery.t(sortTime);
         if (after)
            redditQuery = redditQuery.after(after);
         // redditQuery = redditQuery.limit(4);
      }
   
      this.canLoadNextPage = false;
      this.startLoadingNextPageTimer();
      
      // this.parseRedditJson(slug, ArtJson);
      // return;
      
      redditQuery.fetch(
         (res)=>{
            // this.lastRedditJson = res;
            if (res.error) {
               console.log('QUERY FETCH ERROR');
               console.log(res.error);
               this.displayNoResults();
               // this.loadRedditJsonAfterError(itemId);
            }
            
            else if (itemId) {
               this.parseRedditJson(slug, res, true);
               // this.loadNextPage();
            }
            else
               this.parseRedditJson(slug, res);
            
         },
         (err) => {
            console.error(err);
            this.displayNoResults();
            // this.loadRedditJsonAfterError(itemId);
         });
   }
   
   /*loadRedditJsonAfterError(isItem) {
      this.loadErrors++;
      
      if (this.loadErrors > 1)
         return;
      
      this.isLoadingPage = false;
      this.setRedditParams();
   }*/
   
   loadPageBySlug(slug, currentPageAfter = null, isItem = false) {
      // debugger;
      // this.nextPageAfter = this.pagesBySlug[slug].pages[ this.pagesBySlug[slug].pages.length -1 ].nextAfter;
      currentPageAfter += '';
      this.nextPageAfter = this.pagesBySlug[slug].pagesByAfter[currentPageAfter].nextAfter;
      this.currentPageIndex = this.pagesBySlug[slug].pagesByAfter[currentPageAfter].index;
      this.loadErrors = 0;
      
      this.displayLoadingFooterButton(this.nextPageAfter !== null);
      
      if (this.isLoadingNewPage) {
         this.orderMedias(false);
         
         this.currentPageIndex = this.pagesBySlug[slug].pages.length -1;
         this.nextPageAfter = this.pagesBySlug[slug].pages[ this.currentPageIndex ].nextAfter;
      }
      else
         this.orderMedias(true);
      
      this.lastPageIsItem = !this.currentPageIndex && isItem;
   }
   
   updateTitle() {
      
      if (this.isHomePage) {
         document.title = "Reddit.pics | Turn Reddit into a Television";
      }
      else {
         document.title =
            (this.selectedSort === 'hot' ? 'All' : uppercaseFirstLetter(this.selectedSort)) +
            (this.selectedMediaType === 2 ? ' Gifs' : this.selectedMediaType === 0 ? ' Images' : ' Posts') +
            (this.selectedSort === 'top' ?
                  ' of ' + (this.selectedSortTime === 'hour' ? 'this hour' : this.sortTimeTexts[this.selectedSortTime])
                  : ' '
            ) +
            ' from ' +
            (this.selectedType === 'u' ?
               'Reddit user ' + this.selectedSubredditsText
               :
               this.selectedSubreddits.join(', ') + (this.selectedType === 'r' ? ' subreddit' : ' category')
            ) +
            (this.selectedSubreddits.length > 1 ? 's' : '') + ' | Reddit.pics';
      }
   }
   
   parseRedditJson(slug, threadJson, isItem = false) {
      console.log('parseRedditThreadJson : ',threadJson);
      // let item.json, item.isVideoForSure, isHD, isMultiSize, item.isGfycatFilesOk /*, minImageResolution*/;
      // debugger
      if (isItem) {
         if (!threadJson[0])
            return;
         threadJson = threadJson[0];
      }
      
      // threadJson.data.dist === nb children !!!!!!!!!!!
      
      const page = {
         nextAfter: threadJson.data.after,
         medias: []
      };
      
      if (!!threadJson && !!threadJson.data && !!threadJson.data.dist) {
   
         const vars = {
            json: null,
            poster: null,
            isVideoForSure: null, /*isMultiSize:null,*/
            isGfycatFilesOk: null
         } /*, minImageResolution*/;
         const maxNbChildren = isItem ? 1 : threadJson.data.children.length;
   
         /*imgurJson = await getJSON('https://api.imgur.com/3/album/MsMJHMH?client_id=cb65642c0a7a7a8');
         console.log(imgurJson);*/
         // debugger;
         for (let i = 0; i < threadJson.data.children.length && i < maxNbChildren; i++) {
            // if (i !== 0 +2 -1)
            // if (i < 21)
            // if (i > 6 - 1)
            // if (i < 9 || i > 11)
            // if (i !== 1)
            //    continue;
            // debugger
            vars.json = threadJson.data.children[i].data;
      
            if (!isItem && vars.json.id === this.lastItemId)
               continue;
      
            const media = this._parseRedditItemJson(vars);
            // debugger
            if (media) {
         
               if (isItem && media.hasMultipleSizes) {
                  this.setMediaSizeOnly(media, true);
               }
         
               page.medias.push(media);
               console.log('media : ', media);
         
               // if (media.isVideo && media.selectedSize === undefined) {
               //    debugger
               // }
         
               // if (!this.medias.hasOwnProperty(media.id)) {
               if (!this.medias[media.id]) {
                  this.medias[media.id] = media;
               }
            }
         }
      }
      
      page.index = this.pagesBySlug[slug].pages.length;
      this.pagesBySlug[slug].pages.push(page);
      this.pagesBySlug[slug].pagesByAfter[this.nextPageAfter +''] = page;
      
      console.log(this.pagesBySlug);
      // console.log('this.rowsByTopPos : ',this.rowsByTopPos);
      // console.log('rows', rows);
      this.loadPageBySlug(slug, this.nextPageAfter, isItem);
   }
   
   /*
   
   title
   redditUrl
   redditId
   subreddit
   waiting
   visible
   clicked
   play
   playing
   mustDisplayHD
   playStarted
   isVideo
   width
   height
   isHD
   thumb
   backgroundThumb
   id
   sourceType
   hasMultipleSizes
   onlySize
   selectedSize
   sd: {
      ok
      only
      inserted
      selected
      loaded
      files: [{…}]
   }
   hd: {
      ok
      only
      inserted
      selected
      loaded
      files: [{…}]
   }
   downloadUrl
   refs: {
      loadingSpinner
      hd
      mediaContainer
      item
   }
   pos: {
      x
      y
   }
   forceLoadTimeout
   loadForced
   updated
   
   */
   
   unmountMedia(media) {
      console.log('unmountMedia '+media.id);
      clearTimeout(media.forceLoadTimeout);
      
      media.waiting =
      media.visible =
      media.clicked =
      media.play =
      media.playing =
      media.playStarted =
      media.loadForced = false;
      
      media.pos = {};
      media.refs = {};
   }
   
   _parseRedditItemJson(vars) {
   
      console.log('item.json : ',vars.json);
      
      let media = this.mediasByRedditId[vars.json.id];
   
      if (!media)
      {
         media = {
            title: decodeHTMLEntities(vars.json.title),
            redditUrl: decodeHTMLEntities(vars.json.permalink),
            redditId: vars.json.id,
            subreddit: vars.json.subreddit,
            redditUser: vars.json.author,
            isNSFW: vars.json.over_18,
            waiting: false,
            // empty: false,
            visible: false,
            clicked: false,
            play: false,
            playing: false,
            mustDisplayHD: false,
            playStarted: false,
            updated: 0,
         };
      
         let mediaJson = {};
         vars.isVideoForSure = false;
         vars.isGfycat = false;
      
         // vars.isMultiSize = false;
      
         // if (!vars.json.hasOwnProperty('preview') || vars.json.preview === null)
         if (!vars.json.preview)
            return;
      
         // GFYCAT REMOVED BY COPYRIGHT
            // pas besoin de tester vars.json.removal_reason === 'legal' parce qu'il n'y a deja plus de .preview
         else if (REMOVED_PREVIEW_URLS.indexOf(vars.json.preview.images[0].source.url) !== -1) {
            console.log('GFYCAT REMOVED FOR COPYRIGHT : ',vars.json.title);
            return;
         }
         
         else if (vars.json.url.match(/\.(jpg|jpeg|png|bmp|webp)(\?[^?/]+)?$/i) !== null) {
            // if (this.selectedMediaType > 1)
            //    return;
         
            media.isVideo = false;
         
            if (vars.json.preview.images) {
               media.width = vars.json.preview.images[0].source.width;
               media.height = vars.json.preview.images[0].source.height;
            }
            else
               return;
         
         }
      
         else {
            media.isVideo = true;
         
            // if (vars.json.hasOwnProperty('media') && vars.json.media !== null)
            if (!!vars.json.media)
               mediaJson = vars.json.media;
         
            // else if (vars.json.hasOwnProperty('crosspost_parent_list') && vars.json.crosspost_parent_list.media)
            else if (!!vars.json.crosspost_parent_list && !!vars.json.crosspost_parent_list.media)
               mediaJson = vars.json.crosspost_parent_list.media;
         
            // if (vars.json.preview.hasOwnProperty('reddit_video_preview')) {
            if (!!vars.json.preview.reddit_video_preview) {
               // if (this.selectedMediaType < 1)
               //    return;
               
               vars.isVideoForSure = true;
               media.width = vars.json.preview.reddit_video_preview.width;
               media.height = vars.json.preview.reddit_video_preview.height;
            
               // vars.isMultiSize = this.getIsHdByRedditJsonVideo(vars.json);
            }
            // else if (vars.json.preview.images && vars.json.preview.images[0].variants && vars.json.preview.images[0].variants.hasOwnProperty('mp4')) {
            else if (!!vars.json.preview.images && !!vars.json.preview.images[0].variants && !!vars.json.preview.images[0].variants.mp4) {
               // if (this.selectedMediaType < 1)
               //    return;
   
               vars.isVideoForSure = true;
               media.width = vars.json.preview.images[0].variants.mp4.source.width;
               media.height = vars.json.preview.images[0].variants.mp4.source.height;
            
               // vars.isMultiSize = this.getIsHdByRedditJsonVideo(vars.json);
            }
            // else if (mediaJson.hasOwnProperty('oembed')) {
            else if (!!mediaJson.oembed) { /* EST CE QU'IL NE FAUDRAIT PAS PLUTOT FAIRE CONFIANCE AU PREVIEW IMAGE */
               media.width = mediaJson.oembed.width;
               media.height = mediaJson.oembed.height;
            
               // vars.isMultiSize = this.getIsHdByRedditJsonImage(vars.json);
            }
            else if (vars.json.preview.images) {
               media.width = vars.json.preview.images[0].source.width;
               media.height = vars.json.preview.images[0].source.height;
            
               // vars.isMultiSize = this.getIsHdByRedditJsonImage(vars.json);
            }
            else
               return;
         
         }
         
         //307200 : 640 x 480
         //230400 : 640 x 360
         // au dessus du 5ieme format de reddit :  960 x 540 : 518400
         media.isHD = /*item.isHD || */(media.width * media.height) >= 518400;
         
         let domain;
      
         // debugger;
      
         /*media.img = {
            only:!media.isVideo,
         };*/
         
         /*if (!vars.isMultiSize)
            vars.poster = decodeHTMLEntities(vars.json.preview.images[0].source.url);
         //// ca plutot que l'url de l'image direct car elle peut etre supprimée
         else
            vars.poster = decodeHTMLEntities(vars.json.preview.images[0].resolutions[ Math.min(vars.json.preview.images[0].resolutions.length-1, 3) ].url);*/
         
         vars.poster = this.getRedditPoster(vars.json);
         media.thumb = this.getRedditMinImg(vars.json);
         // media.posterJPG = 'http://localhost:3010/reddit/'+vars.poster+vars.json.thumbnail;
         media.backgroundThumb = vars.json.thumbnail;
         
         // ONLY REDDIT
         if (vars.json.is_reddit_media_domain || vars.json.url.match(REGEX.redgifs) !== null) { /*****  REDGIFS BUG SEPTEMBRE 2022 ********************/
         
            media.id = vars.json.id;
            media.sourceType = SOURCE_TYPES.reddit;
         
            // if (mediaJson.hasOwnProperty('reddit_video')) {
            if (!!mediaJson.reddit_video) {
               // if (this.selectedMediaType < 1)
               //    return;
               
               this.setMediaSizeOnly(media, media.isHD);
            
               media[media.selectedSize].files = [
                  {type: 'mp4', src: mediaJson.reddit_video.fallback_url},
               ];
            
               media.downloadUrl = mediaJson.reddit_video.fallback_url;
            }
            else  {
               this.initRedditMedia(media);
               
               if (media.isVideo) {
                  if (!this.addRedditVideoSources(vars.json, media))
                     return;
               }
               else
                  this.addRedditImgSources(vars.json, media);
            }
         }
      
         // ONLY GFYCAT
         else if (
               (domain = vars.json.url.match(REGEX.gfycat)) !== null && (vars.isGfycat = true)
            // || (domain = vars.json.url.match(REGEX.redgifs)) !== null    /********************  REDGIFS BUG SEPTEMBRE 2022 ********************/
         ) {
   
            // if (this.selectedMediaType < 1)
            //    return;
            
            // debugger;
            media.sd = {
               ok: true,
               only: false,
               inserted: true,
               selected: true,
               loaded: false,
               // errors: 0,
            };
         
            media.hd = {
               only: false,
               inserted: false,
               selected: false,
               loaded: false,
               // errors: 0,
            };
            // debugger;
            vars.isGfycatFilesOk = false;
   
            vars.domain = vars.isGfycat ? 'gfycat' : 'redgifs';
            // debugger;
            if (!domain[1] || domain[1] === 'www.') {
            
               // if (!mediaJson.hasOwnProperty('oembed')) {
               if (!mediaJson.oembed || !mediaJson.oembed.thumbnail_url) {
               
                  if (this.addRedditVideoSources(vars.json, media))
                     vars.isGfycatFilesOk = true;
                  else
                     return;
               }
               else {
                  domain = decodeURIComponent(decodeHTMLEntities(mediaJson.oembed.thumbnail_url)).match(REGEX[vars.domain]);
                  
                  if ( domain === null )
                     return;
               }
            }
            
            media.id = domain[3];
            media.sourceType = SOURCE_TYPES[vars.domain];
         
            
            if (!vars.isGfycatFilesOk) {
               media.selectedSize = 'sd';
               media.onlySize = '';
               media.hd.ok = true;
               media.hasMultipleSizes = true;
               
               vars.thumbPrefix = 'thumbs';
               vars.giantPrefix = 'giant';
               
               if (!vars.isGfycat)
                  vars.thumbPrefix = vars.giantPrefix = 'thumbs2';
               
               media.posterJPG = 'https://'+vars.thumbPrefix+'.'+vars.domain+'.com/' + media.id + '-mobile.jpg';
            
               /*if (!item.isHD)
                  media.sd.files = [
                     {type: 'mp4', src: 'https://thumbs.gfycat.com/' + media.id + '-mobile.mp4'},
                  ];

               else {*/
               media.sd.files = [
                  {type: 'mp4', src: 'https://'+vars.thumbPrefix+'.'+vars.domain+'.com/' + media.id + '-mobile.mp4'},
               ];
               media.hd.files = [
                  {type: 'mp4', src: 'https://'+vars.giantPrefix+'.'+vars.domain+'.com/' + media.id + '.mp4'},
               ];
               if (this.canPlayWebm)
                  media.hd.files.unshift({type: 'webm', src: 'https://'+vars.giantPrefix+'.'+vars.domain+'.com/' + media.id + '.webm'});
            
               media.downloadUrl = media.hd.files[0].src;
               // }
            }
         }
      
         // IMGUR AND GIPHY
         else {
         
            // if (media.isVideo)
            //    this.setMediaSizeOnly(media, media.isHD);
         
            // ONLY IMGUR
            if ((domain = vars.json.url.match(REGEX.imgur)) !== null) {
               // console.log('IMGUR DOMAIN : ',domain)
               // debugger;
            
               if (!domain[1]) {
               
                  if (!vars.isVideoForSure) {
                     // if (this.selectedMediaType > 1)
                     //    return;
                  
                     media.isVideo = false;
                     // media.img.only = true;
                  
                     media.width = vars.json.preview.images[0].source.width; /* CORRECTION DES DIMENSIONS FAUSSES D'OEMBED */
                     media.height = vars.json.preview.images[0].source.height;
                  }
               
                  if (!! domain[2]) {
                     /*isImgurJsonOk = true;
                     if (domain[2] === '/a') {
                        imgurJson = await getJSON('https://api.imgur.com/3/album/'+media.id+'?client_id=cb65642c0a7a7a8');
                     }
                     else if (domain[2] === '/gallery' || !isImgurJsonOk) {
                        imgurJson = await getJSON('https://api.imgur.com/3/gallery/'+media.id+'?client_id=cb65642c0a7a7a8');
                     }
                     
                     if (imgurJson.is_album)*/
                  
                     // if (!mediaJson.hasOwnProperty('oembed'))
                     if (!mediaJson.oembed)
                        return;
                     else if ((domain = decodeURI(mediaJson.oembed.thumbnail_url).match(REGEX.imgur)) === null)
                        return;
                  }
               }
            
               // console.log('IMGUR JSON : ',item.json);
            
               media.id = domain[3];
            
               media.sourceType = SOURCE_TYPES.imgur;
            
               if (media.isVideo) {
   
                  this.setMediaSizeOnly(media, media.isHD);
                  
                  media[media.selectedSize].files = [
                     {type: 'mp4', src: 'https://i.imgur.com/' + media.id + '.mp4'},
                  ];
               
                  const redditBigPreview = this.getRedditBigPreviewVideo(vars.json);
                  if (redditBigPreview)
                     media[media.selectedSize].files.push(
                        {type: 'mp4', src: redditBigPreview}
                     );
               
                  // media.posterJPG.push(media.posterJPG);
                  
                  media.downloadUrl = media[media.selectedSize].files[0].src;
               }
               else {
                  /*media.img.files.push(
                     // 'https://i.imgur.com/' + media.id + '.jpg',
                     decodeHTMLEntities(vars.json.preview.images[0].source.url), // vu qu'imgur a suppr tout le nsfw
                  );
                  media.downloadUrl = media.img.files[0];*/
   
                  this.initRedditMedia(media);
                  
                  this.addRedditImgSources(vars.json, media);
               }
            
            }
            
            // ONLY GIPHY
            else if ((domain = vars.json.url.match(REGEX.giphy)) !== null) {
               // if (this.selectedMediaType < 1)
               //    return;
   
               this.setMediaSizeOnly(media, media.isHD);
               
               media.id = domain[4];
            
               media.sourceType = SOURCE_TYPES.giphy;
            
               media[media.selectedSize].files = [
                  {type: 'mp4', src: 'https://media.giphy.com/media/' + media.id + '/giphy.mp4'},
               ];
            
               // media.img.files.push(media.posterJPG);
            
               media.downloadUrl = media[media.selectedSize].files[0].src;
            }
         
            else
               return;
         }
      
         // console.log('media.width : ',media.width);
         // console.log('media.height : ',media.height);
         
         media.refs = {
            // item: React.createRef(),
         };
      
         /*
         media.refs = {
            sd: React.createRef(),
            hd: React.createRef(),
         };
         media.refs.loadingLayerProgressMin = {
            sd: React.createRef(),
            hd: React.createRef(),
         };
      
         media.refs.component = React.createRef();
         // media.refs.fullscreenContainer = React.createRef();
         media.refs.mediaContainer = React.createRef();
         media.refs.loadingLayer = React.createRef();
         media.refs.loadingLayerProgress = React.createRef();
         media.refs.loadingSpinner = React.createRef();*/
         
         this.mediasByRedditId[vars.json.id] = media;
      }
      
      return media;
   }
   
   removeMediaRefs(media) {
      
      /*media.refs = {
            sd: null,
            hd: null,
         };
         media.refs.loadingLayerProgressMin = {
            sd: null,
            hd: null,
         };
         media.refs.component = null;
         // media.refs.fullscreenContainer = null;
         media.refs.mediaContainer = null;
         media.refs.loadingLayer = null;
         media.refs.loadingLayerProgress = null;
         media.refs.loadingSpinner = null;*/
   
   
      media.refs = {
         sd: null,
         hd: null,
         mediaContainer: null,
         loadingSpinner: null,
      };
   }
   
   setMediaSizeOnly(media, isHD) {
      let otherSize;
      
      media.hasMultipleSizes = false;
      
      if (isHD) {
         media.selectedSize = media.onlySize = 'hd';
         otherSize = 'sd';
      }
      else {
         media.selectedSize = media.onlySize = 'sd';
         otherSize = 'hd';
      }
   
      media[media.selectedSize] = {
         ...media[media.selectedSize],
         ok: true,
         only: true,
         inserted: true,
         selected: true,
         loaded: false,
         // errors: 0,
      };
      
      media[otherSize] = {
         ...media[otherSize],
         ok: false,
         only: false,
         inserted: false,
         selected: false,
         loaded: false,
         // errors: 0,
      };
   }
   
   /*getIsHdByRedditJsonVideo(itemJson) {
      return !! itemJson.preview.images[0].variants.mp4 && itemJson.preview.images[0].variants.mp4.resolutions.length > 3;
   }*/
   
   /*getIsHdByRedditJsonImage(itemJson) {
      return itemJson.preview.images[0].resolutions.length > this.minRedditImgResolution;
   }*/
   
   getRedditMinImg(itemJson) {
      if (itemJson.preview.images[0].resolutions.length)
         return decodeHTMLEntities(itemJson.preview.images[0].resolutions[0].url);
      else
         return decodeHTMLEntities(itemJson.preview.images[0].source.url);
   }
   
   getRedditPoster(itemJson) {
      if (itemJson.preview.images[0].resolutions.length >= this.minRedditPosterResolution)
         return decodeHTMLEntities(itemJson.preview.images[0].resolutions[ this.minRedditPosterResolution-1 ].url);
      else
         return decodeHTMLEntities(itemJson.preview.images[0].source.url);
   }
   
   getRedditBigPreviewVideo(itemJson) {
      // if (  itemJson.preview.hasOwnProperty('reddit_video_preview')
      //    && itemJson.preview.reddit_video_preview.hasOwnProperty('fallback_url'))
      if (!!itemJson.preview.reddit_video_preview && !!itemJson.preview.reddit_video_preview.fallback_url)
      {
         return itemJson.preview.reddit_video_preview.fallback_url;
      }
      
      return null;
   }
   
   initRedditMedia(media) {
      media.sd = {
         // ok: true,
         // only: vars.isMultiSize,
         // inserted: true,
         // selected: true,
         loaded: false,
         // errors: 0,
      };
   
      media.hd = {
         // ok: vars.isMultiSize,
         // only: false,
         inserted: false,
         selected: false,
         loaded: false,
         // errors: 0,
      };
   }
   
   addRedditVideoSources(itemJson, media) {
      // if (!itemJson.preview.images[0].variants.hasOwnProperty('mp4'))
      let mp4Json, biggestMp4 = this.getRedditBigPreviewVideo(itemJson);
      
      if (itemJson.preview.images[0].variants.mp4) {
         mp4Json = itemJson.preview.images[0].variants.mp4;
         // console.log('redditMp4Json : ', redditMp4Json)
         // console.log('Math.min(redditMp4Json.resolutions.length -2, 2) : ',Math.min(redditMp4Json.resolutions.length -2, 2))
   
         if (!biggestMp4)
            biggestMp4 = decodeHTMLEntities(mp4Json.source.url);
      }
      
      if (!mp4Json || mp4Json.resolutions.length <= this.minRedditVideoResolution) {
   
         this.setMediaSizeOnly(media, media.isHD);
         
         media[ media.onlySize ].files = [
            {type: 'mp4', src: biggestMp4},
         ];
      }
      else {
   
         media.hasMultipleSizes =
         media.hd.ok =
         media.sd.ok =
         media.sd.inserted =
         media.sd.selected = true;
         
         media.sd.only = false;
         
         media.onlySize = '';
         media.selectedSize = 'sd';
         
         media.sd.files = [
            {
               type: 'mp4',
               src: decodeHTMLEntities(mp4Json.resolutions[Math.min(mp4Json.resolutions.length - 1, this.minRedditVideoResolution -1)].url)
            },
         ];
         media.hd.files = [
            {type: 'mp4', src: biggestMp4},
         ];
      }
      
      media.downloadUrl = biggestMp4;
      
      return true;
   }
   
   addRedditImgSources(itemJson, media) {
      // debugger
      // console.log('itemJson : ',itemJson)
      
      media.downloadUrl = decodeHTMLEntities(itemJson.preview.images[0].source.url);
      
      const resolutions = itemJson.preview.images[0].resolutions;
      
      const biggestImgUrl = resolutions.length ?
         decodeHTMLEntities(resolutions[Math.min(resolutions.length - 1, this.maxRedditImgResolution -1)].url)
         :
         media.downloadUrl
      ;
      // debugger;
      if (  resolutions.length <= this.minRedditImgResolution
         && media.width * media.height <= this.minRedditPosterSurface)
      {
         this.setMediaSizeOnly(media, media.isHD);
         media[ media.onlySize ].img = media.downloadUrl;
      }
      else {
   
         media.hasMultipleSizes =
         media.hd.ok =
         media.sd.ok =
         media.sd.inserted =
         media.sd.selected = true;
         
         media.sd.only = false;
         
         media.onlySize = '';
         media.selectedSize = 'sd';
         
         media.sd.img = decodeHTMLEntities(resolutions[Math.min(resolutions.length - 1, this.minRedditImgResolution -1)].url);
         media.hd.img = biggestImgUrl;
      }
   }
   
   initOrderMedias() {
   
      this.initRows();
   
      this.displayTopBar();
   
      if (!this.isFirstLoad)
         this.hideDisclaimer();
   }
   
   orderMedias(onlyLastPage = true, nbMediasPerRow = null) {
      console.log('orderMedias onlyLastPage : '+onlyLastPage+' : ',nbMediasPerRow);
      let /*rows, */rowIndex, rowBottom, groupIndex, pageIndex;
      
      let items = [], rowTop, rowFormat = 0, rowNbVideos = 0, isItemRow, containNSFW = false, itemAdded = false, finishingLastRow = false;
      
      if (!nbMediasPerRow)
         nbMediasPerRow = this.nbMediasPerRow;
      
      // debugger;
      if (onlyLastPage && this.rows && this.rows.length && nbMediasPerRow === this.nbMediasPerRow) {
         // rows = [...this.rows];
         pageIndex = this.currentPageIndex;
         
         rowIndex = this.rows.length-1;
         groupIndex = this.groups.length-1;
         rowBottom = this.rowsByTopPos.length -1;//this.rowsByTopPos[ this.rowsByTopPos.length -1 ];
         
         if (this.rows[rowIndex].items.length === this.nbMediasPerRow || this.lastPageIsItem) {
            rowIndex++;
            if (this.groups[groupIndex].rows.length === this.nbRowsPerGroup) {
               groupIndex++;
            }
         }
         else {
            finishingLastRow = true;
            items = [...this.rows[rowIndex].items];
            rowNbVideos = items.length;
            
            // while (rowBottom && rowIndex === this.rowsByTopPos[rowBottom]) {
            while (rowBottom  &&
                  (  this.rowsByTopPos[rowBottom] === rowIndex
                  || (Array.isArray(this.rowsByTopPos[rowBottom]) && this.rowsByTopPos[rowBottom].indexOf(rowIndex) !== -1)
                  )
            ) {
               rowBottom--;
            }
            
            for (var i=0; i < rowNbVideos; i++)
               rowFormat += items[i].width / items[i].height;
         }
         
      }
      else {
         this.isNSFWSubreddit = true;
         rowIndex = 0;
         rowBottom = 0;
         pageIndex = 0;
         groupIndex = 0;
         this.nbMediasPerRow = nbMediasPerRow;
         
         this.initOrderMedias();
      }
      // debugger
      
      // const dateParam = '';
   
      if (this.pagesBySlug[this.selectedSlug] && this.pagesBySlug[this.selectedSlug].pages.length) {
   
         const addRow = () => {
            // debugger;
            rowTop = rowBottom;
            
            if (isItemRow)
               rowFormat = window.innerWidth / window.innerHeight;
            
            rowBottom += Math.round((1 / rowFormat) * 100);
            
            // Traitement de la dernière bande connue
   
            // if (rowTop in this.rowsByTopPos &&
            //    (Array.isArray(this.rowsByTopPos[rowTop]) ?
            //          this.rowsByTopPos[rowTop].indexOf(rowIndex) === -1
            //          : this.rowsByTopPos[rowTop] !== rowIndex
            //    )
            // ) {
            if (finishingLastRow) {
               rowTop++;
               this.rowsByTopPos.splice(rowTop);
               finishingLastRow = false;
            }
            else {
               if (rowTop in this.rowsByTopPos && this.rowsByTopPos[rowTop] !== rowIndex) // bande contenant 2 rows
                  this.rowsByTopPos[rowTop] = [this.rowsByTopPos[rowTop], rowIndex];
   
               else // bande contenant une seule row
                  this.rowsByTopPos[rowTop] = rowIndex;
               
               rowTop++;
            }


            // bandes intermédiaire entre le dernier rowBottom et le nouveau
            for (let i = rowTop; i <= rowBottom; i++)
               this.rowsByTopPos[i] = rowIndex;
            
            if (!this.rows[rowIndex]) {
               this.rows[rowIndex] = {
                  ref:React.createRef(),
                  visible: false,
                  play: false,
                  // updated:0,
                  items,
                  groupIndex: groupIndex,
               };
               
               if (!this.groups[groupIndex]) {
                  this.groups[groupIndex] = {
                     ref: React.createRef(),
                     visible: false,
                     nbVisibleRows: 0,
                     rows: [rowIndex],
                  };
               }
               else {
                  this.groups[groupIndex].rows.push(rowIndex);
                  
                  if (this.groups[groupIndex].rows.length === this.nbRowsPerGroup)
                     groupIndex++;
               }
            }
            else {
               // this.rows[rowIndex].updated++;
               this.rows[rowIndex].items = items;
            }
            
            this.rows[rowIndex].format = rowFormat;
            delete this.rows[rowIndex].height;
            
            rowIndex++;
         
            items = [];
            rowFormat = rowNbVideos = 0;
         };
      
         /*imgurJson = await getJSON('https://api.imgur.com/3/album/MsMJHMH?client_id=cb65642c0a7a7a8');
         console.log(imgurJson);*/
         // debugger;
         
         do {
            for (let i=0; i < this.pagesBySlug[this.selectedSlug].pages[pageIndex].medias.length; i++) {
               
               const media = this.pagesBySlug[this.selectedSlug].pages[pageIndex].medias[i];
               
               // L = 100
            
               // 16/9 + 16/9 + 21/9 = 53/9 = 100 / H
            
               // H = 100 * 9 / 53
            
               // H = 100 * (coeffs additionnés)
            
               // rows = [ 0:row1, 1:row2... ]
            
               // row1 = { top:0, bottom:1.5, items: ['key1', 'key2', 'key3'] }
               // row1 = { top:1.5, bottom:3, items: ['key4', 'key5', 'key6] }
            
               // rowsByTopPos [ 0:row1, 1:[row1, row2], 2:row2, 3:row3 ]
            
               // [
               //    0  H = 1.5
               //    1
               //    2  H = 1.5
               // ]
   
               this.isNSFWSubreddit = this.isNSFWSubreddit && media.isNSFW;
               containNSFW = containNSFW || media.isNSFW;
               
               if ( (!this.allowNSFW && media.isNSFW) || (media.isVideo ? this.selectedMediaType < 1 : this.selectedMediaType > 1) ) {
                  this.unmountMedia(media);
               }
               else {
                  itemAdded = true;
                  
                  media.play = false;
                  media.playing = false;
                  media.visible = false;
   
                  if (!media[media.selectedSize].loaded)
                     media.waiting = this.allowAutoPlay;
                  else
                     media.waiting = false;
   
                  media.pos = {
                     x: items.length,
                     y: rowIndex,
                  };
   
                  rowNbVideos++;
                  items.push(media);
                  rowFormat += media.width / media.height;
   
                  isItemRow = media.redditId === this.lastItemId;
                  if (rowNbVideos === this.nbMediasPerRow || isItemRow) {
                     addRow();
                  }
               }
            }
            
            pageIndex++;
         }
         while (pageIndex < this.pagesBySlug[this.selectedSlug].pages.length);
         
         if (itemAdded) {
            if (rowFormat)
               addRow();
            // debugger;
            rowBottom++;
            this.rowsByTopPos.splice(rowBottom);
         }
         
         this.isNSFWSubreddit = this.isNSFWSubreddit && containNSFW;
         
         if (!this.allowNSFWChoosen && !this.allowNSFW && this.isNSFWSubreddit) {
            this.displayWarningNSFW();
         }
         else {
            this.hideWarningNSFW();
         }
         
         const visibleRows = this.getVisibleRows(this.isLoadingNewPage);
         // debugger;
         if (this.isFirstLoad || this.isLoadingNewPage)
            this.visibleRows = visibleRows;
         
         let first = visibleRows.first - this.nbRowPreloadingBack;
         let last = visibleRows.last + this.nbRowPreloadingNext;
         
         // for (let row = visibleRows.first; row <= visibleRows.last; row++) {
         for (let group = 0; group < this.groups.length; group++) {
            
            this.groups[group].visible = false;
            this.groups[group].nbVisibleRows = 0;
            
            for (let i = 0, row = this.groups[group].rows[i]; i < this.groups[group].rows.length; i++, row = this.groups[group].rows[i]) {
               // this.rows[row].visible = true;
               // this.rows[row].play = this.allowAutoPlay;
               this.rows[row].visible = row >= first &&  row <= last;
               
               if (this.rows[row].visible) {
                  this.groups[group].visible = true;
                  this.groups[group].nbVisibleRows++;
               }
               
               this.rows[row].play = row >= visibleRows.first && row <= visibleRows.last;
               
               for (let media = 0; media < this.rows[row].items.length; media++) {
                  this.rows[row].items[media].visible = this.rows[row].play;
                  
                  if (this.rows[row].items[media].isVideo)
                     this.rows[row].items[media].play = this.rows[row].items[media].visible && this.allowAutoPlay;
               }
            }
         }
         
      }
      // debugger;
      this.isLoadingPage = false;
      // console.log(this.medias);
      console.log('this.rowsByTopPos : ',this.rowsByTopPos);
      console.log('this.groups : ', this.groups);
      console.log('this.rows : ', this.rows);
      // console.log('orderMedias setState');
   
      if (this.isLoadingNewPage) {
         this.scrollToTop();
      }
      
      // this.galleryRef.current.setState({rows:this.rows, rowsRefs: this.rowsRefs, visibleRows:this.visibleRows, nbMediasPerRow:this.nbMediasPerRow}, () => {
      this.forceState('gallery', this.galleryRef, ()=>{
   
         if (this.lastPageIsItem) {
            this.canLoadNextPage = true;
            this.loadNextPage();
         }
         else
            this.startLoadingNextPageTimer();
   
         
         if (!this.isFullscreen) {
            if ((this.isFirstLoad || this.isLoadingNewPage) && this.displayTitles)
               this.displayBodyHover();
            
            if (this.isLoadingNewPage) { // play ceux qui sont en haut et nouveaux
               // this.scrollTopBeforeNewSlug = true;
               // debugger;
               // if (window.scrollY)
               //    window.scrollTo(0, 0); // doit aussi trigger playVisibleVideos
               // else {
                  this.playVisibleVideos(true);
                  this.isLoadingNewPage = false;
               // }
            }
            // else
            //    this.playVisibleVideos();
         }
         
         this.hideLoadingFooterAnim();
         // if (this.isFirstLoad)
         //    setTimeout(()=>{
         //       this.loadNextPage();
         //    }, 20000);
         // this.canLoadNextPage = true; /* A TRAITER */
         this.documentWidthChanged = false;
         this.isFirstLoad = false;
      });
   }
   
   displayNoResults() {
      console.log('displayNoResults');
      this.initOrderMedias();
      // debugger;
      this.isLoadingPage = false;
      this.scrollToTop();
      
      // this.galleryRef.current.setState({rows:this.rows, rowsRefs: this.rowsRefs, visibleRows:this.visibleRows, nbMediasPerRow:this.nbMediasPerRow}, () => {
      this.forceState('gallery', this.galleryRef, ()=>{
         this.isLoadingNewPage = false;
         this.hideLoadingFooterAnim();
         this.documentWidthChanged = false;
         this.isFirstLoad = false;
      });
   }
   
   getVisibleRows(fromTop = false) {
      console.log('getVisibleRows '+(fromTop ? 'fromTop' : '')+' this.galleryTopOffset : ',this.galleryTopOffset);
      
      if (this.documentHidden || !this.rowsByTopPos)
         return {first:0, last:0, length:0};
      
      const scrollY = (fromTop ? 0 : window.scrollY) - this.galleryTopOffset;
      // console.log('scrollY : ',scrollY);
      
      const screenTop = Math.max(0, Math.floor(scrollY * (100 / document.body.clientWidth)) );
      const screenBottom = Math.max(0, Math.floor((scrollY + window.innerHeight) * (100 / document.body.clientWidth)) );
      
      let topPos = screenTop, ended = true, nbRows = 0, rowIndex;
      
      const visibleRows = {
         first: Array.isArray(this.rowsByTopPos[topPos]) ? this.rowsByTopPos[topPos][0] : this.rowsByTopPos[topPos]
      };
      
      visibleRows.last = visibleRows.first;
      
      // debugger;
      while (topPos <= screenBottom && nbRows <= this.maxVisibleRows && topPos in this.rowsByTopPos) {
         rowIndex = this.rowsByTopPos[topPos];
         ended = false;
         
         if (Array.isArray(rowIndex)) {
            for (let row of rowIndex) {
               visibleRows[row] = true;
               visibleRows.last = row;
            }
            ended = true;
            nbRows++;
         }
         else {
            visibleRows[rowIndex] = true;
            visibleRows.last = rowIndex;
         }
         
         topPos++;
      }
      
      if (!ended)
         nbRows++;
      
      visibleRows.length = nbRows;
      
      // console.log('this.rowsByTopPos : ', this.rowsByTopPos);
      console.log('visibleRows : ', visibleRows);
      
      return visibleRows;
   }
   
   playVisibleVideos(alreadySaved = false, loadNextPage = false) {
      console.log('playVisibleVideos alreadySaved : '+alreadySaved+' this.visibleRows : ',this.visibleRows);
      // if (this.isFullscreen)
      //    return;
      // debugger;
      // if (!this.canVideosPlay)
      //    this.setState({canVideosPlay:true});
      
      if (!alreadySaved || !this.visibleRows.length) {
         const visibleRows = this.getVisibleRows();
         
         // console.log('visibleRows : ',visibleRows);
         // console.log('this.visibleRows : ',this.visibleRows);
         console.log('this.rows: ',this.rows);
         // console.log('this.groups : ',this.groups);
         
         if (  !visibleRows.length
            || (visibleRows.first === this.visibleRows.first && visibleRows.length === this.visibleRows.length )
         )
            return;
         
         this.hidePrevVisibleVideos(this.visibleRows, visibleRows);
         this.pausePrevVisibleVideos(this.visibleRows, visibleRows);
         this.preloadNextVisibleVideos(this.visibleRows, visibleRows);
         // this.visibleRows = visibleRows;
         // return;
         let updated = true;
         
         for (let row = visibleRows.first; row <= visibleRows.last; row++)
            updated = updated && this.playRowVideos(row);
         
         if (updated) {
            this.visibleRows = visibleRows;
         }
         else {
            console.log('PLAY VISIBLES VIDEOS IMPOSSIBLE')
         }
         // console.log('playRowVideos of rows : ',visibleRows);
      }
      else {
         // debugger;
         for (let row = this.visibleRows.first; row <= this.visibleRows.last; row++)
            this.playRowVideos(row);
      }
      // console.log('this.visibleRows : ',this.visibleRows)
      if (loadNextPage && this.rows.length && /*this.visibleRows.length === 1 &&*/ this.visibleRows.last +1 >= this.rows.length-1) {
         // debugger;
         console.log('PLAY VISIBLE LOAD NEXT PAGE');
         this.loadNextPage();
      }
   }
   
   
   pauseAllVideos() {
      // console.log('pauseAllVideos');
      // debugger;
      for (let row=0; row < this.rows.length; row++) {
         this.pauseRowVideos(row);
      }
      this.initVisibleRows();
   }
   
   stopAllVideos() {
      // console.log('stopAllVideos');
      // debugger;
      for (let row=0; row < this.rows.length; row++) {
         this.stopRowVideos(row);
      }
   }
   
   pauseOtherVisibleVideos() {
      // console.log('pauseOtherVisibleVideos');
      // debugger;
      for (let row = this.visibleRows.first; row <= this.visibleRows.last; row++) {
         this.pauseRowOtherVideos(row);
      }
   }
   
   pauseVisibleVideos() {
      // console.log('pauseVisibleVideos');
      // debugger;
      for (let row = this.visibleRows.first; row <= this.visibleRows.last; row++) {
         this.pauseRowVideos(row);
      }
   }
   
   pausePrevVisibleVideos(prevVisibleRows, nextVisibleRows) {
      // console.log('pausePrevVisibleVideos ');
      // debugger;
      for (let row = prevVisibleRows.first; row <= prevVisibleRows.last; row++) {
         // if (!(row in nextVisibleRows))
         if (!nextVisibleRows[row])
            this.pauseRowVideos(row);
      }
   }
   
   /*stopPrevVisibleVideos(prevVisibleRows, nextVisibleRows) {
      console.log('stopPrevVisibleVideos ');
      // debugger;
      for (let row = prevVisibleRows.first; row <= prevVisibleRows.last; row++) {
         // if (!(row in nextVisibleRows))
         if (!nextVisibleRows[row])
            this.stopRowVideos(row);
      }
   }*/
   
   hidePrevVisibleVideos(prevVisibleRows, nextVisibleRows) {
      console.log('hidePrevVisibleVideos');
      let nextFirst = nextVisibleRows.first - this.nbRowPreloadingBack;
      let nextLast = nextVisibleRows.last + this.nbRowPreloadingNext;
      
      for (let row = prevVisibleRows.first - this.nbRowPreloadingBack; row <= prevVisibleRows.last + this.nbRowPreloadingNext; row++) {
         if (row < nextFirst || row > nextLast)
            this.hideRowVideos(row);
      }
   }
   
   preloadNextVisibleVideos(prevVisibleRows, nextVisibleRows) {
      console.log('preloadNextVisibleVideos');
      // debugger;
      let prevFirst = prevVisibleRows.first - this.nbRowPreloadingBack;
      let prevLast = prevVisibleRows.last + this.nbRowPreloadingNext;
      
      for (let row = nextVisibleRows.first - this.nbRowPreloadingBack; row <= nextVisibleRows.last + this.nbRowPreloadingNext; row++) {
         if (row < prevFirst || row > prevLast)
            this.setVisibleRow(row);
      }
   }
   
   preloadRowsAround(row) {
      console.log('preloadRowsAround');
      // debugger;
      if (!row) {
         this.setVisibleRow(this.rows[this.rows.length-1]);
      }
      else {
         this.setVisibleRow(row-1);
         
         if (row === this.rows.length-1)
            this.setVisibleRow(0);
         else
            this.setVisibleRow(row+1);
      }
   }
   
   
   /*hideVisibleVideos() {
      // console.log('hideVisibleVideos');
      // debugger;
      for (let row = this.visibleRows.first; row <= this.visibleRows.last; row++) {
         this.hideRowVideos(row);
      }
   }*/
   
   playRowVideos(row) {
      console.log('playRowVideos : ',row);
      // return;
      // debugger;
      
      if (!this.allowAutoPlay) {
         for (let media = 0; media < this.rows[row].items.length; media++) {
            
            if (this.rows[row].items[media].playStarted)
               this.playVideo(this.rows[row].items[media]);
            else
               this.preloadVideo(this.rows[row].items[media]); /* SUPPR APRES NOUVELLE ARCHITECTURE */
         }
      }
      else
         for (let media = 0; media < this.rows[row].items.length; media++) {
            this.playVideo(this.rows[row].items[media]);
         }
         
      return this.setVisibleRow(row, true); // on fait confiance a l'auto load
   }
   
   pauseRowVideos(row) {
      // console.log('pauseRowVideos : ',row);
      // debugger;
      this.rows[row].play = false;
      
      for (let media = 0; media < this.rows[row].items.length; media++) {
         this.pauseVideo(this.rows[row].items[media]);
      }
   }
   
   stopRowVideos(row) {
      // console.log('stopRowVideos : ',row);
      // debugger;
      this.rows[row].play = false;
      
      for (let media = 0; media < this.rows[row].items.length; media++) {
         this.stopVideo(this.rows[row].items[media]);
      }
   }
   
   pauseRowOtherVideos(row) {
      // console.log('pauseRowOtherVideos : ',row);
      // debugger;
      for (let media = 0; media < this.rows[row].items.length; media++) {
         if (this.rows[row].items[media] !== this.selectedMedia) {
            this.pauseVideo(this.rows[row].items[media]);
         }
      }
   }
   
   hideRowVideos(row) {
      console.log('hideRowVideos : ',row);
      // debugger;
      if (!!this.rows[row] && this.rows[row].visible && this.groups[ this.rows[row].groupIndex ].visible) {
         
         this.groups[ this.rows[row].groupIndex ].nbVisibleRows--;
         
         this.rows[row].visible = false;
         this.rows[row].play = false;
         this.rows[row].playing = false;
         
         if (this.groups[ this.rows[row].groupIndex ].nbVisibleRows) {
            for (let media = 0; media < this.rows[row].items.length; media++) {
               // if (this.rows[row].items[media].isVideo)
               this.setHidden(this.rows[row].items[media]);
            }
   
            // if (this.rows[row] && this.rows[row].ref.current) {
            // for (let media = 0; media < this.rows[row].items.length; media++) {
            //    if (this.rows[row].items[media].isVideo)
            //       this.setVisible(this.rows[row].items[media]);
            // }
            // this.rows[row].ref.current.forceUpdate();
            this.forceState('row'+row, this.rows[row].ref);
            // }
         }
         else {
            for (let media = 0; media < this.rows[row].items.length; media++) {
               this.rows[row].items[media].visible = false;
            }
            
            this.groups[ this.rows[row].groupIndex ].visible = false;
            this.forceState('group' + this.rows[row].groupIndex, this.groups[ this.rows[row].groupIndex ].ref);
         }
   
         // console.log('this.groups : ',this.groups);
      }
   }
   
   /*setVisibleRowVideos(row) {
      console.log('setVisibleRowVideos : ',row);
      // debugger;
      // if (!!this.rows[row]) {
         // for (let media = 0; media < this.rows[row].items.length; media++) {
         //    if (this.rows[row].items[media].isVideo)
         //       this.setVisible(this.rows[row].items[media]);
         // }
      if (this.rows[row] && !this.rows[row].visible) {
         this.rows[row].visible = true;
         
         for (let media = 0; media < this.rows[row].items.length; media++) {
            // if (this.rows[row].items[media].isVideo)
               this.setVisible(this.rows[row].items[media]);
         }
         
         if (this.rows[row] && this.rows[row].ref.current)
            this.rows[row].ref.current.setState({visible:true});
      }
   }*/
   
   setVisibleRow(row, play = false) {
      // debugger;
      // if (!!this.rows[row]) {
         // for (let media = 0; media < this.rows[row].items.length; media++) {
         //    if (this.rows[row].items[media].isVideo)
         //       this.setVisible(this.rows[row].items[media]);
         // }
      if (this.rows[row] && (!this.rows[row].visible || this.rows[row].play !== play)) {
      console.log('setVisibleRow '+row+' '+(this.rows[row].play ? ' PLAY':''));
      console.log('row already visible ? '+this.rows[row].visible);
      // console.log('this.groups : ',this.groups);
         
         /*for (let media = 0; media < this.rows[row].items.length; media++) {
            // if (this.rows[row].items[media].isVideo)
               this.preloadVideo(this.rows[row].items[media]);
         }*/
         
         // if (this.rows[row] && this.rows[row].ref.current) {
            if (!this.rows[row].visible) {
               this.groups[ this.rows[row].groupIndex ].nbVisibleRows++;
               this.rows[row].visible = true;
            }
            
            this.rows[row].play = play;
            
            if (this.groups[ this.rows[row].groupIndex ].visible) {
               // this.rows[row].ref.current.forceUpdate();
               this.forceState('row'+row, this.rows[row].ref);
            }
            else {
               this.groups[ this.rows[row].groupIndex ].visible = true;
               this.forceState('group'+this.rows[row].groupIndex, this.groups[ this.rows[row].groupIndex ].ref);
            }
            
         // }
         // else {
         //    console.log("CAN'T UPDATE ROW")
         //    return false
         // }
      }
      
      return true;
   }
   
   
   openMedia(media, clicked = false) {
      // debugger;
      // console.log('--------------------')
      console.log('openMedia this.isFullscreen',this.isFullscreen);
      console.log('open Media ',media.id);
   
      this.canHideTitles = true;
      
      if (this.allowAutoPlay || clicked) {
         media.clicked = true;
         
         try {
            media.refs.item.classList.add('clicked');
         }
         catch (e) {}
      }
      
      if (!this.isFullscreen) // quand l'utilisateur a mis allowAutoPlay à false
         this.playVideo(media);
      
      else {
         if (this.isTouchScreen && this.isHover)
            this.displayBodyHover(); // pour relancer le timeout after touch start
         else
            this.displayBodyTitles();

         if (this.allowFullscreenVolume)
            this.muteVideo(media, false);
         
         if (this.prevOpenedMedias)
            // this.playVideo(media, this.prevOpenedMedias.hasOwnProperty(media.id) || media.clicked);
            this.playVideo(media, this.prevOpenedMedias[media.id] || media.clicked);
         
         else {
            this.prevOpenedMedias = {};
            this.playVideo(media);
            // this.pauseOtherVisibleVideos(); // deja dans go fullscreen
         }
         
         this.prevOpenedMedias[media.id] = true;
         this.selectedMedia = media;
         
         media.isFullscreen = true;
         media.mustDisplayHD = true;
         
         if (media.isVideo)
            this.loadHDVideo(media);
         else
            this.loadHDImg(media);
         
         try {
            document.body.classList.add('fullscreen');
            media.refs.item.classList.add('fullscreen');
         }
         catch (e) {}
         // this.displayVideoControls(media);
      }
   }
   
   closeMedia(media) {
      // debugger;
      // console.log('--------------------')
      console.log('closeMedia ',media.id);
      
      this.canHideTitles = true;
      
      media.isFullscreen = false;
      
      this.muteVideo(media);
      
      try {
         document.body.classList.remove('fullscreen');
         media.refs.item.classList.remove('fullscreen');
         media.refs.mediaContainer.style.marginLeft = 0;
      }
      catch (e) {}
      // debugger;
      this.hideVideoControls(media);
      
      
      if (this.isFullscreen) {
         if (!this.allowAutoPlay && !media.clicked) {
            this.stopVideo(media); /* A TRAITER */
      
            // if (media.isVideo)
            try {
               if (!this.rows[media.pos.y].visible)
                  this.setHidden(media);
            }
            catch(e) {}
         }
         else
            this.pauseVideo(media);
      }
      else {
         try {
            const scrollOffset = Math.max(0, media.refs.item.offsetTop + (media.refs.item.clientHeight / 2) - (window.innerHeight /2));
            // debugger;
            if (  media.refs.item.offsetTop < window.scrollY
               || Math.abs(scrollOffset - window.scrollY) > 10
            ) {
               media.refs.item.scrollIntoView({block: "center"});
            }
            else
               this.playVisibleVideos(true);
         // this.selectedMedia = null;
         }
         catch (e) {
            console.error(e);
            this.playVisibleVideos(true);
         }
      }
   }
   
   
   playVideo(media, keepTime = true) {
      console.log('playVideo '+media.id+' '+(keepTime ? 'keepTime':'')+' playing : '+media.playing);
      // debugger;
      this.setVisible(media);
   
      if (!media.isVideo)
         return;
      
      if (!media.playing) {
         this._playVideo(media, 'sd', keepTime);
         this._playVideo(media, 'hd', keepTime);
      }
      
      this.setVideoPlayState(media);
   }
   
   preloadVideo(media) {
      // console.log('preloadVideo '+media.id);
      this.setVisible(media);
      
      if (!media.isVideo)
         return;
      
      if (!media.playing) {
         this._preloadVideo(media, 'sd');
         this._preloadVideo(media, 'hd');
      }
      
      // media.play = true; /* A TRAITER */ // A SUPPR ?
   }
   
   setVideoPlayState(media) {
      // console.log('setVideoPlayState '+media.id);
      
      media.play = true;
      
      /*try {
         media.refs.item.classList.add('PLAY'); /!* TEST *!/
         media.refs.item.classList.remove('PAUSE');
      }
      catch(e) { /!*console.error(e)*!/ }*/ /* PRODUCTION */
   }
   
   setVideoPauseState(media) {
      // console.log('setVideoPauseState '+media.id);
      
      media.play = false;
      
      /*try {
         media.refs.item.classList.remove('PLAY'); /!* TEST *!/
         media.refs.item.classList.add('PAUSE');
      }
      catch(e) { /!*console.error(e)*!/ }*/ /* PRODUCTION */
   }
   
   setVisible(media) {
      if (media.visible)
         return;
   
      console.log('setVisible '+media.id);
      media.visible = true;
      
      try {
         // media.refs.mediaContainer.style.visibility = 'visible';
         // media.refs.mediaContainer.style.display = 'block';
         media.refs.item.classList.remove('hidden');
      }
      catch(e) { console.error(e) }
   }
   
   setHidden(media) {
      if (!media.visible)
         return;
      
      console.log('setHidden '+media.id);
      media.visible = false;
      
      try {
         // media.refs.mediaContainer.style.visibility = 'hidden';
         // media.refs.mediaContainer.style.display = 'none';
         media.refs.item.classList.add('hidden');
      }
      catch(e) { console.error(e) }
   }
   
   pauseVideo(media) {
      console.log('pauseVideo '+media.id);
      if (!media.isVideo)
         return;
      
      // if (media.playing) {
         this._pauseVideo(media, 'sd');
         this._pauseVideo(media, 'hd');
      // }
      
      this.setVideoPauseState(media);
   }
   
   stopVideo(media) {
      // console.log('stopVideo '+media.id);
      if (!media.isVideo)
         return;
      
      this._pauseVideo(media, 'sd');
      this._pauseVideo(media, 'hd');
      
      this.setVideoPauseState(media);
      
      this.removeVideoPlayStarted(media);
   }
   
   removeVideoPlayStarted(media) {
      // console.log('removeVideoPlayStarted '+media.id);
      if (!media.isVideo)
         return;
      
      media.clicked = false;
      media.playStarted = false;
      media.waiting = false;
   
      try {
         media.refs.item.classList.remove('waiting');
         media.refs.item.classList.remove('clicked');
         media.refs.item.classList.remove('play-started');
      }
      catch(e) { console.error(e) }
   }
   
   _playVideo(media, size, keepTime = true/*, keepLoading = false*/) {
      console.log('_playVideo : '+media.id+' '+size+(keepTime ? ' keepTime':''));
      // if (!media.refs[size])
      //    return;
      
      try {
         // console.log('play');
         // if (/*!this.state.allowAutoPlay || */!this.letBrowserAutoPlay) {
         //    media.refs[size].setAttribute('preload', 'auto');
            media.refs[size].preload = 'auto';
         // }
         if (!keepTime)
            media.refs[size].currentTime = 0;
         
         // console.log('PLAY && PRELOAD');
         // if (media.refs[size].paused)
         //    debugger;
         media.refs[size].autoplay = true;
         media.refs[size].play().catch(e => {
            // console.error('error play() !!! '+media.id+' '+size);
            // load retry, necessary ?
            /*try {
               media.refs[size].play().catch(e => {
                  console.error(e);
               });
            } catch (e) {}*/
         });
      }
      catch(e) { /*console.error(e)*/ }
   }
   
   _preloadVideo(media, size) {
      // console.log('_playVideo : '+media.id+' '+size+(onlyPreload ? ' ONLY PRELOAD':'')+(keepTime ? ' keepTime':''));
      try {
         media.refs[size].preload = 'auto';
         // media.refs[size].setAttribute('preload', 'auto');
      }
      catch(e) { /*console.error(e)*/ }
   }
   
   _pauseVideo(media, size) {
      // console.log('_pauseVideo : '+media.id+' '+size+(onlyPreload ? ' ONLY PRELOAD':'')+(keepTime ? ' keepTime':''));
      // if (!media.refs[size])
      //    return;
      
      try {
         // if (/*!this.state.allowAutoPlay || */!this.letBrowserAutoPlay) {
            media.refs[size].removeAttribute('autoplay');
            
            // if (!keepLoading)
            if (!media.visible && !this.isFullscreen)
               media.refs[size].setAttribute('preload', 'metadata');
               // media.refs[size].setAttribute('preload', 'metadata');
         // }
         media.refs[size].pause();
      }
      catch(e) { /*console.error(e)*/ }
   }
   
   muteVideo(media, muted = true, size = null) {
      // console.log('muteVideo '+media.id+' '+size+' : ',muted);
      // if (!media.refs[size] ||! media.isVideo)
      //    return;
      
      try {
         media.refs[size || media.selectedSize].muted = muted;
      } catch (e) {}
   }
   
   swipeVolume(media, prevSize, nextSize) {
      const muted = media.refs[prevSize].muted;
      media.refs[prevSize].muted = true;
      media.refs[nextSize].volume = media.refs[prevSize].volume;
      media.refs[nextSize].muted = muted;
   }
   
   displayVideoControls(media) {
      // console.log('displayVideoControls '+media.id);
      if (!media.isVideo)
         return;
      
      try {
         // media.refs[size || media.selectedSize].setAttribute('controls', true);
         if (!media.refs[media.selectedSize].controls)
            media.refs[media.selectedSize].controls = true;
      }
      catch(e) {}
   }
   
   hideVideoControls(media) {
      // console.log('hideVideoControls '+media.id);
      if (!media.isVideo)
         return;
      
      try {
         media.refs[media.selectedSize].removeAttribute('controls');
      }
      catch(e) {}
   }
   
   loadHDImg(media) {
      // console.log('loadHDImg : ',media.id);
      // debugger;
      try {
         // if (  (media.hd.ok && !media.hasMultipleSizes)
         //    || (!media.hd.ok && !media.refs.hd))
         if (!media.hasMultipleSizes || !media.sd.loaded) // SI LA SD EST EN ATTENTE DE LOAD ou QUE LA TAILLE HD N'EST PAS SUFFISANTE
            return null;
         
         else if (!media.hd.loaded) {
            // console.log('LOAD HD : ',media.id);
            media.hd.inserted = true;
            // media.refs.component.current.setState({media});
            this.forceStateMedia(media);
         }
         else
            media.refs.hd.load();
      }
      catch(e) { console.error(e) }
   }
   
   loadHDVideo(media) {
      console.log('loadHDVideo '+media.id+' : ',media);
      try {
         // if (  (media.hd.ok && !media.hasMultipleSizes)
         //    || (!media.hd.ok && !media.refs.hd))
         if (!media.hasMultipleSizes || (!media.sd.loaded && !media.playing)) // SI LA SD EST EN ATTENTE DE LOAD ou QUE LA TAILLE HD N'EST PAS SUFFISANTE
            return null;
         
         else if (!media.hd.loaded) {
            console.log('LOAD HD : ',media.id);
            media.hd.inserted = true;
            // media.refs.component.current.setState({media}, () => {
            this.forceStateMedia(media, () => {
               try {
                  let currentTime = media.refs.sd.currentTime + 1; // FAIRE UN AJOUT DE TEMPS FONCTION DE LA VITESSE
                  
                  while(currentTime > media.refs.sd.duration)
                     currentTime -= media.refs.sd.duration;
                  
                  media.refs.hd.currentTime = currentTime;
                  if (media.play) {
                     // console.log('PLAY 1');
                     media.refs.hd.play().catch(e => { /*media.refs.hd.play().catch(e => {})*/ });
                  }
               }
               catch(e) { /*console.error(e)*/ }
            });
         }
         else
            media.refs.hd.load();
      }
      catch(e) { console.error(e) }
   }
   
   
   /*replaceSDByHDVideo1(media) {
      console.log('replaceSDByHDVideo : ',media.id);
      // return;
      // media.hd.selected = active;
      if (!media.sd.ok)
         return;
      
      if (!media.hd.selected) {
         try {
            media.refs.hd.currentTime = media.refs.sd.currentTime;
            
            if (!media.play) {
               media.refs.item.classList.add('hd-on');
               media.refs.hd.controls = media.refs.sd.controls;
               this.removeVideoSize(media);
            }
            else {
               // console.log('PLAY 2');
               media.refs.hd.controls = media.refs.sd.controls;
               media.refs.hd.play().catch(e => {
                  // media.refs.hd.play().catch(e => {
                  // })
               });
               
               setTimeout(() => {
                  try {
                     media.refs.hd.currentTime += 2 * (media.refs.sd.currentTime - media.refs.hd.currentTime);
                     media.refs.hd.controls = media.refs.sd.controls;
                     // console.log('TRANSITION DONE : ',media.id);
                     setTimeout(() => {
                        // media.hd.selected = true;
                        media.refs.hd.controls = media.refs.sd.controls;
                        media.refs.item.classList.add('hd-on');
                        
                        setTimeout(() => {
                           this.removeVideoSize(media);
                        }, 500);
                     }, 1000);
                  } catch (e) {
                     console.error(e)
                  }
               }, 1000);
               
            }
         } catch (e) {console.error(e)}
      }
      else
         this.removeVideoSize(media);
   }*/
   
   replaceSDByHDImg(media) {
      // console.log('replaceSDByHDImg : ',media.id);
      // return;
      // media.hd.selected = active;
      if (!media.sd.ok)
         return;
      
      if (!media.hd.selected) {
         try {
            media.refs.item.classList.add('hd-on');
         } catch (e) {console.error(e)}
         
         setTimeout(() => {
            this.removeImgSizeSD(media);
         }, 3000);
      }
      else
         this.removeImgSizeSD(media);
   }
   
   replaceSDByHDVideo(media) {
      console.log('replaceSDByHDVideo : ',media.id);
      // return;
      // media.hd.selected = active;
      if (!media.sd.ok)
         return;
      
      if (!media.hd.selected) {
         
         try {
            media.refs.hd.currentTime = media.refs.sd.currentTime;
            
            if (this._replaceSDByHDVideo(media))
               return;
            
            // console.log('PLAY 2');
            media.refs.hd.play().catch(e => {});
         }
         catch (e) {console.error(e)}
         
         setTimeout(() => {
            try {
               media.refs.hd.currentTime += 2 * (media.refs.sd.currentTime - media.refs.hd.currentTime);
               // console.log('TRANSITION DONE : ',media.id);
            } catch (e) {console.error(e)}
            
            if (this._replaceSDByHDVideo(media))
               return;
               
            setTimeout(() => {
               // media.hd.selected = true;
               if (this._replaceSDByHDVideo(media))
                  return;
                  
               try {
                  media.refs.item.classList.add('hd-on');
               } catch (e) {console.error(e)}

               setTimeout(() => {
                  this.removeVideoSizeSD(media);
               }, 500);
               
            }, 1000);
            
         }, 1000);
      }
      else
         this.removeVideoSizeSD(media);
   }
   
   _replaceSDByHDVideo(media) {
      try {
         media.refs.hd.controls = media.refs.sd.controls;
         
         if (!media.play) {
            try {
               media.refs.hd.pause();
            } catch (e) {}
            
            media.refs.item.classList.add('hd-on');
            
            this.removeVideoSizeSD(media);
            return true;
         }
      } catch (e) {}
   }
   
   /*removeVideoSize(media, removeSize = 'sd', mute = true) {
      console.log('removeVideoSize '+media.id+' : '+removeSize);
      // return;
      let keepSize = 'hd', removeSD = true;
      
      if (removeSize === keepSize) {
         removeSize = 'hd';
         keepSize = 'sd';
         removeSD = false;
      }
      
      // console.log('removeVideoSize '+media.id+' '+removeSize);
      
      media[removeSize] = {
         ok: false,
         only: false,
         inserted: false,
         selected: false,
         loaded: false,
      };
      
      if (media[keepSize].ok) {
         media[keepSize].selected = true;
         media.hasMultipleSizes = false;
         media[keepSize].only = true;
         
         media.selectedSize = media.onlySize = keepSize;
         
         if (mute) {
            try {
               if (removeSD && !media.refs.sd.muted) {
                  this.muteVideo(media, true, 'sd');
                  this.muteVideo(media, false);
               }
            } catch (e) {}
         }
      }
      else {
         media.empty = true;
         media.selectedSize = media.onlySize = '';
      }
      
      try {
         media.refs.component.current.setState({media});
      }
      catch (e) {}
      // media.refs.sd.remove();
   }*/
   
   removeImgSizeHD(media) {
      // console.log('removeImgSizeSD '+media.id);
      this.setImgSizeOnly(media, false, 'hd');
   }
   
   removeImgSizeSD(media) {
      // console.log('removeImgSizeSD '+media.id);
      this.setImgSizeOnly(media, true, 'sd');
   }
   
   setImgSizeOnly(media, isHD = true, size = 'hd') {
      // console.log('setImgSizeOnly '+media.id+' : '+size);
      // return;
      
      this.setMediaSizeOnly(media, isHD);
      
      media[size].loaded = true;
      
      try {
         // media.refs.component.current.setState({media});
         this.forceStateMedia(media);
      }
      catch (e) {}
      // media.refs.sd.remove();
   }
   
   removeVideoSizeSD(media, removeSize = 'sd', mute = true) {
      console.log('removeVideoSizeSD '+media.id+' : '+removeSize);
      // return;
      
      this.setMediaSizeOnly(media, true);
      
      media.hd.loaded = true;
      
      if (mute) {
         try {
            if (!media.refs.sd.muted) {
               const muted = media.refs[removeSize].muted;
               media.refs[removeSize].muted = true;
               media.refs[media.selectedSize].volume = media.refs[removeSize].volume;
               media.refs[media.selectedSize].muted = muted;
               // this.muteVideo(media, true, 'sd');
               // this.muteVideo(media, false);
            }
         } catch (e) {}
      }
   
      try {
         // media.refs.component.current.setState({media});
         this.forceStateMedia(media);
      }
      catch (e) {}
      
      // media.refs.sd.remove();
   }
   
   toggleVideoSize(media, size) {
      console.log('toggleVideoSize '+media.id+' : '+size);
      // debugger
      try {
         if (!media.hd.inserted) {
            media.hd.inserted = true;
            // media.refs.component.current.setState({media}, () => {
            //    this.toggleVideoSize(media, size);
            // });
            this.forceStateMedia(media, () => {
               this.toggleVideoSize(media, size);
            });
         }
         else if (media.selectedSize !== size && media.hasMultipleSizes)
         {
            const prevSize = media.selectedSize;
            
            media[size].selected = true;
            media[prevSize].selected = false;
            // media.selectedSize = size;
   
            // this.forceStateMedia(media);
            // console.log('toggleVideoSize : ',media.id);
            try {
               if (media.refs[size] &&  media.refs[prevSize]) {
                  
                  media.refs[size].muted = true;
                  media.refs[size].currentTime = media.refs[prevSize].currentTime;
                  
                  media.refs[size].play().then(() => {
                     
                     try {
                        this.swipeVolume(media, prevSize, size);
                        
                        media.selectedSize = size;
      
                        if (media.hd.selected) {
                           media.refs.item.classList.add('hd-on');
         
                           if (media.hd.loaded)
                              this.removeVideoSizeSD(media, 'sd', false);
                        } else
                           media.refs.item.classList.remove('hd-on');
                        // media.refs[media.selectedSize].pause();
                     }
                     catch(e) {
                        console.error(e);
                        this._cancelToggleSize(media, size, prevSize);
                     }
                  
                  }).catch(e => {
                     console.error(e);
                     this._cancelToggleSize(media, size, prevSize);
                  })
               }
            }
            catch(e) {
               console.error(e);
               this._cancelToggleSize(media, size, prevSize);
            }
            
            // media.refs.component.current.setState({media}, ()=>{
            //    this.updateLoadingProgress(media, size);
            // });
            this.forceStateMedia(media);
         }
      }
      catch(e) { console.error(e) }
   }
   
   toggleImgSize(media, size) {
      // console.log('toggleImgSize '+media.id+' : '+size);
      // debugger
      
      try {
         if (!media.hd.inserted) {
            media.hd.inserted = true;
            // media.refs.component.current.setState({media}, () => {
            //    this.toggleImgSize(media, size);
            // });
            this.forceStateMedia(media, () => {
               this.toggleImgSize(media, size);
            });
         }
         else if (media.selectedSize !== size && media.hasMultipleSizes)
         {
            media[size].selected = true;
            media[media.selectedSize].selected = false;
            // media.selectedSize = size;
            
            const prevSize = media.selectedSize;
            media.selectedSize = size;
            // console.log('toggleVideoSize : ',media.id);
            try {
               
               if (media.hd.selected) {
                  media.refs.item.classList.add('hd-on');
   
                  if (media.hd.loaded)
                     this.removeImgSizeSD(media);
               } else
                  media.refs.item.classList.remove('hd-on');
            }
            catch(e) {
               /*console.error(e)*/;
               this._cancelToggleSize(media, size, prevSize);
            }
         }
      }
      catch(e) { console.error(e) }
   }
   
   _cancelToggleSize(media, size, prevSize) {
      media[size].selected = false;
      media[media.selectedSize].selected = true;
      media.selectedSize = prevSize;
   }
   
   onNavNextToMedia(e, horizontal = true, delta = 1) {
      this.endClickEvent(e);
      if (!this.isTouchScreen || this.isHover) {
         this.navNextToMedia(horizontal, delta);
         this.canDisplayHoverAfterClick = false;
      }
   }
   
   onNavPrevToMedia(e) {
      this.onNavNextToMedia(e, true, -1);
   }
   
   navNextToMedia(horizontal = true, delta = 1) {
      if (this.isFullscreen) {
         const pos = Object.assign({}, this.selectedMedia.pos);
         
         if (horizontal) {
            if ( !this._navNextX(pos, delta)) {
               if (delta > 0)
                  this.loadNextPage();
               return;
            }
            
            // this.lastPosX = pos.x;
         }
         /**** NAVIGATION VERTICALE ANNULEE ****/
         /*else {
            pos.x = this.lastPosX;
            
            if ( !this._navNextY(pos, delta, true)) {
               if (delta > 0)
                  this.loadNextPage();
               return;
            }
         }*/
         
         this.closeMedia(this.selectedMedia);
         this.openMedia(this.rows[pos.y].items[pos.x]);
         this.preloadRowsAround(pos.y);
         
         if (pos.y === this.rows.length-1)
            this.loadNextPage();
         
      }
   }
   
   _navNextX(pos, delta) {
      pos.x += delta;
      
      if (!this.rows[pos.y].items[pos.x]) {
         
         if (!this._navNextY(pos, delta, false))
            return false;
         
         if (delta > 0)
            pos.x = 0;
         else
            pos.x = this.rows[pos.y].items.length -1;
      }
      
      return true;
   }
   
   _navNextY(pos, delta, limit = true) {
      if (this.nextPageAfter || this.lastPageIsItem) {
         if (  (delta > 0 && pos.y === this.rows.length - 1)
            || (delta < 0 && pos.y === 0)
         )
            return false;
      }
      
      pos.y += delta;
      
      if (!this.rows[pos.y]) {
         if (delta > 0)
            pos.y = 0;
         else
            pos.y = this.rows.length - 1;
      }
      
      if (limit)
         pos.x = Math.max(0, Math.min(pos.x, this.rows[pos.y].items.length -1) );
      
      return true;
   }
   
   toggleFullscreen(media) {
      console.log('toggleFullscreen this.isFullscreen : ',this.isFullscreen)
      if (!this.allowFullScreen)
         return;
      // debugger;
      if (!this.isFullscreen && !document.fullscreenElement &&    // alternative standard method
         !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
         this.goFullscreen(media);
      }
      else if (!this.isTouchScreen) {
         this.exitFullscreen();
      }
   }
   
   goFullscreen(media) {
      console.log('goFullscreen this.isFullscreen : ',this.isFullscreen)
      try {
         if (this.isFullscreen || this.selectedMedia || !media/* || !media.refs.fullscreenContainer*/)
            return;
         // debugger;
   
         this.isTheGoodFullscreenChangeEvent = true; // PERMET DE FAIRE D'ELIMINER UN DOUBLE EVENT NON DESIRE
         this.selectedMedia = media;
         media.isFullscreen = true;
         console.log('this.selectedMedia : ',this.selectedMedia);
         
         this.pauseOtherVisibleVideos();
         // this.fullscreenElement = media.refs.fullscreenContainer;
         // media.refs.item.classList.add('fullscreen');
         this.fullscreenElement = document.documentElement;
         
         // if (!media)
         //    this.fullscreenElement = document.documentElement;
         
         // if (this.fullscreenElement.requestFullscreen) {
         //    this.fullscreenElement.requestFullscreen();
         // } else if (this.fullscreenElement.mozRequestFullScreen) {
         //    this.fullscreenElement.mozRequestFullScreen();
         // } else if (this.fullscreenElement.webkitRequestFullscreen) {
         //    this.fullscreenElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
         // }
         if (this.fullscreenElement.requestFullscreen) {
            this.fullscreenElement.requestFullscreen()
               .catch(e => {
               console.log('low speed scroll fullscreen attempt prevented');
               this.resetFullscreen(media);
            });
         } else if (this.fullscreenElement.mozRequestFullScreen) {
            this.fullscreenElement.mozRequestFullScreen();
         } else if (this.fullscreenElement.webkitRequestFullscreen) {
            this.fullscreenElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
         }
      }
      catch(e) {
         this.resetFullscreen(media);
      }
   }
   
   exitFullscreen() {
      console.log('exitFullscreen this.isFullscreen : ',this.isFullscreen);
      if (!this.allowFullScreen)
         return;
      
      if (!this.isFullscreen || !this.selectedMedia)
         return;
      
      try {
   
         if (!document.fullscreenElement &&    // alternative standard method
            !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
            this.closeFullscreenMedia();
         }
         else if (document.exitFullscreen) {
            document.exitFullscreen().catch(e => {
               this.closeFullscreenMedia();
            });
         } else if (document.cancelFullScreen) {
            document.cancelFullScreen();
         } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
         } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
         }
      }
      catch (e) { console.error(e) }
   }
   
   closeFullscreenMedia() {
      console.log('closeFullscreenMedia this.isFullscreen : ',this.isFullscreen);
      this.isFullscreen = false;
      if (this.selectedMedia)
         this.closeMedia(this.selectedMedia);
      this.resetFullscreen(this.selectedMedia);
   }
   
   onClickMedia(e, media) {
      // e.target.
      // debugger;
      console.log('ON CLICK ',media.id);
      // console.log('media.playStarted ',media.playStarted);
      this.endClickEvent(e);
      // debugger
      if (!media.isVideo || media.clicked || media.playStarted /*|| this.letBrowserAutoPlay*/) {
         this.toggleFullscreen(media);
      }
      else
         this.openMedia(media, true);
   }
   
   onTouchStartMedia(e) {
      // console.log('onTouchStartMedia target : ',e.currentTarget);
      this.touchStartPosX = e.touches[0].clientX;
      this.touchLastDeltaX = 0;
      this.touchLastPosX = 0;
   
      if (!this.touchStarted) {
         this.touchStarted = true;
         this.isTouchShort = true;
         clearTimeout(this.touchPressTimeout);
         this.touchPressTimeout = setTimeout(() => {
            // console.log('touch started FALSE !!!!!!')
            this.isTouchShort = false;
         }, 400)
      }
   
      if (this.isFullscreen)
         this.displayBodyTitles(true);
      // this.isTouchClick = true;
      // e.preventDefault();
   }
   
   onTouchMoveMedia(e, media) {
      // console.log('onTouchMoveMedia');
      if (!this.isFullscreen)
         return;
      
      try {
         media.refs.mediaContainer.style.marginLeft = (e.touches[0].clientX - this.touchStartPosX) + 'px';
      } catch(e) {}
      
      this.touchLastDeltaX = e.touches[0].clientX - this.touchLastPosX;
      this.touchLastPosX = e.touches[0].clientX;
   }
   
   onTouchEndMedia(e, media) {
      // console.log('onTouchEndMedia');
      const distance = Math.abs(e.changedTouches[0].clientX - this.touchStartPosX);
      // console.log('onTouchEndMedia target : ',e.target);
      // console.log('distance : ',distance);
      // console.log('this.isFullscreen : ',this.isFullscreen);
      
      this.touchStarted = false;
      
      try {
         media.refs.mediaContainer.style.marginLeft = 0;
      } catch(e) {}
      
      if (distance < 50) {
         if (!distance) { // c'est un click par touch
            if (this.isFullscreen) {
                  // debugger
               if (this.isTouchEndOnButton || this.isTouchEndOnTitle) { // a cliqué sur le titre ?
                  if ((this.isTouchEndOnButton && !this.isHover) || (this.isTouchEndOnTitle && !this.isHoverTitle)) {
                     this.endClickEvent(e);
                  }
                  this.displayBodyHover();
               }
               else
                  this.toggleBodyHover();
            }
            else if (this.isTouchShort) {
               // console.log('TOUCH END OPEN MEDIA');
               this.onClickMedia(e, media); // will preventdefault
            }
            else {
               // console.log('SLOW SCROLL TOUCH PREVENTED')
               this.endClickEvent(e);
            }
         }
         else
            this.displayBodyHover();
      }
      else {
         if (this.touchLastDeltaX > 0)
            this.navNextToMedia(true, -1);
         else if (this.touchLastDeltaX < 0)
            this.navNextToMedia();
      }
   }
   
   onTouchEndTitle(e) {
      // console.log('ON TOUCH END TITLE ');
      this.isTouchEndOnTitle = true;
   }
   
   onTouchEndButton(e) {
      // console.log('ON TOUCH END BUTTON ');
      this.isTouchEndOnButton = true;
   }
   
   onClickButton(e) {
      // console.log('ON CLICK BUTTON is hover : ',this.isHover);
      if (!this.isHover)
         this.endClickEvent(e);
      else
         e.stopPropagation();
         // e.nativeEvent.stopImmediatePropagation();
   }
   
   onClickTitle(e) {
      // console.log('ON CLICK TITLE ',e);
      // if (this.isHoverTitles)
      //    this.pauseVideo(media);
      // else
      if (!this.isHoverTitle)
         this.endClickEvent(e);
      else
         e.stopPropagation();
         // e.nativeEvent.stopImmediatePropagation();
      // window.open('https://reddit.com'+media.redditUrl);
   }
   
   /*onClickSource(e, media) {
      // console.log('ON CLICK SOURCE is hover : ',this.isHover);
      // if (this.isHover)
      //    this.pauseVideo(media);
      // else
      if (!this.isHover)
         this.endClickEvent(e);
   }
   
   onClickDownload(e, media) {
      // console.log('ON CLICK TITLE ',media.id);
      // if (this.isHover) {
      //    let type = !media.isVideo ? 'img' : media.hd.ok ? 'hd' : 'sd';
      //    let b = window.browser || window.chrome;
      //    try {
      //       b.downloads.download(
      //          {url: media[type].files[0]}
      //       ).then().catch(e => console.error(e));
      //    }
      //    catch(e) { console.error(e)}
      // }
      // else
      if (!this.isHover)
         this.endClickEvent(e);
   }
   
   onClickShare(e, media) {
      // console.log('ON CLICK TITLE ',media.id);
      if (!this.isHover)
         this.endClickEvent(e);
   }*/
   
   onClickDocument() {
      if (!this.isTouchScreen && this.canDisplayHoverAfterClick) {
         // console.log('onClickDocument')
         this.displayBodyHover();
      }
   
      this.canDisplayHoverAfterClick = true;
   }
   
   onMouseDownCaptureDocument() {
      // console.log('on mouse DOWN');
      this.isMouseDown = true; // pour empécher mouse move de toggle Hover
   }
   
   onMouseUpCaptureDocument(e) {
      // console.log('on mouse UP');
      // this.endClickEvent(e);
      this.isMouseDown = false;
   }
   
   endClickEvent(e) {
      e.preventDefault();
      this.isMouseDown = false;
      this.touchStarted = false;
      this.isTouchShort = false;
      this.isTouchEndOnTitle = false;
      this.isTouchEndOnButton = false;
      // this.isTouchClick = false;
   }
   
   _onMouseMove(e) {
      // console.log('on Mouse move e.clientY : '+e.clientY+' this.topBarMouseHoverHeight : ', this.topBarMouseHoverHeight);
      if (!this.isMouseDown /*&& !this.isTouchScreen*/) {
         this.displayBodyHover();
         
         if (window.scrollY) {
   
            let deltaY = e.clientY - this.prevMouseY;
            
            if (deltaY < 0 && e.clientY < this.topBarMouseHoverHeight)
               this.displayTopBar();
            // else if (deltaY > 0 && e.clientY > this.topBarMouseHoverHeight)
            //    this.hideTopBar();
   
            this.prevMouseY = e.clientY;
         }
      }
   }
   
   onTouchStart() {
      // console.log('onTouchStart');
      
      if (!this.isTouchScreen) {
         this.isTouchScreen = true;
         // this.appRef.current.classList.add('touch');
         // this.appRef.current.classList.remove('no-touch');
         this.setDisplayTitles(this.defaultDisplayTitles);
         this.updateTimeoutDelays();
      }
      
      if (!this.isFullscreen)
         this.displayBodyHover(true); // pas en fullscreen pour ne pas empecher le toggle on off rapide du hover
   }
   
   /*setDisplayHomeTitles(active = true) {
      if (active)
         this.appRef.current.classList.add('allow-titles');
      else
         this.appRef.current.classList.remove('allow-titles');
      
      this.displayTitles = active;
   }*/
   
   setDisplayTitles(displayTitles) {
      // this.appRef.current.classList.add('allow-titles');
      if (displayTitles)
         document.body.classList.add('allow-titles');
      else
         document.body.classList.remove('allow-titles');
      
      this.displayTitles = displayTitles;
   }
   
   onTouchMove() {
      // console.log('onTouchMove');
      // if (!this.isFullscreen)
      //    this.toggleBodyHover(true, true);
   }
   
   updateTimeoutDelays() {
      // console.log('updateTimeoutDelays : ',this.isTouchScreen);
      if (this.isTouchScreen) {
         this.titlesHoverDelay = this.displayMobileTitlesLong ? 1500 : 500;
         this.titleHoverDelay = this.displayMobileTitlesLong ? 1500 : 1500;
         this.bodyHoverDelay = this.titlesHoverDelay + this.titleHoverDelay;
         this.titleLinkHoverDelay = 1000;
      }
      else {
         this.bodyHoverDelay = 2000;
         this.titlesHoverDelay = this.displayTitles ? 2500 : 0;
         this.titleHoverDelay = 2500;
         this.titleLinkHoverDelay = 2000;
      }
   }
   
   toggleBodyHover(noTimeout = false) {
      // console.log('toggleBodyHover Active : '+active+' noTimeout : ',noTimeout);
      // return;
      // document.body.classList.add('hover', 'titles', 'title');
      // return;
      
      if (this.isHover)
         this.hideBodyHover();
      else
         this.displayBodyHover(noTimeout);
   }
   
   displayBodyHover(noTimeout = false) {
      // console.log('toggleBodyHover Active : '+active+' noTimeout : ',noTimeout);
      // return;
      // console.log('on mouse move');
      clearTimeout(this.titlesMouseMoveTimeout);
      clearTimeout(this.bodyMouseMoveTimeout);
   
      // document.body.classList.add('hover', 'titles', 'title');
      // return;
      
      if (!this.isHover) {
         document.body.classList.add('hover', 'title', 'title-link');
         if (this.displayTitles)
            document.body.classList.add('titles');
         
         this.isHoverTitles = this.isHoverTitle = true;
         // if (this.displayTitles)
         //    document.body.classList.add('titles');
      }
      
      // console.log('toggleBodyHover TRUE');
      this.isHover = true;

      this.displayBodyTitles(noTimeout);

      if (noTimeout)
         return;
      
      this.bodyMouseMoveTimeout = setTimeout(() => {
         // console.log('toggleBodyHover FALSE');
         this.isHover = false;
         document.body.classList.remove('hover');
      }, this.bodyHoverDelay);
   }
   
   hideBodyHover() {
      // console.log('hideBodyHover');
      // return;
      clearTimeout(this.titlesMouseMoveTimeout);
      clearTimeout(this.bodyMouseMoveTimeout);
   
      this.isHover = this.isHoverTitles = this.isHoverTitle = false;
      document.body.classList.remove('hover', 'titles', 'title', 'title-link');
   }
   
   toggleBodyTitles(noTimeout = false) {
      // return;
      if (this.isHoverTitles)
         this.hideBodyTitles();
      else
         this.displayBodyTitles(noTimeout);
   }
   
   displayBodyTitles(noTimeout = false) {
      // return;
      if (!this.isHoverTitles) {
         document.body.classList.add('title', 'title-link');
         if (this.displayTitles)
            document.body.classList.add('titles');
      }

      this.isHoverTitles = this.isHoverTitle = true;
      
      // console.log('toggleBodyTitles TRUE');
      clearTimeout(this.titlesMouseMoveTimeout);

      if (!this.canHideTitles || noTimeout)
         return;
      
      this.titlesMouseMoveTimeout = setTimeout(() => {
         // console.log('toggleBodyTitles FALSE');
         this.isHoverTitles = false;
         document.body.classList.remove('titles');
         
         this.titlesMouseMoveTimeout = setTimeout(() => {
            document.body.classList.remove('title');
            
            this.titlesMouseMoveTimeout = setTimeout(() => {
               
               this.isHoverTitle = false;
               document.body.classList.remove('title-link');
               
            }, this.titleLinkHoverDelay);
            
         }, this.titleHoverDelay);
   
      }, this.titlesHoverDelay);
   }
   
   hideBodyTitles() {
      // return;
      if (this.canHideTitles) {
         clearTimeout(this.titlesMouseMoveTimeout);
         
         this.isHoverTitles = this.isHoverTitle = false;
         document.body.classList.remove('titles','title', 'title-link');
      }
   }
   
   onMouseMoveMedia(e, media) {
      // console.log('on mouse move media');
      // return;
      if (media.isFullscreen && !e.target.controls)
         e.target.controls = true;
         // e.target.setAttribute('controls', true);
   }

   onMouseLeaveMedia(e, media) {
      // return;
      if (media.isFullscreen && e.target.controls)
         e.target.removeAttribute('controls');
   }
   
   onVolumeChangeMedia(e, media, size) {
      if (media.isFullscreen && media.selectedSize === size) {
         this.allowFullscreenVolume = !e.target.muted && e.target.volume > 0;
      }
   }
   
   
   onImgLoaded(e, media, isHD, size) {
      // console.log('onImgLoaded '+media.id+' '+(isHD ? ' HD' : ''), e.nativeEvent);
      // console.log('complete : ',e.target.complete);
      try {
         this.hideLoadingLayer(media);
   
         if (media.waiting) {
            this.removeWaiting(media);
         }
         
         if (!isHD && !media.sd.loaded) {
            // console.log('VIDEO SD LOADED : ', media.id);
            
            media.sd.loaded = true;
            media.refs.item.classList.add('sd-loaded');
            
            if (media.mustDisplayHD)
               this.loadHDImg(media);
            // else
            //    this.forceStateMedia(media);
            //    // media.refs.component.current.setState({media});
         }
         else if (isHD && !media.hd.loaded) {
            // console.log('VIDEO HD LOADED : ', media.id);
            // console.log('HD VIDEO LOADING DONE ON : ', media.id)
            media.hd.loaded = true;
            media.refs.item.classList.add('hd-loaded');
            
            if (!media.hd.selected)
               this.replaceSDByHDImg(media);
            
            else if (!media.hd.only) {
               this.removeImgSizeSD(media);
               // media.refs.component.current.setState({media});
            }
            // else
            //    this.forceStateMedia(media);
            //    // media.refs.component.current.setState({media});
         }
      }
      catch(e) { console.error(e) }
   }
   
   onImgError(e, media, isHD, size) {
      // console.log('onImgError '+media.id+' '+(isHD ? ' HD' : ''),e);
      try {
         this.hideLoadingLayer(media);
   
         if (media.waiting) {
            this.removeWaiting(media);
         }
         
         if (media[size].only)
            this.removeMedia(media);
         
         else if (!isHD)
            this.removeImgSizeSD(media);
         
         else if (isHD && !media.hd.loaded)
            this.removeImgSizeHD(media);
      }
      catch(e) { console.error(e) }
   }
   
   
   removeMedia(media) {
      return;
      // this.removedMedias[media.id] = true;
      // this.forceState('gallery', this.galleryRef);
   }
   
   /*onImgLoadingProgress(e, media, isHD, size) {
      console.log('onImgLoadingProgress '+media.id+' '+size/!*+' '+(isWaitingEvent ? 'isWaitingEvent true' : '')*!/);
      // debugger;
      try {
         // const progress = Math.round(e.target.buffered.end(0)) / Math.round(e.target.seekable.end(0));
         const progress = this.getImgLoadingProgress(media, size) || 0;
         
         this.updateLoadingProgress(media, isHD, size, progress);
         
         if (progress >= 95) {
            // When buffer is 1 whole video is buffered
            
            this.onImgLoaded(media, isHD);
         }
      }
      catch(e) { console.error(e) }
   }*/
   
   
   onVideoLoadingProgress(e, media, isHD, size, isWaitingEvent = false, forceLoadCB = false) {
      // if (isHD)
      // console.log('onVideoLoadingProgress '+media.id+' '+size+' '+(isWaitingEvent ? 'isWaitingEvent true' : ''));
      /*if (e.target.readyState >= 3) {
         console.log('VIDEO LOADING PROGRESS FOR '+media.id+(isHD ? ' - HD : ':' : '), Math.round(e.currentTarget.buffered.end(0)) / Math.round(e.currentTarget.seekable.end(0)))
      }*/
      // media.errors = 0;
      // debugger;
      // console.log('e.nativeEvent: ',e.nativeEvent);
      try {
         // const progress = Math.round(e.target.buffered.end(0)) / Math.round(e.target.seekable.end(0));
         const progress = this.getVideoLoadingProgress(media, size);
         // console.log('progress : ',progress);
         
         this.updateLoadingProgress(media, isHD, size, progress);
         
         // if (e.target.readyState >= 3
         //    && Math.round(e.target.buffered.end(0)) / Math.round(e.target.seekable.end(0)) >= 0.9) {
         // console.log('media.loadingLayer.style.left : ',media.refs.loadingLayer.style.left)
         this.onPlay(e, media, size, isWaitingEvent, forceLoadCB);
   
         // if (!media.playing && isWaitingEvent && media.refs[size].selected && media.refs[size].visible)
         //    media.refs[size].load();
         
         if (progress >= 95) {
            // When buffer is 1 whole video is buffered
            
            this.hideLoadingLayer(media);
            
            if (!isHD && !media.sd.loaded) {
               // console.log('VIDEO SD LOADED : ', media.id);
               media.sd.loaded = true;
               media.loadForced = false;
               media.refs.item.classList.add('sd-loaded');
               
               if (media.mustDisplayHD)
                  this.loadHDVideo(media);
               // else
               //    this.forceStateMedia(media);
               //    // media.refs.component.current.setState({media});
            }
            else if (isHD && !media.hd.loaded) {
               // console.log('VIDEO HD LOADED : ', media.id);
               // console.log('HD VIDEO LOADING DONE ON : ', media.id)
               media.hd.loaded = true;
               media.loadForced = false;
               media.refs.item.classList.add('hd-loaded');
               
               if (!media.hd.selected)
                  this.replaceSDByHDVideo(media);
               
               else if (!media.hd.only) {
                  this.removeVideoSizeSD(media);
                  // media.refs.component.current.setState({media});
               }
               // else
               //    this.forceStateMedia(media);
               //    // media.refs.component.current.setState({media});
            }
         }
         
         if (isWaitingEvent && !media[size].loaded) {
            
            if (media[size].selected && !media.waiting)
               this.setWaiting(media);
            
            if (!media.loadForced && media.play && (!media.playing || (isHD && media.mustDisplayHD)) /*&& !media.empty*/) {
               // return;
               console.log('FORCE LOAD ' + media.id + ' ' + size + ' ' + (isHD && media.mustDisplayHD ? 'isHD && media.mustDisplayHD' : ''));
               
               try {
                  // media.refs.item.classList.add('FORCE-LOAD');  /* PRODUCTION */
                  
                  if (media.playStarted)
                     media.refs[size].play().catch(e =>{})
                  else
                     media.refs[size].load();
                  
                  media.forceLoadTimeout = setTimeout(() => {
                     media.loadForced = false;
                     // media.refs.item.classList.remove('FORCE-LOAD');
                     this.onVideoLoadingProgress(e, media, isHD, size, false, true);
                  }, 4000);
                  // const handler = e => {
                  //    console.log('WAITING CALLBACK : ',e);
                  //    e.target.removeEventListener('timeupdate', handler, {passive: true});
                  //    this.onVideoLoadingProgress(e, media, isHD, size);
                  // };
                  // e.target.addEventListener('timeupdate', handler, {passive: true});
                  
                  
               } catch (e) {
                  console.error(e)
               }
   
               media.loadForced = true;
            }
         }
      }
      catch(e) { console.error(e) }
   }
   
   setWaiting(media) {
      // return;
      // console.log('ADD loading spinner !');
      media.waiting = true;
      try {
         // media.refs.item.classList.add('waiting');
         media.refs.loadingSpinner.style.display = 'block';
      }
      catch(e) { console.error(e) }
   }
   
   removeWaiting(media) {
      // return;
      // console.log('REMOVE loading spinner ! ',media.id);
      media.waiting = false;
      try {
         // media.refs.item.classList.remove('waiting');
         media.refs.loadingSpinner.style.display = 'none';
      }
      catch(e) { console.error(e) }
   }
   
   updateLoadingProgress(media, isHD, size, progress) {
      return;
      try {
         // const progress = this.getVideoLoadingProgress(media, size) || 0;
         // console.log('PROGRESS : ',progress);
         
         // if (progress) {
            if (!isHD)
               media.refs.loadingLayerProgress.style.left = progress + '%';
            
            media.refs.loadingLayerProgressMin[size].style.left = progress + '%';
         // }
         
         // console.log('updateVideoLoadingProgress '+media.id+' '+size+' : ',progress);
         
         return progress;
      }
      catch(e) {}
      
      return 0;
   }
   
   /*getLoadingProgress(e) {
      try {
         return e.lengthComputable ? e.loaded / e.total * 100 : 0;
      }
      catch(e) {}
      
      return 0;
   }*/
   
   getVideoLoadingProgress(media, size) {
      try {
         // console.log('media.refs[size].buffered.start(0) : ',media.refs[size].buffered.start(0))
         // console.log('media.refs[size].buffered.end(0) : ',media.refs[size].buffered.end(0))
         // console.log('media.refs[size].duration : ',media.refs[size].duration);
         
         return media.refs[size].buffered.length ?
            Math.round(
            (media.refs[size].buffered.end(0) - media.refs[size].buffered.start(0)) / media.refs[size].duration * 100
            )
         : 0;
      }
      catch(e) { console.error(e) }
      
      return 0;
   }
   
   onPlay(e, media, size, isWaitingEvent = false, forceLoadCB = false) {
      // console.log('onPlay '+media.id+' '+size+' '+(isWaitingEvent ? 'isWaitingEvent true' : ''));
      // console.log('video.error : ',e.target.error);
      if (!media.playStarted && !media.refs[size].paused /*&& e.target.readyState === 4*/ && media.play && !isWaitingEvent) {
         media.playStarted = true;
         
         try {
            media.refs.item.classList.add('play-started');
         }
         catch(e) { console.error(e) }
      }
      
      try {
         // const playing = !(media.refs[media.selectedSize] || e.target).paused && !isWaitingEvent;
         const playing = !media.refs[media.selectedSize].paused && !isWaitingEvent;
      
         if (playing !== media.playing) {
            
            /*try {
               if (playing)
                  media.refs.item.classList.add('PLAYING'); /!* DEV *!/
               else
                  media.refs.item.classList.remove('PLAYING');
            }
            catch (e) {}*/
            
            media.playing = playing;
            
            if (!media.play && !media.refs[size].paused && !media.isFullscreen) {
               console.log('PAUSE DE SECURITE :'+media.id+' '+size, media);
               media.refs[size].pause();
            }
         }
      }
      catch (e) {
         console.error('onPlay '+media.id+' '+size+' '+(isWaitingEvent ? 'isWaitingEvent true' : '')+' selectedSize : '+media.selectedSize);
      }
      
      if (media.playing && media.waiting && (!isWaitingEvent || forceLoadCB)) {
         this.removeWaiting(media);
      }
      // this.displayLoadingLayer(media.id, !media.playing && !e.target.seeking && !media.playStarted);
   }
   
   onPause(e, media, isHD, size) {
      console.log('onPause '+media.id);
      
      // if (this.documentHidden)
      //    return;
      
      if (!this.documentHidden && this.isFullscreen && this.selectedMedia === media) {
         // console.log('onPause BY CLICK '+media.id);
         media.playStarted = false;
         media.playing = false;
         this.setVideoPauseState(media);
      }
   
      this.onVideoLoadingProgress(e, media, isHD, size);
   }
   
   /*displayLoadingLayer(media) {
      // console.log('displayLoadingLayer '+media.id);
      try {
         media.refs.loadingLayer.style.display = 'block';
      }
      catch(e) { console.error(e) }
   }*/
   
   hideLoadingLayer(media) {
      return;
      // console.log('hideLoadingLayer '+media.id);
      try {
         media.refs.loadingLayer.style.display = 'none';
      }
      catch(e) { /*console.error(e)*/ }
   }
   
   displayLoadingFooterButton(active) {
      // console.log('displayLoadingFooterButton '+media.id+' : ',active);
      try {
         this.loadingFooterButtonRef.current.style.display = active ? 'block' : 'none';
      }
      catch(e) { /*console.error(e)*/ }
   }
   
   /*onTimeUpdate(e, media, isHD, size) {
      if (media.waiting) {
         // console.log('TIME '+media.id+' '+size, media);
         // this.onVideoLoadingProgress(e, media, isHD, size);
         this.onPlay(e, media, size);
      }
   }*/
   
   onVideoError(e, media, size) {
      // console.log('onVideoError '+media.id+' '+size+' : ', e.error );
      // console.log('DOWNLOAD ERROR '+media.id+' '+size+' STATE : ', media.refs[size].networkState); // NETWORK_NO_SOURCE === 3
      // return;
      // media[size].errors++;
      if (  media.refs[size]
         && media.refs[size].networkState !== 3)
         return;
      
      // if (media[size].errors <= 2) {
         if (  media[size].files.length > 1
            /*&& media.refs.component && media.refs.component.current*/) {
            // media[size].errors = 0;
            media[size].files.shift();
            this.forceStateMedia(media);
            // media.refs.component.current.setState({media});
            return null;
         }
         /*else {
            this.removeVideoSize(media, size);
         }*/
         
      // }
      
      /*if (this.state.allowRemoveErrorItems && media[size].only) {
         let removedMedias = this.state.removedMedias;
         removedMedias[media.id] = true;
         this.setState({removedMedias});
      }*/
   }
   
   /*onVideoDownloadWait(media, size) {
      // return;
      // debugger;
      // if (media.refs[size])
      //    media.refs[size].load();
   }*/
   
   /*onSDVideoEnded(media) {
      
      if (media.refs.sd.ended)
         media.refs.sd.play();
      return;
      if (!media.sd.loaded) {
         console.log('onSDVideoEnded : ',media.id);
         media.sd.loaded = true;
         
         if (media.hd.inserted)
            this.loadHDVideo(media);
         else
            this.forceUpdate();
      }
   }*/
   
   
   render() {
      console.log('App Render');
      
      // return <div>{window.location.href}</div>
      
      return (
         <div className={'app '/*+(this.isTouchScreen ? 'touch ' : 'no-touch ')*/}
              // ref={this.appRef}
              onClick={this.onClickDocument}
              onMouseDownCapture={this.onMouseDownCaptureDocument}
              onMouseUpCapture={this.onMouseUpCaptureDocument}
              onMouseMove={this.onMouseMove}
              onTouchStart={this.onTouchStart}
              onTouchMove={this.onTouchMove}
         >
            <TopBar
               key="topbar"
               ref={this.topBarRef}
               vars={this}
            />
            {/*{!this.isDisclaimerHidden ?*/}
               <Disclaimer
                  key="disclaimer"
                  ref={this.disclaimerRef}
                  vars={this}
               />
            {/*   : null*/}
            {/*}*/}
            <Gallery
               key="gallery"
               ref={this.galleryRef}
               // rows={this.rows}
               allowAutoPlay={this.allowAutoPlay}
               vars={this}
               // rowsRefs={this.rowsRefs}
               // nbRowPreloading={this.nbRowPreloading}
               // removedMedias={this.removedMedias}
               // visibleRows={this.visibleRows}
               // // letBrowserAutoPlay={this.letBrowserAutoPlay}
               // // canVideoPlay={this.canVideosPlay || (this.isFirstLoad && !rowIndex)}
               // onMouseLeaveMedia={this.onMouseLeaveMedia}
               // onMouseMoveMedia={this.onMouseMoveMedia}
               // onTouchStartMedia={this.onTouchStartMedia}
               // onTouchMoveMedia={this.onTouchMoveMedia}
               // onTouchEndMedia={this.onTouchEndMedia}
               // onClickMedia={this.onClickMedia}
               // onClickTitle={this.onClickTitle}
               // onTouchEndTitle={this.onTouchEndTitle}
               // onTouchEndButton={this.onTouchEndButton}
               // onClickButton={this.onClickButton}
               // // onClickSource={this.onClickSource}
               // // onClickDownload={this.onClickDownload}
               // // onClickShare={this.onClickShare}
               // onNavNextToMedia={this.onNavNextToMedia}
               // onNavPrevToMedia={this.onNavPrevToMedia}
               // toggleVideoSize={this.toggleVideoSize}
               // toggleImgSize={this.toggleImgSize}
               // onPlay={this.onPlay}
               // // onTimeUpdate={this.onTimeUpdate}
               // onPause={this.onPause}
               // onVolumeChangeMedia={this.onVolumeChangeMedia}
               // onVideoLoadingProgress={this.onVideoLoadingProgress}
               // // onImgLoadingProgress={this.onImgLoadingProgress}
               // onImgLoaded={this.onImgLoaded}
               // onImgError={this.onImgError}
               // // onVideoDownloadWait={this.onVideoDownloadWait}
               // onVideoError={this.onVideoError}
               // goFullscreen={this.goFullscreen}
               // exitFullscreen={this.exitFullscreen}
            />
            <LoadingFooter
               key="loadingfooter"
               vars={this}
               // loadingFooterEmojisContainerRef={this.loadingFooterEmojisContainerRef}
               // loadingFooterEmojis={this.loadingFooterEmojis}
               // isFirstLoad={this.isFirstLoad}
               // isLoadingPage={this.isLoadingPage}
               // loadingFooterRef={this.loadingFooterRef}
               // loadingFooterButtonRef={this.loadingFooterButtonRef}
               // loadNextPage={this.loadNextPage}
            />
            <WarningNSFW
               ref={this.warningNSFWRef}
               display={false}
               vars={this}
            />
         </div>
      );
   
   }
   
   
   displayDisclaimer() {
      if (this.isDisclaimerHidden) {
         this.isDisclaimerHidden = false;
         
         this.setParamCookie(USER_PARAMS.IS_DISCLAIMER_HIDDEN, this.isDisclaimerHidden);
   
         try {
            
            // this.disclaimerContainerRef.current.classList.add('off');
            //
            // setTimeout(() => {
               this.disclaimerRef.current.setState({
                  hide: false,
               }, () => {
                  this.galleryTopOffset += this.disclaimerContainerRef.current.offsetHeight;
                  this.scrollToTop();
               })
            // }, 300);
         }
         catch (e) {}
         
         this.hideDisclaimer = this._hideDisclaimer;
      }
   }
   
   _hideDisclaimer() {
      if (!this.isDisclaimerHidden) {
         this.isDisclaimerHidden = true;
   
         this.setParamCookie(USER_PARAMS.IS_DISCLAIMER_HIDDEN, this.isDisclaimerHidden);
   
         try {
            this.galleryTopOffset -= this.disclaimerContainerRef.current.offsetHeight;
      
            // this.disclaimerContainerRef.current.classList.add('off');
            //
            // setTimeout(() => {
               this.disclaimerRef.current.setState({
                  hide: true,
               })
            // }, 300);
         }
         catch (e) {}
         
         this.hideDisclaimer = () => {};
      }
   }
   
   renderRatioImg(media) {
      // if (!this.media.isVideo)
      //    return;
      // return;
      return (
         <img key="ratioimg" className="ratio-img" src={media.thumb} />
      )
   }
   
   /*
   600
   2/1  2/1  2/1
   
   
   600 -1 -3
   
   596 /(6/1) : 99.333333
   
    */
   
   
   getRowHeight(row) {
      // console.log('getRowHeight ',);
      // console.log('document.body.clientWidth : ',document.body.clientWidth);
      // console.log('(document.body.clientWidth) / row.format : ',(document.body.clientWidth) / row.format);
      // console.log('(document.body.clientWidth) / row.format : ',(document.body.clientWidth) / row.format);
      // console.log('(document.body.clientWidth -1) / row.format : ',(document.body.clientWidth -1) / row.format);
      // console.log('(document.body.clientWidth -1.5) / row.format : ',(document.body.clientWidth -1.5) / row.format);
      // console.log('(document.body.clientWidth -4) / row.format +1 : ',((document.body.clientWidth -4) / row.format) +1);
      return !row.height || this.documentWidthChanged ? ((document.body.clientWidth -4 /*-1.5*/) / row.format) : row.height; // le pixel de la borudre de gauche de gallery
   }
   
   
   _getMediaStyle(media, mediaIndex, row, isLastRow, isItemRow) {
      const style = this.getMediaBackgroundStyle(media);
      
      if (/*!this.isMobile && */this.nbMediasPerRow > 1) {
         style.height = row.height;
         
         if (row.items.length === 1) { // is one video row ?
            
            if (isItemRow) {
               style.width = window.innerWidth;
            }
            else if (mediaIndex === row.items.length-1 && isLastRow) {
               
               style.maxHeight = 0.5 * window.innerHeight;
               style.maxWidth = style.maxHeight * media.width / media.height;
            }
            style.flexGrow = 1;
         }
         else {
            style.flexGrow = media.width / media.height;
            style.flexBasis = 250 * (style.flexGrow);
         }
      }
      
      return style;
   }
   
   getMediaMobileStyle(media) {
      return this.getMediaBackgroundStyle(media);
   }
   
   getMediaBackgroundStyle(media) {
      return {backgroundImage: 'url('+media.thumb+')'};
   }
   
   _renderRowBreak(row) {
      return (<div key={'break' + row} className="break"/>);
   }
   
   renderInvisibleItems(row, isLastRow) {
      
      const rows = [];
      
      for (let mediaIndex = 0; mediaIndex < row.items.length; mediaIndex++) {
         const media = row.items[mediaIndex];
         // if (this.removedMedias[media.id])
         //    return;
         
         rows.push(
            this.renderItemCoontainer(row, isLastRow, media, mediaIndex)
         );
      }
      
      return rows;
   }
   
   renderItemCoontainer(row, isLastRow, media, mediaIndex, children = null) {
      
      const isItemRow = media.redditId === this.lastItemId;
      
      let className = (media.isVideo ? 'video ' : 'img ')/*+(props.isOneVideoRow && props.allowMinSize ? 'min-row-item ' : '')+(media.hd.ok ? 'hd ' : '')*/+(media.hasMultipleSizes ? 'multi-size ' : 'only ')+(media.sd.loaded ? 'sd-loaded ' : '')+(media.hd.loaded ? 'hd-loaded ' : '')+(media.hd.selected ? 'hd-on ' : '')+(media.clicked ? 'clicked ' : '')+(media.isFullscreen ? 'fullscreen ' : '')+(media.waiting ? 'waiting ' : '')+(media.visible ? '' : 'hidden ')+(media.isVideo && media.playStarted ? 'play-started ' : '');
      
      if (isItemRow) {
         className += 'item-row ';
         
         if (media.width / media.height < window.innerWidth / window.innerHeight) {
            className += 'vertical ';
         }
      }
      
      /*if (media.isVideo) {/!* DEV *!/
         className += (media.loadForced ? 'FORCE-LOAD ' : '')+(media.play ? 'PLAY ' : 'PAUSE ') + (media.playing ? 'PLAYING ' : '');
      }
      
      if (!row.visible) /!* DEV *!/
         className += ' INVISIBLE-ROW';*/
      
      // console.log('ITEM IS VISIBLE '+media.id+' : ',media.visible);
      
      return (
         <div className={"flex-item "+className}
            // ref={media.refs.item}
              ref={this.createMediaRef(media, 'item', media.visible && media.isVideo ? ()=>this.isVideoInserted(media) : null)}
              key={media.id}
              id={media.id}
              style={this.getMediaStyle(media, mediaIndex, row, isLastRow, isItemRow)}
         >
            {this.renderRatioImg(media)}
            {children}
         </div>
      );
   }
   
   
   createObjRef(obj, name, cb) {
      return element => {
         // console.log('CREATE REF '+name+' : ',element);
         obj[name] = element;
         if (obj[name] && cb)
            cb();
      }
   }
   
   createMediaRef(media, name, cb) {
      return element => {
         // console.log('CREATE REF '+media.id+' '+name+' : ',element);
         media.refs[name] = element;
         if (media.refs[name] && cb)
            cb();
      }
   }
   
   // renderBackground() {
   _renderBackground(media) {
      // return;
      return (
         <div className="bg"
              key="bg"
            // style={ { backgroundImage:'url('+media.posterJPG+')' } }
              style={ { backgroundImage:'url('+media.backgroundThumb+')' } }
         />
      )
   }
   
   // renderLoadingLayer() {
   /*renderLoadingLayer(media) {
      return (
         <div className="loading-layer"
              ref={this.createMediaRef(media, 'loadingLayer')}
         >
            {/!*<div className="loading-layer-corner" />*!/}
            <div className="loading-layer-progress"
                 ref={this.createMediaRef(media, 'loadingLayerProgress')}
            >
               <div className="loading-layer-bg" />
               <div className="loading-layer-bar" />
            </div>
         </div>
      )
   }*/
   
   _renderNavButtons() {
      return (
         <>
            <a className="bt fullscreen-control floating nav prev"
               onClickCapture={this.onNavPrevToMedia}
               onTouchEnd={this.onTouchEndButton}
            >
               <span className="icon" />
            </a>
            <a className="bt fullscreen-control floating nav next"
               onClickCapture={this.onNavNextToMedia}
               onTouchEnd={this.onTouchEndButton}
            >
               <span className="icon" />
            </a>
         </>
      );
   }
   
   // renderControlsLayer() {
   renderControlsLayer(media, vars) {
      // return;
      return (
         <>
            <div className="controls-layer"
                 onClick={e => this.onClickMedia(e, media)}
                 onTouchEnd={this.onTouchEndButton}
            >
               {media.isVideo ?
                  <span className="bt floating play" />
                  : null
               }
            </div>
            {this.renderNavButtons()}
            <div className="top-controls">
               <span className="bt fullscreen-control title">
                  <a className="link"
                     href={SOURCE_URLS[SOURCE_TYPES.reddit]+media.redditUrl}
                     target="_blank"
                     rel="noopener noreferrer"
                     onClick={this.onClickTitle}
                     onTouchEnd={this.onTouchEndTitle}
                  >
                     <img className="icon reddit"
                          alt="Reddit logo"
                          src={redditSVG} />
                     <span className="text">
                        {media.title}
                     </span>
                     <img className="icon out"
                          alt="external link"
                          src={outSVG} />
                  </a>
                  <Link className="sublink"
                        to={this.getPageUrl('/u/'+media.redditUser)}
                        onClick={this.onClickTitle}
                        onTouchEnd={this.onTouchEndTitle}
                  >
                     u/<span className="subname">{media.redditUser}</span>
                  </Link>
                  <Link className="sublink"
                        to={this.getPageUrl('/r/'+media.subreddit)}
                        onClick={this.onClickTitle}
                        onTouchEnd={this.onTouchEndTitle}
                  >
                     r/<span className="subname">{media.subreddit}</span>
                  </Link>
               </span>
               <div className="left">
                  <span className="bt fullscreen-control res"
                        onTouchEnd={this.onTouchEndButton}
                        onClick={media.hasMultipleSizes ?
                           media.isVideo ?
                              e => this.toggleVideoSize(media, media.sd.selected ? 'hd' : 'sd')
                              :
                              e => this.toggleImgSize(media, media.sd.selected ? 'hd' : 'sd')
                           : null
                        }>
                     <span className="inner">
                        {
                           // media.isVideo ?
                           // <>
                           //    <span className="loading-bar sd"
                           //          // ref={media.refs.loadingLayerProgressMin.sd} /* A REMETTRE EVENTUELLEMENT */
                           //    />
                           //    <span className="loading-bar hd"
                           //          // ref={media.refs.loadingLayerProgressMin.hd} /* A REMETTRE EVENTUELLEMENT */
                           //    />
                           // </>
                           // : null
                        }
                        <span className="sd size">SD</span>
                        <span className="hd size">HD</span>
                     </span>
                  </span>
                  <a className="bt fullscreen-control download"
                     href={media.downloadUrl}
                     target="_blank"
                     download={true}
                     onClick={this.onClickButton}
                     // onClick={e => this.onClickDownload(e, media)}
                     onTouchEnd={this.onTouchEndButton}
                  >
                     <img className="icon"
                          alt="download"
                          src={downloadSVG} />
                  </a>
                  <a className="bt fullscreen-control share"
                     onClick={this.onClickButton}
                     // onClick={e => this.onClickShare(e, media)}
                     onTouchEnd={this.onTouchEndButton}
                  >
                     <span className="icon" />
                  </a>
                  {
                     media.sourceType !== SOURCE_TYPES.reddit ?
                        <a className="bt fullscreen-control source"
                           href={SOURCE_URLS[media.sourceType]+media.id}
                           target="_blank"
                           rel="noopener noreferrer"
                           onClick={this.onClickButton}
                           // onClick={e => this.onClickSource(e, media)}
                           onTouchEnd={this.onTouchEndButton}
                           title={SOURCE_TYPES[media.sourceType]}
                        >
                           <img className="icon source"
                                alt={SOURCE_TYPES[media.sourceType]}
                                src={SOURCE_IMGS[media.sourceType]} />
                           <img className="icon out"
                                alt="external link"
                                src={outSVG} />
                        </a>
                        :
                        null
                  }
               </div>
               <div className="right">
                  {/*<span className="spacer" />*/}
                  <a className="bt fullscreen-control close"
                     onClick={this.exitFullscreen}
                     onTouchEnd={this.onTouchEndButton}
                  >
                     <span className="icon"><span className="bar" /><span className="bar" /></span>
                  </a>
               </div>
            </div>
            <span className="bt floating item-loading-spinner"
               // ref={media.refs.loadingSpinner} /* A REMETTRE EVENTUELLEMENT */
                  ref={this.createMediaRef(media, 'loadingSpinner')}
                  style={{display: media.waiting ? 'block' : 'none'}}
            />
         </>
      )
   }
   
   // renderImg() {
   renderImg(media, vars) {
      
      // if (media.hasMultipleSizes)
      return (
         <>
            {this._renderImg(media, vars, false, 'sd')}
            <div className="hd-container">
               {this._renderImg(media, vars, true, 'hd')}
            </div>
         </>
      );
      // else
      //    return _renderImg(media.isHD, media.onlySize);
   }
   
   // _renderImg(isHD, size) {
   _renderImg(media, vars, isHD, size) {
      // console.log('_renderImg '+size+' : ',media)
      if (!media[size].ok || !media[size].inserted)
         return;
      
      return (
         <div className="img-container"
              key={size}
            // ref={media.refs.img}
            // src={media.img.files[0]}
              style={{backgroundImage:'url('+media[size].img+')'}}
            // onProgress={!media[size].loaded ? () => this.onImgLoadingProgress(media, isHD) : null}
            /*style={{
               maxWidth:media.width,
               height:'auto',
            }}*/
            /*style={{
               width: 250 * (media.width / media.height),
               minHeight: 250,
            }}*/
         >
            <img
               className="media img"
               // ref={media.refs[size]} /* A REMETTRE EVENTUELLEMENT */
               ref={this.createMediaRef(media, size)}
               src={media[size].img}
               onError={e => this.onImgError(e, media, isHD, size)}
               onLoad={!media[size].loaded ? e => this.onImgLoaded(e, media, isHD, size) : null}
               /*style={{
                  maxWidth:media.width,
                  height:'auto',
               }}*/
               /*style={{
                  width: 250 * (media.width / media.height),
                  minHeight: 250,
               }}*/
               // onError={() => this.onVideoError(media, size)}
            />
         </div>
      );
      
   }
   
   // renderVideo() {
   renderVideo(media, vars) {
      
      // if (media.hasMultipleSizes)
      return (
         <>
            {this._renderVideo(media, vars, false, 'sd')}
            <div className="hd-container">
               {this._renderVideo(media, vars, true, 'hd')}
            </div>
         </>
      );
      // else
      //    return _renderVideo(media.isHD, media.onlySize);
   }
   
   // _renderVideo(isHD, size) {
   _renderVideo(media, vars, isHD, size) {
      
      // const media = media;
      
      if (!media[size].ok/* || (!isHD && media.hd.only)*/)
         return null;
      
      // console.log('media[size].loaded : ',media[size].loaded);
      const onVideoLoadingProgress = !media[size].loaded ? e => this.onVideoLoadingProgress(e, media, isHD, size) : e => this.onPlay(e, media, size);
      const onVideoDownloadWait = !media[size].loaded ? e => this.onVideoLoadingProgress(e, media, isHD, size, true) : null;
      const onVideoError = (e) => this.onVideoError(e, media, size);
      // const onVideoDownloadWait = () => this.onVideoDownloadWait(media, size);
      // debugger
      
      const isCurrentSource = media[size].selected || media[size].only;
      
      // console.log('AUTO PLAY '+media.id+' '+size+' : ', media[size].only || media[size].selected ? this.allowAutoPlay && canVideoPlay : false);
      // console.log('media[size].only : ', media[size].only);
      // console.log('media[size].selected : ', media[size].selected);
      // console.log('this.allowAutoPlay : ', this.allowAutoPlay);
      // console.log('canVideosPlay : ', canVideosPlay);
      
      return (
         <video
            className={"media video "+size}
            key={size}
            // ref={media.refs[size]} /* A REMETTRE EVENTUELLEMENT */
            ref={this.createMediaRef(media, size)}
            autoPlay={ isCurrentSource && this.allowAutoPlay && (/*media.visible*/media.play /*|| this.letBrowserAutoPlay*/) }
            // onClick={e => this.onClickMedia(e, media) }
            onMouseLeave={e => this.onMouseLeaveMedia(e, media)}
            onMouseMove={e => this.onMouseMoveMedia(e, media)}
            onVolumeChange={e => this.onVolumeChangeMedia(e, media, size)}
            onPlay={onVideoLoadingProgress}
            onPlaying={onVideoLoadingProgress}
            onPause={e => this.onPause(e, media, isHD, size)}
            onCanPlay={onVideoLoadingProgress}
            onCanPlayThrough={onVideoLoadingProgress}
            onProgress={onVideoLoadingProgress}
            // onTimeUpdate={e => this.onTimeUpdate(e, media, isHD, size)}
            // onEnded={!isHD && !media.sd.loaded  ? e => onSDVideoEnded(media) : null}
            onError={onVideoError}
            onWaiting={onVideoDownloadWait}
            // onAbort={onVideoDownloadWait} /* the loading of an audio/video is aborted */
            // onStalled={onVideoDownloadWait} /* the browser is trying to get media data, but data is not available */
            // onSuspend={onVideoDownloadWait} /* the browser is intentionally not getting media data */
            loop
            muted
            // controls={true}
            // controls={(!isHD && media.hd.selected)}
            // controlsList="nofullscreen nodownload remoteplayback"
            // controlsList="nodownload"
            controlsList="nofullscreen"
            disablePictureInPicture={true}
            preload={ media.visible && (isCurrentSource || media.mustDisplayHD) ? 'auto' : 'metadata' }
            // preload="auto"
            // poster={media.img.files[0]}
            // poster={media.poster}
            // poster={media.backgroundThumb}
            // src={media[size].inserted && media[size].files.length === 1 ? media[size].files[0].src : null}
         >
            {media[size].inserted ?
               media[size].files.map(file =>
                  <source
                     key={file.src}
                     src={file.src}
                     type={'video/' + file.type}
                  />
               )
               : null
            }
         </video>
      );
   }
   
   initFooterEmojis() {
      if (window.Modernizr.emoji) {
         this.loadingFooterEmojiTrack = 0;
         
         this.loadingFooterEmojis = [
            [
               // <>&#128528;</>, // :|
               // <>&#128528;</>, // :|
               <>&#128578;</>, // :)
               <>&#128578;</>, // :)
               <>&#128539;</>, // :P
               <>&#128539;</>, // :P
               <>&#128541;</>, // XP
               <>&#128541;</>, // XP
               // <>&#128518;</>, // XD
               // <>&#128512;</>, // :D
               // <>&#128516;</>, // ^^D
               // <>&#128516;</>, // '^^D'
               // <>&#129322;</>, // o0P
               // <>&#128540;</>, // ;P
               // <>&#128557;</>, // pleur
               // <>&#128562;</>, // 8o
               // <>&#128550;</>, // :o
               // <>&#128558;</>, // :O
            ],
            [
               // <>&#128528;</>, // :|
               <>&#128578;</>, // :)
               <>&#128578;</>, // :)
               <>&#128516;</>, // ^^D
               <>&#128516;</>, // ^^D
               <>&#128514;</>, // '^^D'
               <>&#128514;</>, // '^^D'
               // <>&#129315;</>, // '^^D' de biais
               // <>&#129315;</>, // '^^D' de biais
            ],
            [
               <>&#128577;</>, // :(
               <>&#128550;</>, // :o
               <>&#128558;</>, // :.
               <>&#128562;</>, // ^^8O
               <>&#128562;</>, // ^^8O
               <>&#128562;</>, // ^^8O
            ],
            [ // eternue
               // <>&#128543;</>, //
               // <>&#128543;</>, //
               <>&#128550;</>, //
               <>&#128550;</>, // :(|
               <>&#128550;</>, // :(|
               <>&#128553;</>, // --(|
               <>&#128555;</>, // X(|
               // <>&#128550;</>, //
               // <>&#128551;</>, //
               // <>&#128551;</>, //
               // <>&#128563;</>, //
               // <>&#128563;</>, // 8|
               // <>&#128562;</>, // 8O
               // <>&#128563;</>, // 8|
               <>&#128548;</>, // soufle narine
               // <>&#128547;</>, //
               <>&#129319;</>, //
               <>&#129319;</>, //
               <>&#129319;</>, //
            ],
            /*[
               // <>&#128528;</>, // :|
               // <>&#128528;</>, // :|
               // <>&#128529;</>, // --|
               // <>&#128529;</>, // --|
               <>&#128563;</>, // 8|
               <>&#128563;</>, // 8|
               <>&#128563;</>, // 8|
               <>&#128580;</>, // 99|
               <>&#128580;</>, // 99|
               <>&#128580;</>, // 99|
               <>&#128580;</>, // 99|
            ],*/
            [
               // <>&#128528;</>, // :|
               // <>&#128578;</>, // :)
               <>&#128578;</>, // :)
               <>&#128578;</>, // :)
               <>&#128512;</>, // :D
               <>&#128512;</>, // :D
               <>&#128516;</>, // ^^D
               <>&#128516;</>, // ^^D
               <>&#128516;</>, // ^^D
               // <>&#128513;</>, // ^^DD
               // <>&#128513;</>, // ^^DD
            ],
         ];
      }
   }
   
   displayLoadingFooterAnim() {
      clearInterval(this.loadingFooterAnimInterval);
      // debugger;
      try {
         this.loadingFooterRef.current.classList.add('on');
      }
      catch (e) {}
      
      if (!window.Modernizr.emoji)
         return;
   
      try {
         this.loadingFooterEmojisContainerRef.current.classList.add('e'+this.loadingFooterEmojiTrack);
      }
      catch (e) {}
      
      let t = 0, sens = 1;
      this.loadingFooterAnimInterval = setInterval(() => {
            t += sens;
            // console.log('anim : ',(-t * 21))
            // console.log('anim : ',t)
            try {
               this.loadingFooterEmojisContainerRef.current.style.left = (-t * 21) + 'px';
            }
            catch (e) {}
            
            if (!t || t === this.loadingFooterEmojis[this.loadingFooterEmojiTrack].length-1)
               sens *= -1;
         }
         , 100)
   }
   
   hideLoadingFooterAnim() {
      clearInterval(this.loadingFooterAnimInterval);
      
      // return;
      try {
         this.loadingFooterRef.current.classList.remove('on');
         this.loadingFooterEmojisContainerRef.current.classList.remove('e'+this.loadingFooterEmojiTrack);
         this.loadingFooterEmojisContainerRef.current.style.left = 0;
      }
      catch (e) {}
      
      if (!window.Modernizr.emoji)
         return;
      // debugger;
      this.loadingFooterEmojiTrack++;
      if (this.loadingFooterEmojiTrack === this.loadingFooterEmojis.length)
         this.loadingFooterEmojiTrack = 0;
   }
}


export const Item = React.memo((props) => {
/*export class Item extends React.Component {
   
   constructor(props) {
      super(props);*/
      
      /*this.state = {
         media: this.props.media,
      };*/
      
      const vars = props.vars;
      const media = props.media;
      
      /*
      
              onClick={e => props.onClickMedia(e, media)}
       */
      
   // }
   
   // 16/9 + 4/3 = 100vw
   
   // 16/9 * 1 = L
   
   // 77.55 / 100 = 100vw / 100vh
   
   // render() {
      console.log('Item Render '+media.id+' : ', {visible:media.visible, play:media.play, playing:media.playing, playStarted:media.playStarted, clicked:media.clicked, mustDisplayHD:media.mustDisplayHD});
      // console.log('vars : ',vars);
      // media = state.media;
      
      /*let className = (media.isVideo ? 'video ' : 'img ')/!*+(props.isOneVideoRow && props.allowMinSize ? 'min-row-item ' : '')+(media.hd.ok ? 'hd ' : '')*!/+(media.hasMultipleSizes ? 'multi-size ' : 'only ')+(media.sd.loaded ? 'sd-loaded ' : '')+(media.hd.loaded ? 'hd-loaded ' : '')+(media.hd.selected ? 'hd-on ' : '')+(media.clicked ? 'clicked ' : '')+(media.isFullscreen ? 'fullscreen ' : '')+(media.waiting ? 'waiting ' : '')+(media.loadForced ? 'FORCE-LOAD ' : '')+(media.visible ? '' : 'hidden ');
      
      if (media.isVideo) /!* TEST *!/
         className += (media.play ? 'PLAY ' : 'PAUSE ')+/!*(media.playing ? 'PLAYING ' : '')+*!/(media.playStarted ? 'play-started ' : '');*/
      
      
      return (
         /*<div className={"flex-item "+ className}
              ref={media.refs.item}
              id={media.id}
              style={props.style}
         >*/
            <div className="fullscreen-container"
                 key="fullscreen-container"
                 // ref={media.refs.fullscreenContainer}
                 onTouchStart={e => vars.onTouchStartMedia(e, media)}
                 onTouchMove={e => vars.onTouchMoveMedia(e, media)}
                 onTouchEnd={e => vars.onTouchEndMedia(e, media)}
            >
               {vars.renderBackground(media)}
               {vars.renderControlsLayer(media, vars)}
               <div className="flex-item-media-container"
                    // ref={media.refs.mediaContainer} /* A REMETTRE EVENTUELLEMENT */
                    ref={vars.createMediaRef(media, 'mediaContainer')}
                    onClick={e => vars.onClickMedia(e, media)}
                    // style={{visibility: !media.isVideo || media.visible ? 'visible' : 'hidden'}}
                    // onTouchStart={e => vars.onTouchStartMedia(e, media)}
                    // onTouchMove={e => vars.onTouchMoveMedia(e, media)}
                    // onTouchEnd={e => vars.onTouchEndMedia(e, media)}
               >
                  {media.isVideo ?
                     /*<>
                        {renderLoadingLayer(media)}
                        {renderVideo(media, vars)}
                     </>*/
                     vars.renderVideo(media, vars)
                     :
                     vars.renderImg(media, vars)
                  }
               </div>
            </div>
         // </div>
      );
   }/*, (prevProps, nextProps) =>
      prevProps.updated === nextProps.updated && prevProps.rowVisible === nextProps.rowVisible*/
   );
// }